import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-header-dynamic-skeleton',
    templateUrl: './header-dynamic-skeleton.component.html',
    styleUrls: ['./header-dynamic-skeleton.component.scss'],
})
export class HeaderDynamicSkeletonComponent implements OnInit {
    @Input() isBrowser: boolean;

    constructor() {}

    ngOnInit(): void {}
}
