import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'app-input-search',
    templateUrl: './input-search.component.html',
    styleUrls: ['./input-search.component.scss'],
})
export class InputSearchComponent implements OnInit {
    @Input() placeholder = '';
    @Input() hideSearch = true;
    @Input() size: 'default' | 'small' = 'default';
    @Output() onSearch = new EventEmitter<string>();
    @Output() onWriteSearch = new EventEmitter<string>();
    @Output() onHideSearch = new EventEmitter<void>();
    @Output() onClickInputSearch = new EventEmitter<void>();

    @ViewChild('inputSearchElem') inputSearchElem: ElementRef;

    searchTextChanged: Subject<string> = new Subject<string>();
    searchText = '';

    constructor() {}

    ngOnInit(): void {
        this.searchTextChanged
            .pipe(debounceTime(500), distinctUntilChanged())
            .subscribe((text) => this.onWriteSearch.emit(text));
    }

    clickSearchButton(): void {
        this.onSearch.emit(this.searchText);
    }

    clickHideSearchButton(): void {
        this.searchText = '';
        this.onHideSearch.emit();
    }

    clickInputSearch(): void {
        this.onClickInputSearch.emit();
    }

    inputSearch(text: string): void {
        this.searchTextChanged.next(text);
    }

    keyPressed(event): void {
        if (event.keyCode === 13) {
            this.hideKeyboard();
            this.inputSearchElem.nativeElement.blur();
            this.onSearch.emit(this.searchText);
        }
    }

    hideKeyboard(): void {
        if (Capacitor.isNativePlatform()) {
            Keyboard.hide();
        }
    }

    clearInput() {
        this.searchText = '';
    }
}
