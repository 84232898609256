import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Appointment } from '../../state/appointment/appointment.model';

@Component({
    selector: 'app-button-primary',
    templateUrl: './button-primary.component.html',
    styleUrls: ['./button-primary.component.scss'],
})
export class ButtonPrimaryComponent implements OnInit {
    @Input() iconStart;
    @Input() iconEnd;

    @Output() buttonClicked = new EventEmitter<any>();

    constructor() {}

    ngOnInit(): void {}

    onButtonClicked() {
        this.buttonClicked.emit();
    }
}
