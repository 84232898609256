import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-units-box',
    templateUrl: './units-box.component.html',
    styleUrls: ['./units-box.component.scss'],
})
export class UnitsBoxComponent implements OnInit {
    @Input() currentUnits;
    @Output() removeUnitsClickEvent = new EventEmitter<any>();
    @Output() addUnitsClickEvent = new EventEmitter<any>();

    constructor() {}

    ngOnInit(): void {}

    onRemoveUnitsClick() {
		this.removeUnitsClickEvent.emit();
	}

    onAddUnitsClick() {
		this.addUnitsClickEvent.emit();
	}
}
