import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SettingsQuery } from '../../state/settings/settings.query';

@Component({
    selector: 'app-header-dynamic',
    templateUrl: './header-dynamic.component.html',
    styleUrls: ['./header-dynamic.component.scss'],
})
export class HeaderDynamicComponent implements OnInit {
    @Input() isLoadingContent;
    @Input() isHidden;
    @Input() backgroundHeader;
    @Input() style;

    isBrowser$: Observable<boolean>;
    showHeader$: Observable<boolean>;

    constructor(private settingsQuery: SettingsQuery) {}

    ngOnInit(): void {
        this.isBrowser$ = this.settingsQuery.select('browser');
        this.showHeader$ = this.settingsQuery.select('showHeader');
    }
}
