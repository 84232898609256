import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsQuery } from '../../../shared/state/settings/settings.query';
import { ClinicDetailQuery } from '../../../shared/state/clinic-detail/clinic-detail.query';

@Injectable({
    providedIn: 'root',
})
export class RouterService {
    constructor(
        private router: Router,
        private settingsQuery: SettingsQuery,
        private clinicDetailQuery: ClinicDetailQuery
    ) {}

    // ¿Es necesario esto? ¿No sería mejor simplemente redirigir siempre a /details?
    // Details redirige a user-account si no existe y user-account redirige a register si no está logeado
    // Respondo, si es necesario, ya que esta función se llama desde los tabs generalmente,
    // Y si desde un tab llamas a /details pero no existe como tab peta y no se redigirá a user-account,
    // Por eso es necesario esta comprobación
    goToMainTabWithoutLogin() {
        if (this.settingsQuery.showClinicDetails) {
            /** Si puedo mostrar el tab de details redirijo a details, si no a registro */
            this.router.navigate([
                '/' +
                    this.settingsQuery.systemKeyURL +
                    '/' +
                    this.clinicDetailQuery.id +
                    '/details',
            ]);
        } else {
            /** Si puedo mostrar el tab de details redirijo a details, si no a registro */
            /** Redirigimos con el queryParam de parentSystemKey */
            this.router.navigate([
                '/' +
                    this.settingsQuery.systemKeyURL +
                    '/' +
                    this.clinicDetailQuery.id +
                    //'/register/social', E.C.S: Se desactiva esta funcionalidad momentaneamente
                    '/register/default',
            ]);
        }
    }
}
