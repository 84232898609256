import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => CheckboxComponent),
        multi: true
    }]
})
export class CheckboxComponent implements OnInit, ControlValueAccessor {
    @Input() disabled = false;
    @Input() set checked(value: boolean) {
        this.isChecked = value;
    }

    isChecked = false;
    onChange = (_ => {});
    onBlur = (_ => {});

    constructor() {
    }

    ngOnInit(): void {
    }

    onChanged($event) {
        this.isChecked = $event && $event.target && $event.target.checked;
        this.onChange(this.isChecked);
    }

    writeValue(obj: boolean): void {
        this.isChecked = obj;
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onBlur = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }
}
