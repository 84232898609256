import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { filter, take } from 'rxjs/operators';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { LibraryService } from '../../../core/services/library/library.service';
import { SocialLoginMethodModel } from '../../models/socialLoginMethod.model';
import { SocialLoginProviderModel } from '../../models/socialLoginProvider.model';
import { LoadingService } from '../../state/loading/loading.service';
import { SettingsQuery } from '../../state/settings/settings.query';
import { SocialLoginService } from '../../state/social-login/social-login.service';

@Component({
    selector: 'app-social-login',
    templateUrl: './social-login.component.html',
    styleUrls: ['./social-login.component.scss'],
})
export class SocialLoginComponent implements OnInit {
    @Input() showSekeleton = false;
    @Output() registerDefaultClickEvent = new EventEmitter<any>();
    iframeSRC = null;

    @HostListener('window:message', ['$event'])
    async onParentMessage(event: MessageEvent): Promise<void> {
        this.socialLoginHandlerPostMessage(event);
    }

    constructor(
        public libraryService: LibraryService,
        private sanitizer: DomSanitizer,
        private socialLoginService: SocialLoginService,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private settingsQuery: SettingsQuery
    ) {}

    ngOnInit(): void {}

    registerDefault() {
        this.registerDefaultClickEvent.emit();
    }

    socialLoginWithGoogle() {
        this.socialLogin(SocialLoginProviderModel.GOOGLE);
    }

    socialLoginWithFacebook() {
        this.socialLogin(SocialLoginProviderModel.FACEBOOK);
    }

    socialLoginWithApple() {
        this.socialLogin(SocialLoginProviderModel.APPLE);
    }

    socialLogin(provider: SocialLoginProviderModel) {
        this.settingsQuery
            .select('flowwwServicesHost')
            .pipe(
                filter((flowwwServicesHost) => flowwwServicesHost !== ''),
                take(1)
            )
            .subscribe((_) => {
                if (Capacitor.isNativePlatform()) {
                    this.socialLoginNative(provider);
                } else {
                    if (this.hasTouchScreen()) {
                        this.socialLoginMobileBrowser(provider);
                    } else {
                        this.socialLoginBrowser(provider);
                    }
                }
            });
    }

    socialLoginBrowser(provider: SocialLoginProviderModel) {
        /** Desde browser, el microservicio funciona con postmessage, que se controla desde este propio componente */
        const callbackURI = window.location.origin;
        this.iframeSRC =
            this.settingsQuery.flowwwServicesHost +
            '/social-login/auth?callbackURI=' +
            callbackURI +
            '&provider=' +
            provider +
            '&method=' +
            SocialLoginMethodModel.POPUP;

        this.iframeSRC = this.sanitizer.bypassSecurityTrustResourceUrl(
            this.iframeSRC
        );
    }

    socialLoginMobileBrowser(provider: SocialLoginProviderModel) {
        /** Desde browser en dispositivo mobile, tenemos que llamar al microservicio con el método redirect, pero para no perder
         * el flujo actual de la aplicación, vamos a abrir una nueva ventana de la aplicación que se encargue de llamar al microservicio,
         * cuando se haya hecho el login, esta nueva tabs nos avisará a la actual y nos pasará los datos del login para continuar
         */

        var ch = new BroadcastChannel('flowwwerSocialLogin');

        ch.addEventListener('message', (event) => {
            this.socialLoginHandlerPostMessage(event);
            ch.close;
        });

        const tabURI = window.location.origin + '/social-login/' + provider;
        window.open(tabURI, '_blank');
    }

    async socialLoginNative(provider: SocialLoginProviderModel) {
        /** Desde nativo, el microservicio funciona con Intents, que se controla desde app components */
        const { id } = await App.getInfo();
        const callbackURI = `${id}://socialLogin/callback`;
        const redirectURI =
            this.settingsQuery.flowwwServicesHost +
            '/social-login/auth?callbackURI=' +
            callbackURI +
            '&provider=' +
            provider +
            '&method=' +
            SocialLoginMethodModel.REDIRECT;

        Browser.open({ url: redirectURI, windowName: '_self' });
    }

    hasTouchScreen() {
        var hasTouchScreen = false;
        if ('maxTouchPoints' in navigator) {
            hasTouchScreen = navigator.maxTouchPoints > 0;
        }
        return hasTouchScreen;
    }

    async socialLoginHandlerPostMessage(event) {
        const data = event.data as {
            type: string;
            payload?: { accessTokenCrypt: string; provider: string };
            error?: any;
        };

        if (
            !data ||
            !data.type ||
            (data.type !== 'socialLogin' && data.type !== 'socialLoginError')
        ) {
            return;
        }

        this.loadingService.updateShowSpinner(true);

        switch (data.type) {
            case 'socialLogin':
                this.iframeSRC = null;
                const accessToken = data.payload.accessTokenCrypt;
                const provider = data.payload.provider;

                await this.socialLoginService.socialLogin(
                    accessToken,
                    provider
                );
                break;
            case 'socialLoginError':
                if (!data.error.message.includes('popup-closed-by-user')) {
                    this.toastService.presentErrorToast(data.error);
                } else {
                    console.log(data.error);
                }
                break;
        }

        this.loadingService.updateShowSpinner(false);
    }
}
