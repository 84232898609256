import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { UserTabsHeaderComponent } from './components/user-tabs-header/user-tabs-header.component';
import { IonicModule } from '@ionic/angular';
import { InfoUserTemporalCardComponent } from './components/info-user-temporal-card/info-user-temporal-card.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { DateWizardPipe } from './pipes/date-wizard.pipe';
import { RegisterFormComponent } from './components/register-form/register-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PrivacityModalComponent } from './components/privacity-modal/privacity-modal.component';
import { FamilyListComponent } from './components/wizard/family-list/family-list.component';
import { PaymentWizardComponent } from './components/wizard/payment-wizard/payment-wizard.component';
import { FamilyItemComponent } from './components/wizard/family-item/family-item.component';
import { ResultWizardComponent } from './components/wizard/result-wizard/result-wizard.component';
import { FloatingLinkInfoComponent } from './components/floating-link-info/floating-link-info.component';
import { SkeletonWizardComponent } from './components/skeleton-wizard/skeleton-wizard.component';
import { HeaderDynamicComponent } from './components/header-dynamic/header-dynamic.component';
import { HeaderDynamicSkeletonComponent } from './components/header-dynamic-skeleton/header-dynamic-skeleton.component';
import { HeaderSimpleComponent } from './components/header-simple/header-simple.component';
import { MainHeaderComponent } from './components/main-header/main-header.component';
import { NoResultsComponent } from './components/no-results/no-results.component';
import { GenericHeaderComponent } from './components/generic-header/generic-header.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ButtonsFooterComponent } from './components/buttons-footer/buttons-footer.component';
import { GenericAppointmentComponent } from './components/generic-appointment/generic-appointment.component';
import { FamilyServiceListComponent } from './components/wizard/family-service-list/family-service-list.component';
import { ServiceListComponent } from './components/wizard/service-list/service-list.component';
import { ServiceItemComponent } from './components/wizard/service-item/service-item.component';
import { ServiceSearchbarComponent } from './components/service-searchbar/service-searchbar.component';
import { ProductListComponent } from './components/wizard/product-list/product-list.component';
import { ProductItemComponent } from './components/wizard/product-item/product-item.component';
import { FamilyProductListComponent } from './components/wizard/family-product-list/family-product-list.component';
import { UnitsListPopperComponent } from './components/wizard/units-list-popper/units-list-popper.component';
import { HeightNoCompatibleComponent } from './components/height-no-compatible/height-no-compatible.component';
import { SkeletonAppointmentComponent } from './components/skeleton-appointment/skeleton-appointment.component';
import { SkeletonTreatmentComponent } from './components/skeleton-treatment/skeleton-treatment.component';
import { SkeletonMessagesComponent } from './components/skeleton-messages/skeleton-messages.component';
import { SkeletonUserAccountComponent } from './components/skeleton-user-account/skeleton-user-account.component';
import { ButtonPrimaryComponent } from './components/button-primary/button-primary.component';
import { OtpModalComponent } from './components/otp-modal/otp-modal.component';
import { SocialLoginComponent } from './components/social-login/social-login.component';
import { SocialLoginButtonComponent } from './components/social-login-button/social-login-button.component';
import { TitleComponent } from './components/title/title.component';
import { HeaderComponent } from './components/header/header.component';
import { ClinicPreviewComponent } from './components/clinic-preview/clinic-preview.component';
import { InputSearchComponent } from './components/input-search/input-search.component';
import { StepIndicatorComponent } from './components/step-indicator/step-indicator.component';
import { MomentPipe } from './pipes/moment/moment.pipe';
import { UnitsBoxComponent } from './components/units-box/units-box.component';
import { SkeletonWizardTimeListComponent } from './components/skeleton-wizard-time-list/skeleton-wizard-time-list.component';
import { HeaderDesktopComponent } from './components/header-desktop/header-desktop.component';
import { TabBarComponent } from './components/tab-bar/tab-bar.component';
import { TabButtonComponent } from './components/tab-button/tab-button.component';
import { SkeletonSocialLoginComponent } from './components/skeleton-social-login/skeleton-social-login.component';
import { SkeletonFooterComponent } from './components/skeleton-footer/skeleton-footer.component';
import { EmptyListComponent } from './components/empty-list/empty-list.component';
import { PackItemComponent } from './components/wizard/pack-item/pack-item.component';

@NgModule({
    declarations: [
        LoadingScreenComponent,
        UserTabsHeaderComponent,
        InfoUserTemporalCardComponent,
        DateWizardPipe,
        RegisterFormComponent,
        PrivacityModalComponent,
        FamilyListComponent,
        FamilyItemComponent,
        PaymentWizardComponent,
        ResultWizardComponent,
        FloatingLinkInfoComponent,
        SkeletonWizardComponent,
        HeaderDynamicComponent,
        HeaderDynamicSkeletonComponent,
        HeaderSimpleComponent,
        ButtonPrimaryComponent,
        MainHeaderComponent,
        NoResultsComponent,
        GenericHeaderComponent,
        CheckboxComponent,
        ButtonsFooterComponent,
        GenericAppointmentComponent,
        HeightNoCompatibleComponent,
        FamilyServiceListComponent,
        ServiceListComponent,
        ServiceItemComponent,
        ServiceSearchbarComponent,
        ProductListComponent,
        ProductItemComponent,
        FamilyProductListComponent,
        UnitsListPopperComponent,
        SkeletonAppointmentComponent,
        SkeletonTreatmentComponent,
        SkeletonMessagesComponent,
        SkeletonUserAccountComponent,
        OtpModalComponent,
        SocialLoginComponent,
        SocialLoginButtonComponent,
        TitleComponent,
        HeaderComponent,
        ClinicPreviewComponent,
        InputSearchComponent,
        StepIndicatorComponent,
        MomentPipe,
        UnitsBoxComponent,
        SkeletonWizardTimeListComponent,
        HeaderDesktopComponent,
        TabBarComponent,
        TabButtonComponent,
        SkeletonSocialLoginComponent,
        SkeletonFooterComponent,
        EmptyListComponent,
        PackItemComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        IonicModule,
        NgbDatepickerModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule
    ],
    exports: [
        TranslateModule,
        LoadingScreenComponent,
        UserTabsHeaderComponent,
        InfoUserTemporalCardComponent,
        NgbDatepickerModule,
        DateWizardPipe,
        RegisterFormComponent,
        FamilyListComponent,
        FamilyItemComponent,
        PaymentWizardComponent,
        ResultWizardComponent,
        FloatingLinkInfoComponent,
        SkeletonWizardComponent,
        HeaderDynamicComponent,
        HeaderDynamicSkeletonComponent,
        HeaderSimpleComponent,
        MainHeaderComponent,
        NoResultsComponent,
        GenericHeaderComponent,
        ButtonsFooterComponent,
        CheckboxComponent,
        GenericAppointmentComponent,
        HeightNoCompatibleComponent,
        ServiceListComponent,
        ServiceItemComponent,
        FamilyServiceListComponent,
        ServiceSearchbarComponent,
        ProductListComponent,
        ProductItemComponent,
        UnitsListPopperComponent,
        FamilyProductListComponent,
        SkeletonAppointmentComponent,
        SkeletonTreatmentComponent,
        SkeletonMessagesComponent,
        SkeletonUserAccountComponent,
        ButtonPrimaryComponent,
        OtpModalComponent,
        SocialLoginComponent,
        TitleComponent,
        HeaderComponent,
        ClinicPreviewComponent,
        InputSearchComponent,
        StepIndicatorComponent,
        MomentPipe,
        UnitsBoxComponent,
        SkeletonWizardTimeListComponent,
        HeaderDesktopComponent,
        TabBarComponent,
        SkeletonSocialLoginComponent,
        SkeletonFooterComponent,
        EmptyListComponent
    ],
})
export class SharedModule {}
