import { Injectable } from '@angular/core';
import {
    CanDeactivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { SettingsQuery } from '../state/settings/settings.query';
import { StorageService } from 'src/app/core/services/storage/storage.service';

@Injectable({
    providedIn: 'root',
})
export class ClinicActiveGuard implements CanDeactivate<unknown> {
    constructor(
        private settingsQuery: SettingsQuery,
        private router: Router,
        private storage: StorageService
    ) {}

    /** TODO: Por como están hechas las rutas de Generic y personalized, este candeactivate solo
     * funciona (y solo hace falta) para Generic. En personalized el borrado se hace en el
     * enter del buscador de personalized. Ya que solo se comprueba una sola vez el ir a la clínica guardada
     * no como en Generic que se hace siempre que se entra al buscador
     * MEJORAR ESTO SI O SI, PUEDE DAR PROBLEMAS EN EL FUTURO, HACERLO DE OTRA FORMA
     */
    canDeactivate(
        component: unknown,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        if (nextState.url.split('/').length <= 2) {
            return new Promise((resolve) => {
                this.storage
                    .remove('companyActive')
                    .then(() => {
                        resolve(true);
                    })
                    .catch((error) => {
                        resolve(false);
                    });
            });
        } else {
            return true;
        }
    }
}
