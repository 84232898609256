import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { ProductWizard } from '../../../state/product-wizard/product-wizard.model';
import { Platform } from '@ionic/angular';
import { ProductWizardQuery } from '../../../state/product-wizard/product-wizard.query';
import { AppRolesModel, GenericKeysModel } from '../../../models';
import { SettingsQuery } from '../../../state/settings/settings.query';
import { UnitsListPopperComponent } from '../units-list-popper/units-list-popper.component';

@Component({
    selector: 'app-product-item',
    templateUrl: './product-item.component.html',
    styleUrls: ['./product-item.component.scss'],
})
export class ProductItemComponent implements OnInit {
    private readonly MAX_UNITS = 100;
    private readonly MIN_UNITS = 1;

    @Input() product: ProductWizard;
    @Input() appDelegate: boolean;

    @Output() productToggleEvent = new EventEmitter<any>();
    @Output() productClickToPresentModalEvent = new EventEmitter<any>();
    /** Flowwwer template */
    @Output() productClickEvent = new EventEmitter<any>();

    @ViewChild('unitsListPopper')
    unitsListPopper: UnitsListPopperComponent;

    ios;

    currentUnits = 0;
    currentChecked = false;

    get finalPrice() {
        if (this.product.price && this.product.price !== '') {
            if (
                this.product.promotedPrice &&
                this.product.promotedPrice !== ''
            ) {
                return this.product.promotedPrice;
            } else {
                return this.product.price;
            }
        }
    }

    constructor(
        private platform: Platform,
        private productWizardQuery: ProductWizardQuery,
        private settingsQuery: SettingsQuery
    ) {
        this.ios = this.platform.is('ios');
    }

    ngOnInit(): void {
        // Como los productos estan en carpetas, y estas no se auto recargan con akita (solo la inicial),
        // tenemos que obtener el checked y las unidades directamente de akita
        this.productWizardQuery
            .selectEntity(this.product.id, 'unitsCart')
            .subscribe((unitsCarts) => {
                this.currentUnits = unitsCarts;
            });

        /** Flowwwer template */
        this.productWizardQuery
            .selectEntity(this.product.id, 'checked')
            .subscribe((checked) => {
                this.currentChecked = checked;
            });
    }

    onProductToggle(event: Event, product: ProductWizard) {
        const currentProduct = { ...product, unitsCart: this.currentUnits };
        if (this.currentUnits === 1) {
            /** Primero se lanza el evento del popper (que está disabled cuando está 1
             * Luego se emite el evento para poner a 0 las unidades
             * Necesitamos el timeout para que asegurar que el evento del popper se lance primero
             */
            setTimeout(() => {
                this.onProductToggleWithNewUnits(currentProduct, 0);
            }, 10);
        } else if (this.showModalProduct) {
            this.onProductClickToPresentModal(product);
        }

        event.preventDefault();
    }

    onProductClickToPresentModal(productWizard: ProductWizard) {
        this.productClickToPresentModalEvent.emit(productWizard);
    }

    onProductToggleWithNewUnits(product: ProductWizard, newUnits: number) {
        this.productToggleEvent.emit({
            product,
            newUnits,
        });
    }

    selectUnits(units) {
        //this.popperUnitsContent.hide();
        this.onProductToggleWithNewUnits(this.product, units);
    }

    popperOnShown() {
        this.unitsListPopper.initUnits();
        setTimeout(() => {
            this.unitsListPopper.scrollToCurrentUnits();
        }, 100);
    }

    get showModalProduct() {
        if (
            this.settingsQuery.appRole === AppRolesModel.Personalized ||
            this.settingsQuery.appRole === AppRolesModel.PersonalizedBrowser ||
            ((this.settingsQuery.appRole === AppRolesModel.Generic ||
                this.settingsQuery.appRole === AppRolesModel.GenericBrowser) &&
                this.settingsQuery.genericKey === GenericKeysModel.LPG)
        ) {
            return true;
        }
        return false;
    }

    /** Flowwwer template */
    onProductClick() {
        if (this.showModalProduct) {
            //TODO: Ver si da tiempo a refactorizar la ficha del producto con back
            if (this.currentUnits === 1) {
                this.productClickEvent.emit(this.product);
            }else{
                this.onProductClickToPresentModal(this.product); //Muestra el modal del producto
                
            }
            
        } else {
            this.productClickEvent.emit(this.product); //Hace un togle del checked para mostrar o no el units-box
        }
    }

    removeUnits() {
        if (this.currentUnits > this.MIN_UNITS) {
            this.currentUnits--;
        }
        this.setCurrentsUnits();
    }

    addUnits() {
        if (this.currentUnits < this.MAX_UNITS) {
            this.currentUnits++;
        }
        this.setCurrentsUnits();
    }

    setCurrentsUnits() {
        this.onProductToggleWithNewUnits(this.product, this.currentUnits);
    }
}
