/**
 * @author Esteban Castro <esteban.catro@flowww.com>
 */

import { Component, HostListener, ViewChild, NgZone } from '@angular/core';
import {
    AnimationController,
    IonRouterOutlet,
    ModalController,
    NavController,
    Platform,
} from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { LanguageService } from './core/services/language/language.service';
import { LibraryService } from './core/services/library/library.service';
import { SettingsService } from './shared/state/settings/settings.service';
import { environment } from '../environments/environment';
import { LocationService } from './core/services/location/location.service';
import { SettingsQuery } from './shared/state/settings/settings.query';
import { PushService } from './core/services/push/push.service';
import { NavigationStart, Router } from '@angular/router';
import { AutocloseOverlaysService } from './core/services/AutocloseOverlays/autoclose-overlays.service';
import { VersionService } from './core/services/version/version.service';
import { BadgeService } from './core/services/badge/badge.service';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { HeaderBackgroundService } from './shared/state/header-background/header-background.service';
import { HeaderIconService } from './shared/state/header-icon/header-icon.service';
import { HttpClient } from '@angular/common/http';
import { LoadingQuery } from './shared/state/loading/loading.query';
import { KeyboardService } from './shared/state/keyboard/keyboard.service';
import { Observable } from 'rxjs';
import { ClinicQuery } from './shared/state/clinic/clinic.query';
import { Keyboard, KeyboardInfo } from '@capacitor/keyboard';
import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';

/** register the locale information for pipes */
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { CompanyService } from './shared/state/company/company.service';
import { SocialLoginService } from './shared/state/social-login/social-login.service';
import { ToastService } from './core/services/toast/toast.service';
import { ClinicService } from './shared/state/clinic/clinic.service';
import { StorageService } from './core/services/storage/storage.service';
import { register } from 'swiper/element/bundle';
registerLocaleData(localeEs, 'es');
register();

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {
    constructor(
        private platform: Platform,
        private languageService: LanguageService,
        private libraryService: LibraryService,
        private settingsService: SettingsService,
        private locationService: LocationService,
        private pushService: PushService,
        private router: Router,
        private autocloseOverlaysService: AutocloseOverlaysService,
        private versionService: VersionService,
        private badgeService: BadgeService,
        private screenOrientation: ScreenOrientation,
        private settingsQuery: SettingsQuery,
        private headerBacgroundService: HeaderBackgroundService,
        private headerIconService: HeaderIconService,
        private navCtrl: NavController,
        private modalController: ModalController,
        private animationCtrl: AnimationController,
        private http: HttpClient,
        private loadingQuery: LoadingQuery,
        private keyboardService: KeyboardService,
        private ngZone: NgZone,
        private clinicQuery: ClinicQuery,
        private companyService: CompanyService,
        private socialLoginService: SocialLoginService,
        private toastService: ToastService,
        private clinicService: ClinicService,
        private storage: StorageService
    ) {
        this.browser$ = this.settingsQuery.select('browser');

        /** Cargamos el idioma del navegador */
        this.languageService.loadBrowserLocale();

        /** Cargamos la libraría local según idioma */
        this.libraryService.loadOfflineLibrary();

        /** Inicializamos la store de Settings con los datos de environments */
        this.initSettingsStore();

        /** Seteamos la latitud y la longitud */
        this.locationService.loadLocationDevice();

        /** Cargamos las clínicas favoritas desde la LocalStorage */
        this.clinicService.loadClinicsFavFromLocalStorage();

        /** Listener para cerrar los modals cuando se cambia de ruta */
        this.initListenerCloseOverlays();

        /** Inicializar badge de icono de APP */
        this.badgeService.initListenerBadge();

        /** Header dinámico */
        this.settingsService.setHeightHeader();

        /** Icons header */
        this.headerIconService.updateIcons();

        /** back button */
        this.initBackButtonListener();

        /** keyboard */
        this.initListenerKeyBoard();

        /* Inicializar escuchar Intent */
        this.initListenerIntent();

        /** Ocultamos el splash y configuramos elementos solo en dispositivos */
        this.initializeApp();

        this.isDevice = this.mobileAndTabletCheck();
    }

    /** Obtenemos el routerOutlet que nos servirá para manejar el botón de atrás de hardware
     * informándonos si podemos ir atrás
     */
    @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;

    browser$: Observable<boolean>;
    isDevice: boolean;
    myCustomPageTransition = (baseEl: any, opts?: any) => {
        const anim1 = this.animationCtrl
            .create()
            .addElement(opts.leavingEl)
            .duration(300)
            .iterations(1)
            .easing('ease-out')
            .fromTo('opacity', '1', '0.0');
        let anim2 = this.animationCtrl
            .create()
            .addElement(opts.enteringEl)
            .duration(300)
            .iterations(1)
            .easing('ease-out')
            .fromTo('opacity', '0.0', '1');
        anim2 = this.animationCtrl
            .create()
            .duration(500)
            .iterations(1)
            .addAnimation([anim1, anim2]);
        return anim2;
    };

    /** Inicializamos en Akita el valor de los settings de Environment necesarios para la aplicación */
    initSettingsStore() {
        this.settingsService.updateGenericKey(environment.genericKey);
        this.settingsService.setPrimaryColors(environment.primaryColorsList);
        this.settingsService.updateSystemKey(environment.systemKey);
        this.settingsService.updateBrowser(environment.browser);
        this.settingsService.updateDebug(environment.debug);
    }

    /** Cargamos la configuración de browser, que solo se cargará si estamos en modo browser */
    async loadBrowserConfig() {
        let configBrowser: any = {};
        try {
            /** Solo cargo esta config si estoy en modo browser, si no, establezco por defecto los valores */
            configBrowser = await this.http
                .get('/assets/browser/' + 'config.json')
                .toPromise();

            this.settingsService.updateShowClinicDetails(
                configBrowser.showClinicDetails
            );

            this.settingsService.updateshowHeader(configBrowser.showHeader);
        } catch (err) {
            this.router.navigate(['/not-found']);
        }
    }

    /** Cargamos la configuración de la template */
    async loadTemplateConfig() {
        let templateConfig: any = {};
        try {
            /** Solo cargo esta config si estoy en modo browser, si no, establezco por defecto los valores */
            templateConfig = await this.http
                .get('/assets/template/' + 'config.json')
                .toPromise();

            this.companyService.updateClientAdvisorTabMode(
                templateConfig.clientAdvisorTabMode
            );
            this.companyService.updateGMapsClinicDetails(
                templateConfig.gMapsApiKey
            );
        } catch (err) {
            this.router.navigate(['/not-found']);
        }
    }

    /** Cargamos la configuración del Login Social */
    async loadSocialLoginConfig() {
        let socialLoginConfig: any = {};
        try {
            socialLoginConfig = await this.http
                .get('/assets/social-login/' + 'config.json')
                .toPromise();

            this.settingsService.updateFlowwwServicesHost(
                socialLoginConfig.flowwwServicesHost
            );
        } catch (err) {
            this.router.navigate(['/not-found']);
        }
    }

    /** Inicializa la escucha de enventos de navegación para cerrar los modals cuando se pulsa el botón atrás de la app  */
    initListenerCloseOverlays() {
        this.router.events.subscribe((event: any): void => {
            if (event instanceof NavigationStart) {
                if (event.navigationTrigger === 'popstate') {
                    this.autocloseOverlaysService.trigger();
                }
            }
        });
    }

    /** Guardamos en Akita cuando se abre el teclado y cuando se oculta
     * Lo usamos para:
     * 1) Cambiar los estilos de los botones en ios ya que les sigue metiendo el padding cuando el teclado está subido */
    initListenerKeyBoard() {
        if (this.platform.is('cordova') && this.platform.is('ios')) {
            Keyboard.addListener('keyboardWillShow', (info: KeyboardInfo) => {
                this.ngZone.run(() => {
                    this.keyboardService.updateIsShowing(true);
                });
            });

            Keyboard.addListener('keyboardWillHide', () => {
                this.ngZone.run(() => {
                    setTimeout(() => {
                        /** Timeout para que las acciones que dependan de esta valor no se ejecuten instantaneamente
                         * y den mal efecto de UX.
                         * Con este timeout el cambio de estilos para botones de toolbar se hace sin verse*/
                        this.keyboardService.updateIsShowing(false);
                    }, 100);
                });
            });
        }
    }

    /** Inicializamos los estilos de la status bar si es necesario al inicio de la aplicación (si estamos en generic) */
    checkStatusBarGeneric() {
        if (this.settingsQuery.isGeneric()) {
            this.settingsService.updateStatusBarStyle();
        }
    }

    /** Manejamos nosotros todos los eventos al pulsar el botón atrás de hardware de android
     * al suscribirnos con prioridad 9999 podemos cancelar los eventos nativos del botón si queremos */
    initBackButtonListener() {
        this.platform.backButton.subscribeWithPriority(
            9999,
            async (processNextHandler) => {
                /** Primero comprobamos si tenemos el loading mostrandose, y por tanto cancelamos el back button */
                if (!this.loadingQuery.active) {
                    const element = await this.modalController.getTop();
                    if (element) {
                        this.modalController.dismiss();
                    } else {
                        if (this.routerOutlet.canGoBack()) {
                            processNextHandler();
                        } else {
                            if (
                                !this.settingsQuery.isPersonalized() ||
                                this.clinicQuery.getValue().clinicsFound.length > 1
                            ) {
                                this.navCtrl.navigateBack(
                                    '/' +
                                        (this.settingsQuery.isPersonalized()
                                            ? this.settingsQuery.systemKeyURL
                                            : '')
                                );
                            }
                        }
                    }
                }
            }
        );
    }

    /** Escuchamos el evento de resize de la pantalla para recalcular dimensiones del header dinámico */
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.settingsService.setHeightHeader();
    }

    async initializeApp() {
        if (this.settingsQuery.browser) {
            /** Cargamos la configuración del browser si es necesaria */
            await this.loadBrowserConfig();
        }

        await this.loadTemplateConfig();
        await this.loadSocialLoginConfig();
        await this.storage.migrateSQLLiteDataToPreferences();

        this.platform.ready().then(async () => {
            // Notificaciones push
            this.pushService.initNotificationPush();
            try {
                await SplashScreen.hide();
                await StatusBar.setStyle({ style: Style.Light });
                this.screenOrientation.lock(
                    this.screenOrientation.ORIENTATIONS.PORTRAIT
                );
                this.checkStatusBarGeneric();
                
            } catch (err) {
                console.log('This is normal in a browser', err);
            }
        });
    }

    mobileAndTabletCheck(): boolean {
        let check = false;
        (function (a) {
            if (
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                    a
                ) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                    a.substr(0, 4)
                )
            ) {
                check = true;
            }
        })(navigator.userAgent || navigator.vendor);
        return check;
    }

    async initListenerIntent() {
        const { id } = await App.getInfo();
        const callbackUri = `${id}://socialLogin/callback`;

        App.addListener('appUrlOpen', ({ url }) => {
            console.log(url);
            this.ngZone.run(() => {
                if (url?.startsWith(callbackUri)) {
                    if (url.includes('token=') || url.includes('error=')) {
                        // Hacer login con token de facebook
                        const tokenQueryParam = this.getParameterByName(
                            'token',
                            url
                        );
                        const errorQueryParam = this.getParameterByName(
                            'error',
                            url
                        );
                        const providerQueryParam = this.getParameterByName(
                            'provider',
                            url
                        );
                        if (
                            tokenQueryParam &&
                            tokenQueryParam !== '' &&
                            providerQueryParam &&
                            providerQueryParam !== ''
                        ) {
                            this.socialLoginService.socialLogin(
                                tokenQueryParam,
                                providerQueryParam
                            );
                        } else if (errorQueryParam && errorQueryParam !== '') {
                            this.toastService.presentErrorToast(errorQueryParam);
                        }
                    }
                    Browser.close();
                }
            });
        });
    }

    getParameterByName(name, url) {
        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        const results = regex.exec(url);
        if (!results) {
            return null;
        }
        if (!results[2]) {
            return '';
        }
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
}
