import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { KeyboardStore, KeyboardState } from './keyboard.store';

@Injectable({ providedIn: 'root' })
export class KeyboardQuery extends Query<KeyboardState> {

  constructor(protected store: KeyboardStore) {
    super(store);
  }

}
