export interface Message {
    id: string /** ID del mensaje */;
    gid: string /** TODO: No se lo que es y Javi no lo usa */;
    type: string /** Tipo del mensaje TODO: Entenderlo mejor sus consecuencias */;
    colorIcon: string /** Color del icono: TODO: NO lo usa JAVI */;
    title: string /** Título del mensaje */;
    subtitle: string /** Subtítulo del mensaje */;
    date: string /** Fecha del mensaje */;
    read: boolean /** Si el usuario ha leido el mensaje */;
}

/**
 * A factory function that creates Message
 */
export function createMessage(params: Partial<Message>) {
    return {} as Message;
}

/**
 * Factoría que crea un Message con los datos recogidos por la API
 */
export function messageFactory(params: any) {
    return {
        id: params.MessageID,
        gid: params.MessageGID,
        type: params.MessageType,
        colorIcon: params.MessageColorIcon,
        title: params.MessageTitle,
        subtitle: params.MessageSubTitle,
        date: params.MessageDate,
        read: params.MessageRead === '-1',
    } as Message;
}
