import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { PrimaryColorsModel } from '../../models/primary-colors.model';

/**
 * Esta Store va a almacenar datos de configuración generales de la aplicación
 * como por ejemplo los datos de environments
 */
export interface SettingsState {
    systemKey: string /** SystemKey de una app Personalizada */;
    systemKeyURL: string /** Systemkey de una app Personalizada ingresado por párametro de URL */;
    parentSystemKey: string /** Parámtro opcional que indicará el systemKey padre de la clínica (se usa en dibea_app) **/;
    genericKey: number /** GenericKey de una app Genérica */;
    browser: boolean /** Indica si la app se comporta como App de dispositivo y de Navegador */;
    showClinicDetails: boolean /** Para una app Generic, indica si hay que mostrar el tab de Detalles de la clínica */;
    showHeader: boolean /** Para una app Generic, indica si hay que mostrar el tab de Detalles de la clínica */;
    loginWithURL: boolean /** Indica si se ha hecho login con URL  */;
    primaryColors: PrimaryColorsModel /** Configuración de colores primarios para una app Personalizada */;
    apiURL: string /** Url de la API a la que apuntar todas las llamadas */;
    curentBackgroundImage: string /** Indica la imagen actual del background */;
    currentImageHeader: string /** Indica la imagen actual del avatar (bolita del header) */;
    currentIconHeader: string /** Indica el icono dactual del avatar (bolita del header) (Solo para Voooy) */;
    latitude: number /** Latitud de las cordenadas del dispositivo */;
    longitude: number /** Longitud de las coordenadas del dispositivo */;
    status: string;
    debug: boolean;
    flowwwServicesHost: string;
}

export function createInitialState(): SettingsState {
    return {
        systemKey: '',
        systemKeyURL: '',
        parentSystemKey: '',
        genericKey: null,
        browser: false,
        showClinicDetails: true,
        showHeader: true,
        loginWithURL: false,
        primaryColors: null,
        apiURL: '',
        curentBackgroundImage: '',
        currentImageHeader: '',
        currentIconHeader: '',
        latitude: null,
        longitude: null,
        status: 'STABLE',
        debug: false,
        flowwwServicesHost: '',
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'settings' })
export class SettingsStore extends Store<SettingsState> {
    constructor() {
        super(createInitialState());
    }
}
