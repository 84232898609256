import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MessageStore } from './message.store';
import { Message, messageFactory } from './message.model';
import { Observable } from 'rxjs';
import { LanguageService } from '../../../core/services/language/language.service';
import { SettingsQuery } from '../settings/settings.query';
import { UserQuery } from '../user/user.query';
import { CompanyQuery } from '../company/company.query';
import { ApiService } from 'src/app/core/services/api/api.service';

@Injectable({ providedIn: 'root' })
export class MessageService {
    constructor(
        private messageStore: MessageStore,
        private http: HttpClient,
        private languageService: LanguageService,
        private settingsQuery: SettingsQuery,
        private userQuery: UserQuery,
        private companyQuery: CompanyQuery,
        private apiService: ApiService
    ) {}

    /*---------------------*/
    /** OPERACIONES ENTITY */
    /*---------------------*/

    add(message: Message) {
        this.messageStore.add(message);
    }

    update(id, message: Partial<Message>) {
        this.messageStore.update(id, message);
    }

    remove(id: ID) {
        this.messageStore.remove(id);
    }

    setMessages(messages) {
        this.messageStore.set(messages);
    }

    /*---------------*/
    /** LLAMADAS API */
    /*---------------*/

    /**
     * Llamada api para obtener los mensajes de un usuario
     */
    private getMessagesApi(
        systemKey: string,
        clientToken: string,
        messageID: string
    ): Observable<any> {
        let params = new HttpParams();
        params = params.append('SystemKey', systemKey);
        params = params.append('ClientToken', clientToken);
        params = params.append('MessageID', messageID);
        params = params.append('Cmd', 'c1051');
        params = params.append('Locale', this.languageService.getLocale());

        return this.apiService.callApi(params);
    }

    /**
     * Llamada api para borrar un mensaje
     */
    private deleteMessageApi(
        systemKey: string,
        clientToken: string,
        messageId: string
    ): Observable<any> {
        let params = new HttpParams();
        params = params.append('SystemKey', systemKey);
        params = params.append('ClientToken', clientToken);
        params = params.append('MessageID', messageId);
        params = params.append('Cmd', 'c1053');
        params = params.append('Locale', this.languageService.getLocale());

        return this.apiService.callApi(params);
    }

    /*------------*/
    /** SERVICIOS */
    /*------------*/

    /**
     *  Función que llama a la API para cargar todos los mensajes de un usuario con un SystemKey
     *  TODO: No se por qué tengo que pasarle un messageID (se lo paso a null ahora mismo. Javi se lo pasa siempre a null)
     */
    async getMessages(messageId: string) {
        const messagesData = await this.getMessagesApi(
            this.settingsQuery.systemKeyURL,
            this.userQuery.tokenId,
            messageId
        ).toPromise();

        /**t
         * Cargamos las clínicas a la Store
         */
        this.setMessagesByApi(messagesData.Messages);
    }

    /**
     * Función que llama a la API para borrar un mensaje de un usuario con un SystemKey
     */
    async deleteMessage(messageId: string) {
        const appointmentsData = await this.deleteMessageApi(
            this.settingsQuery.systemKeyURL,
            this.userQuery.tokenId,
            messageId
        ).toPromise();

        /**
         * Volvemos a cargar las clínicas al borrar una
         */
        this.getMessages(null);
    }

    /**
     * Crea los mensajes a partir de una Array y las carga en el Store
     */
    setMessagesByApi(messages) {
        const messagesEntities = [];
        messages.forEach((message) => {
            messagesEntities.push(messageFactory(message));
        });

        this.setMessages(messagesEntities);
    }
}
