import { SettingsStore } from './settings.store';
import { Injectable } from '@angular/core';
import { SettingsQuery } from './settings.query';
import { PrimaryColorsModel } from '../../models/primary-colors.model';
import { ColorService } from '../../../core/services/color/color.service';
import { Capacitor } from '@capacitor/core';
import {
    StatusBar,
    Style,
    BackgroundColorOptions,
} from '@capacitor/status-bar';
import { HeaderBackgroundService } from '../header-background/header-background.service';

@Injectable({ providedIn: 'root' })
export class SettingsService {
    constructor(
        private settingsStore: SettingsStore,
        private settingsQuery: SettingsQuery,
        private colorService: ColorService
    ) {}

    /*-----------*/
    /** UPDATES */
    /*----------*/

    updateGenericKey(newGenericKey: number) {
        this.settingsStore.update({ genericKey: newGenericKey });
    }

    updateSystemKey(newSystemKey: string) {
        this.settingsStore.update({ systemKey: newSystemKey });
    }

    updateSystemKeyURL(newSystemKeyURL: string) {
        this.settingsStore.update({ systemKeyURL: newSystemKeyURL });
    }

    updateParentSystemKey(newParentsystemKey: string) {
        this.settingsStore.update({ parentSystemKey: newParentsystemKey });
    }

    updateBrowser(newBrowser: boolean) {
        this.settingsStore.update({ browser: newBrowser });
    }

    updateShowClinicDetails(newShowClinicDetails: boolean) {
        this.settingsStore.update({
            showClinicDetails: newShowClinicDetails,
        });
    }

    updateshowHeader(newShowHeader: boolean) {
        this.settingsStore.update({
            showHeader: newShowHeader,
        });
    }

    updateLoginWithURL(newLoginWithURL: boolean) {
        this.settingsStore.update({ loginWithURL: newLoginWithURL });
    }

    updateApiURL(newApiURL: string) {
        this.settingsStore.update({ apiURL: newApiURL });
    }

    updateLatitude(newLatitude: number) {
        this.settingsStore.update({ latitude: newLatitude });
    }

    updateLongitude(newLongitude: number) {
        this.settingsStore.update({ longitude: newLongitude });
    }

    updateStatus(newStatus: string) {
        this.settingsStore.update({ status: newStatus });
    }

    updateDebug(newDebug: boolean) {
        this.settingsStore.update({ debug: newDebug });
    }

    updateFlowwwServicesHost(newFlowwwServicesHost: string) {
        this.settingsStore.update({ flowwwServicesHost: newFlowwwServicesHost });
    }

    /*------------*/
    /** SERVICIOS */
    /*------------*/

    /**
     * Recorre la lista de los colores Genéricos (Flowwwer, LPG...etc) y estableces los colores primeramos en la Store
     */
    setPrimaryColors(primaryColorsList: Array<any>) {
        for (const genericColor of primaryColorsList) {
            if (genericColor.key === this.settingsQuery.genericKey) {
                let newColor: PrimaryColorsModel;
                newColor = genericColor;
                this.settingsStore.update({ primaryColors: newColor });
            }
        }
    }

    /**
     * Inicializa una App genérica según los datos almacenados en la store se Settings
     * Por ahora solamente hay que inicializar los colores según su configuración en Settings
     */
    initGenericApp() {
        this.configGenericColors();
        this.setHeightHeader();
    }

    /**
     * Configura los colores genéricos según los datos del store
     */
    configGenericColors() {
        this.colorService.updatePrimaryColorsPalettes(
            this.settingsQuery.primaryColors.primary,
            this.settingsQuery.primaryColors.secondary,
            this.settingsQuery.primaryColors.tertiary,
            this.settingsQuery.primaryColors.tertiaryContrast
        );
        this.updateStatusBarStyle();
    }

    updateStatusBarStyle() {
        if (Capacitor.isNativePlatform()) {
            let hexPrimary = this.settingsQuery.primaryColors.primary;
            if (hexPrimary.charAt(0) !== '#') {
                hexPrimary = `#${hexPrimary}`;
            }

            const options: BackgroundColorOptions = {
                color: hexPrimary,
            };
            StatusBar.setBackgroundColor(options);
            StatusBar.setStyle({
                style:
                    this.colorService.getContrastColor(hexPrimary) === '#FFFFFF'
                        ? Style.Dark
                        : Style.Light,
            });
        }
    }

    setHeightHeader() {
        const width = this.settingsQuery.browser
            ? document.documentElement.offsetWidth > 500
                ? 500
                : document.documentElement.offsetWidth
            : document.documentElement.offsetWidth;
        const heigth_header = (width * 100) / 180;
        //var heigth_header = width * 86 / 180;
        const heigth_header_A = (width * 14) / 180;
        const heigth_header_B = (width * 61) / 180;
        const heigth_header_C = (width * 25) / 180;
        const logo_header_W = (width * 67) / 120;
        const logo_header_H = (width * 29) / 120;
        const circle_header = (width * 5) / 18;
        const circle_header_radio = -((width * 5) / 18) / 2;
        const padding_top_title = (width * 5) / 18 / 2 + 15;
        document.documentElement.style.setProperty(`--app_width`, width + 'px');
        document.documentElement.style.setProperty(
            `--app_heigth_header`,
            Math.round(heigth_header) + 'px'
        );
        document.documentElement.style.setProperty(
            `--app_heigth_header_A`,
            Math.round(heigth_header_A) + 'px'
        );
        document.documentElement.style.setProperty(
            `--app_heigth_header_B`,
            Math.round(heigth_header_B) + 'px'
        );
        document.documentElement.style.setProperty(
            `--app_heigth_header_C`,
            Math.round(heigth_header_C) + 'px'
        );
        document.documentElement.style.setProperty(
            `--logo_header_W`,
            Math.round(logo_header_W) + 'px'
        );
        document.documentElement.style.setProperty(
            `--logo_header_H`,
            Math.round(logo_header_H) + 'px'
        );
        document.documentElement.style.setProperty(
            `--circle_header`,
            Math.round(circle_header) + 'px'
        );
        document.documentElement.style.setProperty(
            `--circle_header_radio`,
            Math.round(circle_header_radio) + 'px'
        );
        document.documentElement.style.setProperty(
            `--app_padding_top_title`,
            Math.round(padding_top_title) + 'px'
        );

        const notch = window
            .getComputedStyle(document.documentElement)
            .getPropertyValue('--ion-safe-area-top');

        if (notch !== ' 0px') {
            document.documentElement.style.setProperty(
                `--ion-safe-area-top`,
                Math.round(heigth_header_A) + 'px'
            );
        }
    }
}
