import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { HttpClient } from '@angular/common/http';
import { ServiceWizardStore } from './service-wizard.store';
import { ServiceWizard, serviceWizardFactory } from './service-wizard.model';
import { ServiceWizardQuery } from './service-wizard.query';
import { SettingsQuery } from '../settings/settings.query';
import { LanguageService } from '../../../core/services/language/language.service';
import { UserQuery } from '../user/user.query';
import { ClinicDetailQuery } from '../clinic-detail/clinic-detail.query';

@Injectable({ providedIn: 'root' })
export class ServiceWizardService {
    constructor(
        private serviceWizardStore: ServiceWizardStore,
        private serviceWizardQuery: ServiceWizardQuery,
        private settingsQuery: SettingsQuery,
        private languageService: LanguageService,
        private userQuery: UserQuery,
        private clinicDetailQuery: ClinicDetailQuery,
        private http: HttpClient
    ) {}

    /*---------------------*/
    /** OPERACIONES ENTITY */
    /*---------------------*/

    add(serviceWizard: ServiceWizard) {
        this.serviceWizardStore.add(serviceWizard);
    }

    update(id, serviceWizard: Partial<ServiceWizard>) {
        this.serviceWizardStore.update(id, serviceWizard);
    }

    remove(id: ID) {
        this.serviceWizardStore.remove(id);
    }

    setServicesWizard(servicesWizard: ServiceWizard[]) {
        this.serviceWizardStore.set(servicesWizard);
    }

    /*------------*/
    /** SERVICIOS */
    /*------------*/

    /**
     * Crea los servicioes a partir de los datos de la API y los carga en el Store
     */
    setServicesWizardByApi(servicesWizard) {
        const servicesWizardEntities = [];
        servicesWizard.forEach((serviceWizard) => {
            servicesWizardEntities.push(serviceWizardFactory(serviceWizard));
        });

        this.setServicesWizard(servicesWizardEntities);
    }

    setServiceChecked(serviceWizardId, checked: boolean) {
        this.serviceWizardStore.update(serviceWizardId, { checked });
        if (checked) {
            this.serviceWizardStore.update(serviceWizardId, {
                order: this.getLastServiceCheckedOrder(),
            });
        } else {
            this.serviceWizardStore.update(serviceWizardId, {
                order: 0,
            });
        }
    }

    toggleServiceChecked(serviceWizardId, hidde = false) {
        const checked = !this.serviceWizardQuery.getEntity(serviceWizardId)
            .checked;
        this.serviceWizardStore.update(serviceWizardId, { checked });

        if (checked) {
            this.serviceWizardStore.update(serviceWizardId, {
                order: this.getLastServiceCheckedOrder(),
                hidde
            });
        } else {
            this.serviceWizardStore.update(serviceWizardId, {
                order: 0,
                hidde
            });
        }
    }

    hiddeService(serviceWizardId, hidde: boolean) {
        this.serviceWizardStore.update(serviceWizardId, {
            hidde
        });
    }

    getLastServiceCheckedOrder(): number {
        const servicesChecked = this.serviceWizardQuery.getAll({
            filterBy: ({ checked }) => checked === true,
        });

        servicesChecked.sort((a, b) => {
            if (a.order < b.order) {
                return -1;
            }
            if (a.order > b.order) {
                return 1;
            }
            return 0;
        });
        return servicesChecked[servicesChecked.length - 1].order + 1;
    }

    async getServiceHtmlPage(serviceId: string) {
        const formData = new URLSearchParams();

        formData.append('SystemKey', this.settingsQuery.systemKeyURL);
        formData.append('Locale', this.languageService.getLocale());
        formData.append('ClientToken', this.userQuery.tokenId);
        formData.append('ClinicID', this.clinicDetailQuery.id);
        formData.append('ProductID', serviceId);
        formData.append('Cmd', 'c3001');

        const obj = {
            method: 'POST',
            headers: {
                'Content-Type':
                    'application/x-www-form-urlencoded;charset=UTF-8',
            },
            body: formData,
        };

        return await fetch(this.settingsQuery.apiURL, obj)
            .then((res) => res.text())
            .then((result) => result);
    }

    resetStore() {
        this.serviceWizardStore.reset();
    }
}
