import { Injectable } from '@angular/core';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { LanguageService } from '../language/language.service';
import { Observable } from 'rxjs';
import { SettingsQuery } from '../../../shared/state/settings/settings.query';
import { HttpClient } from '@angular/common/http';
import { CompanyQuery } from '../../../shared/state/company/company.query';

@Injectable({
    providedIn: 'root',
})

/**
 * Este servicio se va a encargar de todos las funciones de labels y etiquetas de la aplicación
 */
export class LibraryService {
    ERR_LABEL_UNKNOWN = '(ERR_LABEL_###_UNKNOWN)';

    constructor(
        private translateService: TranslateService,
        private languageService: LanguageService,
        private settingsQuery: SettingsQuery,
        private http: HttpClient,
        private companyQuery: CompanyQuery
    ) {}

    /**
     * Inicializamos la configuración inicial del TranslateService (Ngx-translate) para obtener el JSON del idioma correspondiente
     * Este método carga las labels offline a través del JSON para poder usarse en cualquier página, componente, vista etc
     *
     * Usamos la librería Ngx-translate para cargar el JSON de idioma offline de manera simple y porque proporciona todos los métodos
     * y servicios para usar labels en cualquier sitio. Además en un futuro se puede implementar el cambio de idioma  dinámico en tiempo real
     * si la api devolviese los labels del sistema en todos los idiomas. Los clientes podrían usar el idioma que quisieran.
     */
    loadOfflineLibrary() {
        this.translateService.setDefaultLang('es');
        this.translateService.use(this.languageService.getLocale());

        /**
         * Nos suscribimos al servicio TranslateService para que nos avise cuando se ha cargado el idioma la primera vez (sin operativa)
         */
        this.translateService.onDefaultLangChange
            .pipe(take(1))
            .subscribe((_) => {});
    }

    async loadOfflineCustomLibrary() {
        if (this.companyQuery.offlineCustomLibraryLoaded) {
            return;
        }
        try {
            this.http
                .get(
                    '/assets/i18n/' +
                        this.settingsQuery.currentSystemKey +
                        '/' +
                        this.languageService.getLocale() +
                        '.json'
                )
                .subscribe((data) => {
                    this.loadCompanyLibrary(data);
                });
        } catch (err) {}
    }

    /*
     * Reseteamos las traducciones de la app eliminando las traducciones de la company y dejando solo las offline
     * Para hacer el reseteo de las labels, tenemos que hacer un set translation vacío para que se refresquen en las vistas
     */
    removeCompnayLibrary() {
        this.translateService
            .reloadLang(this.languageService.getLocale())
            .subscribe(() => {
                /** Introducimos una traducción vacía para forzar el refresco de las traducciones en las vistas */
                this.translateService.setTranslation(
                    this.languageService.getLocale(),
                    {},
                    true
                );
            });
    }

    /**
     * Añadimos a la librería las Labels de la company
     */
    loadCompanyLibrary(companyLibrary) {
        Object.keys(companyLibrary).forEach((key, index) => {
            this.translateService.setTranslation(
                this.languageService.getLocale(),
                {
                    [key]: companyLibrary[key],
                },
                true
            );
        });
    }

    /**
     * IMPORTANTE: Esta función es SÍNCRONA y se devuelve la label que tenga en ese momento el TranslateService guardado.
     * IMPORTANTE: No usar si no estamos seguros de que vamos a tener la key en ese momento guardada (Se usarán otros métodos)
     * IMPORTANTE: No usar si queremos recibir los cambios que ocurran para esta KEY (Se usarán otros métodos)
     * Devolvemos el label de la librería pasándo una KEY
     * El servicio TranslateService (ngx-translate) nos devuelve la KEY cuando no se encuentra la label solicitada así que si esto ocurre
     * devolvemos una label personalizada ya que significa que no existe esa label
     */
    getLabel(key: string): string {
        let label: string;
        label = this.translateService.instant(key);
        if (label === key) {
            label = this.ERR_LABEL_UNKNOWN.replace('###', key);
        }
        return label;
    }

    getLabelAsync(key: string): Observable<string | any> {
        return this.translateService.get(key);
    }

    getLabelStream(key: string): Observable<string | any> {
        return this.translateService.getStreamOnTranslationChange(key);
    }
}
