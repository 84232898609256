import { Component, OnInit } from '@angular/core';
import { LoadingQuery } from '../../state/loading/loading.query';
import { SettingsQuery } from '../../state/settings/settings.query';

@Component({
    selector: 'app-loading-screen',
    templateUrl: './loading-screen.component.html',
    styleUrls: ['./loading-screen.component.scss'],
})
export class LoadingScreenComponent implements OnInit {
    isLoadingActive: boolean;
    showSpinner: boolean;
    browser$;

    constructor(
        private loadingQuery: LoadingQuery,
        private settingsQuery: SettingsQuery
    ) {
        this.loadingQuery.select('active').subscribe((active) => {
            Promise.resolve(null).then(() => (this.isLoadingActive = active));
        });

        this.loadingQuery.select('showSpinner').subscribe((showSpinner) => {
            Promise.resolve(null).then(() => (this.showSpinner = showSpinner));
        });

        this.browser$ = this.settingsQuery.select('browser');
    }

    ngOnInit() {}
}
