import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { KeyboardStore } from './keyboard.store';

@Injectable({ providedIn: 'root' })
export class KeyboardService {
    constructor(
        private keyboardStore: KeyboardStore,
        private http: HttpClient
    ) {}

    /*-----------*/
    /** UPDATES */

    /*----------*/

    updateIsShowing(newIsShowing: boolean) {
        this.keyboardStore.update({
            isShowing: newIsShowing,
        });
    }
}
