import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { MessageStore, MessageState } from './message.store';
import { Message } from './message.model';

@Injectable({
  providedIn: 'root'
})
export class MessageQuery extends QueryEntity<MessageState, Message> {

  constructor(protected store: MessageStore) {
    super(store);
  }

}
