import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ServiceWizard } from './service-wizard.model';

export interface ServiceWizardState extends EntityState<ServiceWizard> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'service-wizard', resettable: true })
export class ServiceWizardStore extends EntityStore<
    ServiceWizardState,
    ServiceWizard
> {
    constructor() {
        super();
    }
}
