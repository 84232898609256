import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { UserStore, UserState } from './user.store';

@Injectable({ providedIn: 'root' })
export class UserQuery extends Query<UserState> {
    constructor(protected store: UserStore) {
        super(store);
    }

    /*-----------*/
    /** GETTERS */
    /*----------*/

    get clientTMP(): boolean {
        return this.getValue()?.isTemporal;
    }

    get tokenId(): string {
        return this.getValue()?.tokenId;
    }

    get phone(): string {
        return this.getValue()?.phone1;
    }

    get email(): string {
        return this.getValue()?.email;
    }

    get enableRGPD(): boolean {
        return this.getValue()?.enableRGPD;
    }

    get points(): number {
        return this.getValue()?.points;
    }

    get onlineShop(): boolean {
        return this.getValue()?.onlineShop;
    }

    get lastLoginByUrl(): boolean {
        return this.getValue()?.lastLoginByUrl;
    }

    get advisorShareText(): string {
        return this.getValue()?.referrer?.advisorShareText;
    }

    get advisorCode(): string {
        return this.getValue()?.referrer?.code;
    }

    get deleteAvailable(): boolean {
        return this.getValue()?.deleteAvailable;
    }

    get appDisabled(): boolean {
        return this.getValue()?.appDisabled;
    }
}
