import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    ServiceWizard,
    ServiceWizardCheckedEvent,
} from '../../../state/service-wizard/service-wizard.model';
import { FamilyFolderModel } from '../../../models/family-folder.model';
import { PackWizard } from 'src/app/shared/state/pack-wizard/pack-wizard.model';

@Component({
    selector: 'app-family-service-list',
    templateUrl: './family-service-list.component.html',
    styleUrls: ['./family-service-list.component.scss'],
})
export class FamilyServiceListComponent implements OnInit {
    @Input() families: FamilyFolderModel[];
    @Input() iconFamilyItem: string;
    @Input() isLoadingContent: boolean;
    @Input() servicesWizard: ServiceWizard[];
    @Input() noMoreServices: boolean;
    @Input() appDelegate: boolean;
    @Input() packs: PackWizard[] = [];

    @Output() familyClickedEvent = new EventEmitter<FamilyFolderModel>();
    @Output() serviceClickedEvent = new EventEmitter<
        ServiceWizardCheckedEvent
    >();
    @Output() serviceToggleEvent = new EventEmitter<any>();
    @Output() changeServiceSearchbarEvent: EventEmitter<any> = new EventEmitter<
        any
    >();
    @Output() packToggleEvent = new EventEmitter<any>();

    constructor() {}

    ngOnInit(): void {}

    onFamilyClicked(family: FamilyFolderModel) {
        this.familyClickedEvent.emit(family);
    }

    onServiceClicked(serviceAndCheckedEvent: ServiceWizardCheckedEvent) {
        this.serviceClickedEvent.emit(serviceAndCheckedEvent);
    }

    onServiceToggle(service: ServiceWizard) {
        this.serviceToggleEvent.emit(service);
    }

    onPackToggle(pack: PackWizard) {
        this.packToggleEvent.emit(pack);
    }
}
