import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class AutocloseOverlaysService {
    constructor(private modalController: ModalController) {}

    async trigger() {
        // close modal
        try {
            const element = await this.modalController.getTop();
            if (element) {
                element.dismiss();
                return;
            }
        } catch (error) {
            console.log(error);
        }
    }
}
