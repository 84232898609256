import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ProductWizard } from './product-wizard.model';

export interface ProductWizardState extends EntityState<ProductWizard> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'product-wizard', resettable: true })
export class ProductWizardStore extends EntityStore<
    ProductWizardState,
    ProductWizard
> {
    constructor() {
        super();
    }
}
