import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { SettingsQuery } from 'src/app/shared/state/settings/settings.query';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    constructor(
        private http: HttpClient,
        private settingsQuery: SettingsQuery
    ) {}

    callApi(params): Observable<any> {
        return this.settingsQuery.select('apiURL').pipe(
            filter((apiURL) => apiURL !== ''),
            take(1),
            switchMap((apiURL) => {
                return this.http.post<any>(apiURL, params);
            })
        );
    }
}
