import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ServiceWizardStore, ServiceWizardState } from './service-wizard.store';
import { ServiceWizard } from './service-wizard.model';

@Injectable({
    providedIn: 'root',
})
export class ServiceWizardQuery extends QueryEntity<
    ServiceWizardState,
    ServiceWizard
> {
    constructor(protected store: ServiceWizardStore) {
        super(store);
    }
}
