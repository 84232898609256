import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-buttons-footer',
    templateUrl: './buttons-footer.component.html',
    styleUrls: ['./buttons-footer.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ButtonsFooterComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
