import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { UserStore } from './user.store';
import { Observable } from 'rxjs';
import { UserQuery } from './user.query';
import { SettingsQuery } from '../settings/settings.query';
import { LanguageService } from '../../../core/services/language/language.service';
import { ClinicDetailQuery } from '../clinic-detail/clinic-detail.query';
import { CompanyQuery } from '../company/company.query';
import { UserRegisterModel } from '../../models/userRegister.model';
import { UserEditModel } from '../../models/userEdit.model';
import { ApiService } from 'src/app/core/services/api/api.service';
import { MessageService } from '../message/message.service';
import { filter, take } from 'rxjs/operators';
import { Capacitor } from '@capacitor/core';
import { Router } from '@angular/router';
import { LoginError } from '../../errors/login.error';
import { Patterns } from '../../patterns/patterns';
import { StorageService } from 'src/app/core/services/storage/storage.service';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(
        private userStore: UserStore,
        private userQuery: UserQuery,
        private settingsQuery: SettingsQuery,
        private languageService: LanguageService,
        private clinicDetailQuery: ClinicDetailQuery,
        private companyQuery: CompanyQuery,
        private apiService: ApiService,
        private messageService: MessageService,
        private router: Router,
        private storage: StorageService
    ) {}

    /*-----------*/
    /** UPDATES */
    /*----------*/

    updateTokenIdAux(newTokenIdAux: string) {
        this.userStore.update({ tokenIdAux: newTokenIdAux });
    }

    updateIsTemporal(newIsTemporal: boolean) {
        this.userStore.update({ isTemporal: newIsTemporal });
    }

    updateOnlineShop(newOnlineShop: boolean) {
        this.userStore.update({ onlineShop: newOnlineShop });
    }

    updateLastLoginByUrl(newLastLoginByUrl: boolean) {
        this.userStore.update({
            lastLoginByUrl: newLastLoginByUrl,
        });
    }

    /**
     * Cargar un usuario en la Store
     */
    updateUser(user, currentTokenId, clientTMP, clientDeleteAvailable) {
        this.userStore.update({
            tokenId: currentTokenId,
            nameFull: user.ClientNameFull,
            phone1: user.ClientPhone1,
            phone2: user.ClientPhone2,
            phone3: user.ClientPhone3,
            address: user.ClientAddress,
            email: user.ClientEMail,
            appDisabled:
                user.ClientAppDisabled != null
                    ? user.ClientAppDisabled === '-1'
                    : false,
            enableRGPD:
                user.ClientEnableRGPD != null
                    ? user.ClientEnableRGPD === '-1'
                    : false,
            points: user.ClientPoints ?? null,
            isTemporal: clientTMP != null ? clientTMP === '-1' : false,
            clinicId: user.ClientClinicID,
            deleteAvailable: clientDeleteAvailable === '-1',
        });
    }

    /*---------------*/
    /** LLAMADAS API */
    /*---------------*/

    /**
     * Obtenemos los datos del programa amigo del usuario
     */
    private getUserReferrerApi(
        companySystemKey: string,
        tokenId: string,
        clinicId: string
    ): Observable<any> {
        let params = new HttpParams();
        params = params.append('SystemKey', companySystemKey);
        params = params.append('ClientToken', tokenId);
        params = params.append('ClinicID', clinicId);
        params = params.append('Cmd', 'c1601');
        params = params.append('Locale', this.languageService.getLocale());

        return this.apiService.callApi(params);
    }

    /**
     * Obtenemos el usuario de la API a partir de un tokenId
     */
    private getUserApi(
        compnaySystemKey,
        tokenId,
        clientDeviceIc
    ): Observable<any> {
        let params = new HttpParams();
        params = params.append('SystemKey', compnaySystemKey);
        params = params.append('ClientToken', tokenId);
        params = params.append('ClientDeviceID', clientDeviceIc);
        params = params.append('Cmd', 'c1023');
        params = params.append('Locale', this.languageService.getLocale());

        return this.apiService.callApi(params);
    }

    /**
     * Registrar usuario en una company y en una clínica
     */
    private saveUserApi(
        compnaySystemKey,
        clinicId,
        clinicRGPDDisable,
        userRegister: UserRegisterModel,
        clientDeviceId
    ): Observable<any> {
        let params = new HttpParams();
        params = params.append('SystemKey', compnaySystemKey);
        params = params.append('clinicId', clinicId);
        params = params.append('clientName', userRegister.name);
        params = params.append('clientSurname1', userRegister.surname1);
        params = params.append('clientSurname2', userRegister.surname2);
        params = params.append('clientPhone1', userRegister.phone);
        params = params.append('clientEmail', userRegister.email);
        params = params.append(
            'ClientCardNum',
            userRegister.referrerCode ?? ''
        );
        params = params.append('clientRgpd', clinicRGPDDisable);
        params = params.append(
            'clientEnableGeneralConditions',
            userRegister.enableGeneralConditions ? '-1' : '0'
        );
        params = params.append(
            'clientEnableRgpd',
            userRegister.enableRgpd ? '-1' : '0'
        );
        params = params.append('clientDeviceId', clientDeviceId);
        params = params.append('Source', 'Custom');
        params = params.append('Cmd', 'c1021b');
        params = params.append('Locale', this.languageService.getLocale());

        return this.apiService.callApi(params);
    }

    /**
     * Obtener clientToken de un cliente a través de su código OTP
     */
    private loginWithOtpApi(
        otpCode: string,
        hashCode: string,
        clientDeviceId: string,
        compnaySystemKey: string
    ): Observable<any> {
        let params = new HttpParams();
        params = params.append('ClientOTP', otpCode);
        params = params.append('ClientHash', hashCode);
        params = params.append('ClientDeviceId', clientDeviceId);
        params = params.append('SystemKey', compnaySystemKey);
        params = params.append('Cmd', 'c1022');
        params = params.append('Locale', this.languageService.getLocale());

        return this.apiService.callApi(params);
    }

    /**
     * Obtenemos el usuario de la API a partir de un tokenId
     * TODO: Ver si cuando el cliente tiene puntos a null, a la api se le envía null o 0, mirar app de javi
     */
    private editUserApi(
        compnaySystemKey,
        clinicId,
        userEdit: UserEditModel,
        clientDeviceId
    ): Observable<any> {
        let params = new HttpParams();
        params = params.append('SystemKey', compnaySystemKey);
        params = params.append('ClinicID', clinicId);
        params = params.append('ClientToken', userEdit.tokenId);
        params = params.append('ClientPhone1', userEdit.phone);
        params = params.append('ClientEmail', userEdit.email);
        params = params.append(
            'ClientPoints',
            userEdit.points ? userEdit.points.toString() : '0'
        );
        params = params.append(
            'ClientEnableRGPD',
            userEdit.enableRgpd ? '-1' : '0'
        );
        params = params.append('ClientDeviceID', clientDeviceId);
        params = params.append('Cmd', 'c1071');
        params = params.append('Locale', this.languageService.getLocale());

        return this.apiService.callApi(params);
    }

    /**
     * Comprueba si el usuario puede agendar más citas desde el app
     * Esta llamada devuelve un error si el usuario no puede agendar más citas
     * TODO: Preguntar por qué se envían tantos parámetros a undefined
     */
    private checkUserCanMakesAppointmentApi(
        compnaySystemKey,
        tokenId,
        clinicId
    ): Observable<any> {
        let params = new HttpParams();
        params = params.append('SystemKey', compnaySystemKey);
        params = params.append('ClientToken', tokenId);
        params = params.append('ClinicID', clinicId);
        params = params.append('Services', undefined);
        params = params.append('ProfessionalID', undefined);
        params = params.append('ProfessionalName', undefined);
        params = params.append('DiaryDate', undefined);
        params = params.append('TimeEnd', undefined);
        params = params.append('CabineID', undefined);
        params = params.append('TimeStart', undefined);
        params = params.append('Cmd', 'c1091');
        params = params.append('Locale', this.languageService.getLocale());

        return this.apiService.callApi(params);
    }

    /**
     * Borra la cuenta del cliente en flowww y hace logout
     */
    private deleteClientApi(
        compnaySystemKey,
        clinicId,
        tokenId
    ): Observable<any> {
        let params = new HttpParams();
        params = params.append('SystemKey', compnaySystemKey);
        params = params.append('ClientToken', tokenId);
        params = params.append('ClinicID', clinicId);
        params = params.append('Cmd', 'c1072');
        params = params.append('Locale', this.languageService.getLocale());

        return this.apiService.callApi(params);
    }

    /*------------*/
    /** SERVICIOS */
    /*------------*/

    /**
     * Obtenemos los datos del programa amigo del usuario actual y lo guardamos en akita.
     */
    async getReferrerData(): Promise<void> {
        const referrerData = await this.getUserReferrerApi(
            this.settingsQuery.systemKeyURL,
            await this.getCurrentTokenId(),
            this.clinicDetailQuery.id
        ).toPromise();

        this.userStore.update({
            referrer: {
                code: referrerData.Client.LastCardNum,
                link: Patterns.url.test(referrerData.Client.FlwUrl)
                    ? referrerData.Client.FlwUrl
                    : '',
                enrolledUsers: referrerData.Client.ClientCardEnroll,
                validatedUsers: referrerData.Client.ClientCardConfirmed,
                points: referrerData.Client.ClientPoints,
                advisorShareText: referrerData.AdvisorShareText,
            },
        });
    }

    /**
     * Cargamos el usuario de la API en el local Storage PERO NO LO CARGAMOS EN AKITA
     * SOLO FUNCIONA PARA PERSONALIZADAS RESELLER = 0
     */
    async checkUser() {
        const currentTab = this.router.url.split('/')[3]?.split('?')[0];
        await this.checkChangesResellerCompnay();
        const currentTokenId = await this.getCurrentTokenId();
        if (
            this.settingsQuery.systemKeyURL &&
            this.companyQuery.systemReseller === '0' &&
            currentTokenId &&
            currentTokenId !== this.userQuery.getValue()?.tokenId
        ) {
            try {
                const deviceId = await this.storage.get('idDevice');
                const userData = await this.getUserApi(
                    this.settingsQuery.systemKeyURL,
                    currentTokenId,
                    deviceId
                ).toPromise();
                if (
                    this.userQuery.getValue()?.tokenIdAux !== '' &&
                    !Capacitor.isNativePlatform()
                ) {
                    this.saveCurrentTokenLocalStorage(
                        currentTokenId,
                        userData.Client.ClientClinicID
                    );
                }

                /** Si nos hemos logeado por URL, reseteamos el tokenIdAux */
                if (
                    this.userQuery.getValue()?.tokenIdAux !== '' &&
                    !Capacitor.isNativePlatform()
                ) {
                    this.updateTokenIdAux('');
                }
            } catch (e) {
                /** Si no existe el usuario, borramos el LocalStorage y reseteamos el State de Akita */
                this.deleteCurrentTokenLocalStorage();
                this.userStore.reset();
            }
        } else {
            this.updateTokenIdAux('');
        }
    }

    /**
     * Cargamos el usuario de la API con los datos de la store y lo guardamos en la store
     */
    async checkUserAndLoad(forceUpdate = false, checkLogin = false) {
        let errorLogin = false;
        const currentTab = this.router.url.split('/')[3]?.split('?')[0];
        /** Añadimos seguridad para no cargar un usuario de un systemkey en una clínica de otro systemkey */
        if (
            this.settingsQuery.systemKeyURL !== this.clinicDetailQuery.systemKey
        ) {
            throw new Error('Generic Error');
        }
        await this.checkChangesResellerCompnay();
        const currentTokenId = await this.getCurrentTokenId();
        if (
            this.settingsQuery.systemKeyURL &&
            this.clinicDetailQuery.id &&
            currentTokenId &&
            (currentTokenId !== this.userQuery.getValue()?.tokenId ||
                forceUpdate)
        ) {
            try {
                const deviceId = await this.storage.get('idDevice');
                const userData = await this.getUserApi(
                    this.settingsQuery.systemKeyURL,
                    currentTokenId,
                    deviceId
                ).toPromise();

                /** Agujero de seguridad, si nos intentamos logear en una clínica con reseller -1
                 * Y el login nos devuelve un cliente con una clínica distinta a la que me estoy logueando
                 * tengo que dar error en el login */
                if (
                    userData.Client.ClientClinicID &&
                    this.companyQuery.systemReseller === '-1' &&
                    userData.Client.ClientClinicID.toString() !==
                        this.clinicDetailQuery.id
                ) {
                    console.log(
                        'EL usuario no pertenece a la clínica donde se está intentando logear'
                    );
                    throw 'Error Login';
                }

                this.updateUser(
                    userData.Client,
                    currentTokenId,
                    userData.ClientTMP,
                    userData.ClientDeleteAvailable
                );
                if (
                    this.userQuery.getValue()?.tokenIdAux !== '' &&
                    !Capacitor.isNativePlatform()
                ) {
                    this.saveCurrentTokenLocalStorage(
                        currentTokenId,
                        userData.Client.ClientClinicID
                    );
                }

                /** Siempre que nos logueamos, cargamos los mensajes */
                /** Puede que aquí se llegue antes de tener el usuario, por eso me tengo que suscribir a Akita a esperarlo */
                this.userQuery
                    .select()
                    .pipe(
                        filter((params) => params.tokenId !== ''),
                        take(1)
                    )
                    .subscribe((_) => {
                        /** TODO: Que significa el parámetro messageID */
                        this.messageService.getMessages(null);
                    });

                /** Si nos hemos logeado por URL, reseteamos el tokenIdAux */
                if (
                    this.userQuery.getValue()?.tokenIdAux !== '' &&
                    !Capacitor.isNativePlatform()
                ) {
                    this.updateTokenIdAux('');
                }
            } catch (e) {
                /** Si no existe el usuario, borramos el LocalStorage y reseteamos el State de Akita */
                this.deleteCurrentTokenLocalStorage();
                this.userStore.reset();
                errorLogin = true;
            }
        }

        /** Seguridad global del sistema, si entramos en una de estas tabs y no estamos registrados
         * (para estas tabs siempre entra el código en esta comprobación)
         * nos vamos a registro
         */
        if (
            (currentTokenId === '' || errorLogin) &&
            checkLogin &&
            (currentTab === 'user-account' ||
                currentTab === 'friend-program' ||
                currentTab === 'user-account-edit' ||
                currentTab === 'treatment' ||
                currentTab === 'appointment' ||
                currentTab === 'message' ||
                currentTab === 'message-detail')
        ) {
            throw new LoginError('LoginError');
        } else {
            if (errorLogin) {
                throw new Error('Generic Error');
            }
        }
    }

    /** Función auxiliar que devuelve el TokenId del usuario bien por URL o bien por Local Storage */
    async getCurrentTokenId() {
        let currentTokenId = '';
        if (this.userQuery.getValue()?.tokenIdAux !== '') {
            /** Cargamos el tokenId por parámetro de URL */
            currentTokenId = this.userQuery.getValue()?.tokenIdAux;
        } else {
            /** Cargamos el tokenId por Local Storage */
            if (this.companyQuery.systemReseller === '0') {
                const data = await this.storage.get(
                    'tokens-' + this.settingsQuery.systemKeyURL
                );
                if (data?.id1) {
                    currentTokenId = data.id1;
                    if (data?.lastLoginByUrl !== null) {
                        this.updateLastLoginByUrl(data.lastLoginByUrl);
                    }
                }
            } else {
                const data = await this.storage.get(
                    'tokens-' +
                        this.settingsQuery.systemKeyURL +
                        '-' +
                        this.clinicDetailQuery.id
                );
                if (data?.id1) {
                    currentTokenId = data.id1;
                    if (data?.lastLoginByUrl !== null) {
                        this.updateLastLoginByUrl(data.lastLoginByUrl);
                    }
                }
            }
        }
        return currentTokenId;
    }

    async saveCurrentTokenLocalStorage(currentTokenId, clientClinicID) {
        /** Actualizamos LocalStorage */
        this.updateLastLoginByUrl(
            this.userQuery.getValue()?.tokenIdAux !== '' ? true : false
        );
        if (this.companyQuery.systemReseller === '0') {
            await this.storage.set(
                'tokens-' + this.settingsQuery.systemKeyURL,
                {
                    ClinicID: clientClinicID.toString(),
                    SystemKey: this.settingsQuery.systemKeyURL,
                    SystemReseller: this.companyQuery.systemReseller,
                    id1: currentTokenId,
                    lastLoginByUrl:
                        this.userQuery.getValue()?.tokenIdAux !== ''
                            ? true
                            : false,
                }
            );
        } else {
            await this.storage.set(
                'tokens-' +
                    this.settingsQuery.systemKeyURL +
                    '-' +
                    this.clinicDetailQuery.id,
                {
                    ClinicID: this.clinicDetailQuery.id,
                    SystemKey: this.settingsQuery.systemKeyURL,
                    SystemReseller: this.companyQuery.systemReseller,
                    id1: currentTokenId,
                    lastLoginByUrl:
                        this.userQuery.getValue()?.tokenIdAux !== ''
                            ? true
                            : false,
                }
            );
        }
    }

    deleteCurrentTokenLocalStorage() {
        if (this.companyQuery.systemReseller === '0') {
            this.storage.remove('tokens-' + this.settingsQuery.systemKeyURL);
        } else {
            this.storage.remove(
                'tokens-' +
                    this.settingsQuery.systemKeyURL +
                    '-' +
                    this.clinicDetailQuery.id
            );
        }
    }

    async checkChangesResellerCompnay() {
        if (this.companyQuery.systemReseller === '0') {
            const data = await this.storage.get(
                'tokens-' + this.settingsQuery.systemKeyURL
            );

            if (!data) {
                /** El reseller ha cambiado de -1 -> 0 */
                /** Cambiamos el storage */
                /** Busco algún registro de reseller -1 y si lo encuentro, creo el registro de reseller 0 */
                const keysStorage = await this.storage.keys();
                let keyAux = null;
                for (let i = 0; i < keysStorage.length; i++) {
                    if (
                        keysStorage[i].includes(
                            'tokens-' + this.settingsQuery.systemKeyURL
                        )
                    ) {
                        keyAux = keysStorage[i];
                        break;
                    }
                }

                if (keyAux) {
                    const dataAux = await this.storage.get(keyAux);
                    /** Borramos el registro que vamos a copiar para no tener tokens duplicados */
                    await this.storage.remove(keyAux);

                    await this.storage.set(
                        'tokens-' + this.settingsQuery.systemKeyURL,
                        {
                            ClinicID: dataAux.ClinicID,
                            SystemKey: dataAux.SystemKey,
                            SystemReseller: this.companyQuery.systemReseller,
                            id1: dataAux.id1,
                            lastLoginByUrl: dataAux.lastLoginByUrl,
                        }
                    );
                }
            }
        } else {
            const data = await this.storage.get(
                'tokens-' +
                    this.settingsQuery.systemKeyURL +
                    '-' +
                    this.clinicDetailQuery.id
            );

            if (!data) {
                const dataCheck = await this.storage.get(
                    'tokens-' + this.settingsQuery.systemKeyURL
                );
                if (dataCheck?.id1) {
                    /** El reseller ha cambiado de 0 -> -1 */
                    /** Cambiamos el storage */
                    /** Buscamos el registro de reseller 0, y si coincide la clínica con la que me
                     * estoy registrando, creo el registro nuevo
                     */
                    const dataAux = await this.storage.get(
                        'tokens-' + this.settingsQuery.systemKeyURL
                    );
                    if (
                        dataAux &&
                        dataAux.ClinicID === this.clinicDetailQuery.id
                    ) {
                        /** Borramos el registro que vamos a copiar para no tener tokens duplicados */
                        await this.storage.remove(
                            'tokens-' + this.settingsQuery.systemKeyURL
                        );

                        await this.storage.set(
                            'tokens-' +
                                this.settingsQuery.systemKeyURL +
                                '-' +
                                this.clinicDetailQuery.id,
                            {
                                ClinicID: dataAux.ClinicID,
                                SystemKey: dataAux.SystemKey,
                                SystemReseller: this.companyQuery
                                    .systemReseller,
                                id1: dataAux.id1,
                                lastLoginByUrl: dataAux.lastLoginByUrl,
                            }
                        );
                        await this.storage.remove(
                            'tokens-' + this.settingsQuery.systemKeyURL
                        );
                    }
                }
            }
        }
    }

    async registerUser(
        userRegister: UserRegisterModel
    ): Promise<{
        clientToken: string;
        hashCode: string;
    }> {
        const deviceId = await this.storage.get('idDevice');
        const userRegisterData = await this.saveUserApi(
            this.settingsQuery.systemKeyURL,
            this.clinicDetailQuery.id,
            this.clinicDetailQuery.RGPDDisable,
            userRegister,
            deviceId
        ).toPromise();
        if (
            userRegisterData.ClientToken &&
            userRegisterData.ClientToken !== ''
        ) {
            this.saveCurrentTokenLocalStorage(
                userRegisterData.ClientToken,
                this.clinicDetailQuery.id
            );
        }
        return {
            clientToken: userRegisterData.ClientToken,
            hashCode: userRegisterData.ClientHash,
        };
    }

    async loginWithOtp(otpCode: string, hashCode: string) {
        const deviceId = await this.storage.get('idDevice');
        const userRegisterData = await this.loginWithOtpApi(
            otpCode,
            hashCode,
            deviceId,
            this.settingsQuery.systemKeyURL
        ).toPromise();

        this.saveCurrentTokenLocalStorage(
            userRegisterData.ClientToken,
            this.clinicDetailQuery.id
        );
    }

    async editEnableRGPDUser(userEdit: UserEditModel) {
        const deviceId = await this.storage.get('idDevice');
        await this.editUserApi(
            this.settingsQuery.systemKeyURL,
            this.clinicDetailQuery.id,
            userEdit,
            deviceId
        ).toPromise();
        this.userStore.update({ enableRGPD: userEdit.enableRgpd });
    }

    async editUser(userEdit: UserEditModel) {
        const deviceId = await this.storage.get('idDevice');
        await this.editUserApi(
            this.settingsQuery.systemKeyURL,
            this.clinicDetailQuery.id,
            userEdit,
            deviceId
        ).toPromise();
        this.userStore.update({ phone1: userEdit.phone });
        this.userStore.update({ email: userEdit.email });
        this.userStore.update({ enableRGPD: userEdit.enableRgpd });
    }

    async deleteClient(tokenId: string) {
        await this.deleteClientApi(
            this.settingsQuery.systemKeyURL,
            this.clinicDetailQuery.id,
            tokenId
        ).toPromise();
        this.deleteCurrentTokenLocalStorage();
        this.resetStore();
    }

    async checkUserCanMakesAppointment() {
        const userData = await this.checkUserCanMakesAppointmentApi(
            this.settingsQuery.systemKeyURL,
            this.userQuery.tokenId,
            this.clinicDetailQuery.id
        ).toPromise();
    }

    resetStore() {
        this.userStore.reset();
    }
}
