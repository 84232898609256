import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SettingsQuery } from '../../state/settings/settings.query';
import { ClinicDetailQuery } from '../../state/clinic-detail/clinic-detail.query';
import { Observable } from 'rxjs';
import { AppRolesModel, GenericKeysModel } from '../../models';
import { NavController } from '@ionic/angular';
import { ClinicQuery } from '../../state/clinic/clinic.query';
import { Clinic } from '../../state/clinic/clinic.store';
import { HeaderPictureQuery } from '../../state/header-picture/header-picture.query';

@Component({
    selector: 'app-user-tabs-header',
    templateUrl: './user-tabs-header.component.html',
    styleUrls: ['./user-tabs-header.component.scss'],
})
export class UserTabsHeaderComponent implements OnInit {
    @Input() showEditButton = false;
    @Input() imageHeader: string;
    @Input() backgroundHeader: string;
    @Input() iconHeader: string;
    @Output() clickEditEvent = new EventEmitter<any>();

    isPersonalized$: Observable<boolean>;
    isBrowser$: Observable<boolean>;
    showHeader$: Observable<boolean>;
    clinicComercialName$: Observable<string>;
    currentGenericKey$: Observable<number>;
    genericKeyLPG = GenericKeysModel.LPG;
    genericKeyVoooy = GenericKeysModel.Voooy;
    clinics$: Observable<Clinic[]>;
    logoHeader: string = null;
    appRole: number;
    roleGeneric = AppRolesModel.Generic;
    roleGenericBrowser = AppRolesModel.GenericBrowser;
    rolePersonalizedBrowser = AppRolesModel.PersonalizedBrowser;
    avatarInit = false;

    constructor(
        private settingsQuery: SettingsQuery,
        private clinicDetailQuery: ClinicDetailQuery,
        private navCtrl: NavController,
        private clinicQuery: ClinicQuery,
        private headerPicturesQuery: HeaderPictureQuery
    ) {}

    ngOnInit(): void {
        this.isPersonalized$ = this.settingsQuery.selectIsPersonalized();
        this.isBrowser$ = this.settingsQuery.select('browser');
        this.showHeader$ = this.settingsQuery.select('showHeader');
        this.clinicComercialName$ = this.clinicDetailQuery.select(
            'commercialName'
        );
        this.currentGenericKey$ = this.settingsQuery.select('genericKey');

        this.headerPicturesQuery
            .select('homeCompany')
            .subscribe((homeCompany) => {
                this.logoHeader = homeCompany;
            });

        this.appRole = this.settingsQuery.appRole;

        this.clinics$ = this.clinicQuery.select('clinicsFound');

        setTimeout(() => {
            this.avatarInit = true;
        }, 150);
    }

    clickEdit(event) {
        event.stopPropagation();
        this.clickEditEvent.emit();
    }

    goHome(event) {
        event.stopPropagation();
        /** Solo puedo volver atrás si no estoy en personalzas o si soy personalizada y tengo más de 1 centro */
        if (
            !this.settingsQuery.isPersonalized() ||
            this.clinicQuery.getValue().clinicsFound.length > 1
        ) {
            this.navCtrl.navigateBack(
                '/' +
                    (this.settingsQuery.isPersonalized()
                        ? this.settingsQuery.currentSystemKey
                        : '')
            );
        }
    }

    stopPropagation(event) {
        event.stopPropagation();
    }
}
