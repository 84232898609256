import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface KeyboardState {
    isShowing: boolean;
}

export function createInitialState(): KeyboardState {
    return {
        isShowing: false,
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'keyboard' })
export class KeyboardStore extends Store<KeyboardState> {
    constructor() {
        super(createInitialState());
    }
}
