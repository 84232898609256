import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { PackWizard } from './pack-wizard.model';

export interface PackWizardState extends EntityState<PackWizard> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'service-wizard', resettable: true})
export class PackWizardStore extends EntityStore<
    PackWizardState,
    PackWizard
> {
    constructor() {
        super();
    }
}
