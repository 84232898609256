import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { UserReferrerData } from '../../models/userReferrerData';

export interface UserState {
    tokenIdAux: string /** Token que se guarda para utilizarse cuando toque */;
    tokenId: string /** TokenId del cliente */;
    nameFull: string /** Nombre completo del cliente  */;
    phone1: string /** Teléfono 1 del cliente */;
    phone2: string /** Teléfono 2 del cliente */;
    phone3: string /** Teléfono 3 del cliente */;
    address: string /** Dirección del client */;
    email: string /** email del cliente */;
    appDisabled: boolean /** TODO Javi no lo usa: Impide coger citas desde la APP, lo hace el back */;
    enableRGPD: boolean /** Indica si el cliente ha aceptado la RGPD */;
    points: number /** Puntos del cliente, TODO:si no llega en la respuesta, eso significa q el sistemKey no tiene sistema de puntos??? */;
    isTemporal: boolean /** Indica si el cliente es temporal en el sistema */;
    onlineShop: boolean /** Determina si el usuario puede comprar desde la app (si ha comprado ya y del setting SET_FWA_APP_BOUGHT) */;
    clinicId: string /** Clínica en la que se registró el usuario */;
    lastLoginByUrl: boolean /** Indica con qué método ha sido su último login, si por URL o no */;
    referrer: UserReferrerData /** Guardar los datos para la pantalla de programa amigo */;
    deleteAvailable: boolean /** Indica si es posible borrar este cliente */;
}

export function createInitialState(): UserState {
    return {
        tokenIdAux: '',
        tokenId: '',
        nameFull: '',
        phone1: '',
        phone2: '',
        phone3: '',
        address: '',
        email: '',
        appDisabled: false,
        enableRGPD: false,
        points: null,
        isTemporal: false,
        onlineShop: null,
        clinicId: '',
        lastLoginByUrl: null,
        deleteAvailable: true,
        referrer: {
            points: 0,
            validatedUsers: 0,
            enrolledUsers: 0,
            link: '',
            code: '',
            advisorShareText: '',
        },
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user', resettable: true })
export class UserStore extends Store<UserState> {
    constructor() {
        super(createInitialState());
    }
}
