import { Injectable } from '@angular/core';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { Platform } from '@ionic/angular';
import { SettingsService } from 'src/app/shared/state/settings/settings.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Geolocation } from '@capacitor/geolocation';
import { SettingsQuery } from 'src/app/shared/state/settings/settings.query';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root',
})

/**
 * Servicio encargado de calcular tu servidor de API más cercano según las coordenadas del teléfono
 * Se comunicará con el store para actualizar la API URL
 */
export class LocationService {
    locations;
    intervalLocation;
    apiUrlDefault = 'https://api.flowww.ws/app_get_v0.2.asp';
    currentApiUrl = null;

    constructor(
        private diagnostic: Diagnostic,
        private platform: Platform,
        private androidPermissions: AndroidPermissions,
        private settingsService: SettingsService,
        private http: HttpClient,
        private router: Router,
        private settingsQuery: SettingsQuery
    ) {}

    /** El archivo donde están las Apis URL de las regiones de los servidores es un recurso externo
     * no compilado para que se pueda modificar fácilmente sin necesidad de compilación
     */
    async loadLocations() {
        try {
            if (environment.systemKey && environment.apiURL && !environment.genericKey) return;
            this.locations = await this.http
                .get('/assets/location/' + 'location.json')
                .toPromise();
        } catch (err) {
            this.router.navigate(['/not-found']);
        }
    }

    /** Calcula y guarda las coordenadas del dispositivo */
    async loadLocationDevice(timeout = true) {
        await this.loadLocations();
        try {
            if (!this.platform.is('cordova')) {
                await this.updateLocationDevice(timeout);
            } else {
                if (await this.diagnostic.isLocationEnabled()) {
                    await this.androidPermissions.requestPermission(
                        this.androidPermissions.PERMISSION
                            .ACCESS_COARSE_LOCATION
                    );
                    await this.updateLocationDevice(timeout);
                } else {
                    if(this.haveApiUrl()) return;
                    if (this.locations) {
                        this.settingsService.updateApiURL(this.locations[0].apiUrl);
                        this.currentApiUrl = this.locations[0].apiUrl;
                    }
                }
            }
        } catch (err) {
            if(this.haveApiUrl()) return;
            if (this.locations) {
                this.settingsService.updateApiURL(this.locations[0].apiUrl);
                this.currentApiUrl = this.locations[0].apiUrl;
            }
        }
    }

    /** TODO: Como hacer guardar un valor en akita y usarlo al instante ¿cual es la mejor forma? */
    async updateLocationDevice(timeout = true) {

        const optionsLocation = timeout ? { timeout: 3000 } : {};

        /** Intervalo fake porque a veces el getCurrentPosition no devuelve nada, ni entra en el catch del timeout */
        setTimeout(() => {
            if (!this.currentApiUrl) {
                this.settingsService.updateApiURL(this.locations[0].apiUrl);
                this.currentApiUrl = this.locations[0].apiUrl;
            }
        }, 4000);
        const resp = await Geolocation.getCurrentPosition(optionsLocation);
        clearInterval(this.intervalLocation);
        this.settingsService.updateLatitude(resp.coords.latitude);
        this.settingsService.updateLongitude(resp.coords.longitude);

        if(this.haveApiUrl()) return;
        
        this.loadUrlByPosition(resp.coords.latitude, resp.coords.longitude);
    }

    public loadUrlByPosition(latitude: number, longitude: number) {
        if (!latitude || !longitude) {
            return;
        }
        let minDif = 99999;
        let closest;
        this.locations.sort((a, b) => {
            const dif1 = this.PythagorasEquirectangular(
                latitude,
                longitude,
                a.latitude,
                a.logitude
            );

            const dif2 = this.PythagorasEquirectangular(
                latitude,
                longitude,
                b.latitude,
                b.logitude
            );
            
            return dif1 - dif2;
        });

        this.settingsService.updateApiURL(this.locations[0].apiUrl);
        this.currentApiUrl = this.locations[0].apiUrl;
    }

    private PythagorasEquirectangular(lat1, lon1, lat2, lon2) {
        lat1 = this.Deg2Rad(lat1);
        lat2 = this.Deg2Rad(lat2);
        lon1 = this.Deg2Rad(lon1);
        lon2 = this.Deg2Rad(lon2);
        const R = 6371; // km
        const x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2);
        const y = lat2 - lat1;
        const d = Math.sqrt(x * x + y * y) * R;
        return d;
    }

    private Deg2Rad(deg) {
        return (deg * Math.PI) / 180;
    }

    private haveApiUrl(): boolean {
        if (environment.systemKey && environment.apiURL && !environment.genericKey) {
            this.settingsService.updateApiURL(environment.apiURL);
            this.currentApiUrl = environment.apiURL;
            return true;
        };
        return false;
    }
}
