import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { IonList } from '@ionic/angular';

@Component({
    selector: 'app-units-list-popper',
    templateUrl: './units-list-popper.component.html',
    styleUrls: ['./units-list-popper.component.scss'],
})
export class UnitsListPopperComponent implements OnInit {
    @Input() maxUnits;
    @Input() minUnits;
    @Input() currentUnits;
    @Input() modeResponsive = false;

    @ViewChild('popperUnitList')
    popperUnitList: IonList;

    optionsUnits = [];

    @Output() selectUnitsEvent = new EventEmitter<any>();

    constructor() {}

    ngOnInit(): void {}

    onSelectUnits(units) {
        this.selectUnitsEvent.emit(units);
    }

    scrollToCurrentUnits() {
        // @ts-ignore
        this.popperUnitList.el.scrollTop = (this.currentUnits - 2) * 37;
    }

    initUnits() {
        if (!this.optionsUnits.length) {
            this.optionsUnits = [].constructor(+this.maxUnits + 1);
            this.optionsUnits.splice(0, +this.minUnits);
        }
    }
}
