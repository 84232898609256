import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface HeaderPictureState {
    home: string;
    homeCompany: string;
    company: string;
    account: string;
    appointment: string;
    treatment: string;
    messages: string;
}

export function createInitialState(): HeaderPictureState {
    return {
        home: '',
        homeCompany: '',
        company: '',
        account: '',
        appointment: '',
        treatment: '',
        messages: '',
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'header-picture' })
export class HeaderPictureStore extends Store<HeaderPictureState> {
    constructor() {
        super(createInitialState());
    }
}
