import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface HeaderBackgroundState {
    home: string;
    homeCompany: string;
    company: string;
    account: string;
    appointment: string;
    treatment: string;
    messages: string;
}

export function createInitialState(): HeaderBackgroundState {
    return {
        home: null,
        homeCompany: null,
        company: null,
        account: null,
        appointment: null,
        treatment: null,
        messages: null,
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'header-background' })
export class HeaderBackgroundStore extends Store<HeaderBackgroundState> {
    constructor() {
        super(createInitialState());
    }
}
