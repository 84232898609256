import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { LoadingStore, LoadingState } from './loading.store';

@Injectable({ providedIn: 'root' })
export class LoadingQuery extends Query<LoadingState> {
    constructor(protected store: LoadingStore) {
        super(store);
    }

    /*-----------*/
    /** GETTERS */
    /*----------*/

    get active(): boolean {
        return this.getValue()?.active;
    }
}
