import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-step-indicator',
    templateUrl: './step-indicator.component.html',
    styleUrls: ['./step-indicator.component.scss'],
})
export class StepIndicatorComponent implements OnInit {
    @Input() step: number;
    @Input() totalStep: number;

    constructor() {}

    ngOnInit(): void {}
}
