import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SettingsQuery} from '../../state/settings/settings.query';
import {NavController} from '@ionic/angular';
import {ClinicQuery} from '../../state/clinic/clinic.query';

@Component({
    selector: 'app-main-header',
    templateUrl: './main-header.component.html',
    styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit {
    @Input() navigationBar = false;
    @Input() titleBar = true;
    @Input() searchbar = false;
    @Output() search = new EventEmitter<string>();
    searching = false;

    constructor(
        private clinicQuery: ClinicQuery,
        private navCtrl: NavController,
        private settingsQuery: SettingsQuery,
    ) {
    }

    searchBarOnFocus(): void {
        this.searching = true;
    }

    searchBarOnBlur(): void {
        this.searching = false;
    }

    searchBarOnChange(ev: CustomEvent): void {
        this.search.emit(ev.detail.value);
    }

    goHome(event) {
        event.stopPropagation();
        /** Solo puedo volver atrás si no estoy en personalzas o si soy personalizada y tengo más de 1 centro */
        if (
            !this.settingsQuery.isPersonalized() ||
            this.clinicQuery.getValue().clinicsFound.length > 1
        ) {
            this.navCtrl.navigateBack(
                '/' +
                (this.settingsQuery.isPersonalized()
                    ? this.settingsQuery.currentSystemKey
                    : '')
            );
        }
    }

    ngOnInit(): void {
    }

}
