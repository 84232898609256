// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { GenericKeysModel } from '../app/shared/models';

export const environment = {
    production: true,
    systemKey: null,
    genericKey: null,
    browser: true,
    debug: false,
    primaryColorsList: [
        {
            key: GenericKeysModel.FLOWwwer,
            primary: '#6E5EE0', //azul (no se usa)
            secondary: '#3C1A75', //morado
            tertiary: '#00f088', //verde
            tertiaryContrast: '#3C1A75',
        },
        {
            key: GenericKeysModel.LPG,
            primary: '#231F20',
            secondary: '#555555',
            tertiary: '#004b93',
        },
        {
            key: GenericKeysModel.Voooy,
            primary: '#33DDAC',
            secondary: '#4096EC',
            tertiary: '#444444',
        },
    ],
    apiURL: null,
    googleMapsApiKey: 'AIzaSyDd6YEwUDDugFwDMYTQiV24ELxpZLHQl3k',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
