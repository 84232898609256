import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { PrimaryColorsModel } from '../../models/primary-colors.model';

export interface CompanyState {
    systemKey: string /** SystemKey de la company */;
    systemReseller: string /** Indica si el company es sistema distribuidor */;
    nameSplit: boolean /** Setting de la company que indica si el cliente tiene el nombre separado en nombre-apellido */;
    maxDate: string /** TODO: Preguntar que es y para qué se usa, Javi no lo usa nunca */;
    maxServices: string /** Indica el número máximo de servicios que se pueden pedir */;
    fwaAppDisableTemp: boolean /** Deshabilita el poder  agendar desde la App a clientes temporales, sin validar. */;
    fwaClientNewFields: string /** Campos del formulario de alta que tienen que aparecer */;
    fwaClientNewRequiredFields: string /** Campos del formulario de alta que tienen que ser obligatorios */;
    primaryColors: PrimaryColorsModel /** Configuración de colores primarios del company */;
    style: any /** Configuración de estilos primarios de lacompany */;
    labels: any /** Configuración de estilos primarios de la company */;
    clientAdvisor: boolean /** Está activado el programa de prescriptores */;
    clientAdvisorTabMode: boolean /** Está activado el programa amigo en modo Tab, por el contrario aparece en la ficha de cliente */;
    offlineCustomLibraryLoaded: boolean /** Indica si se ha cargado (o intentado) cargar la librería custom de la compnay */;
    gMapsApiKey: string /** Api key para cargar el mapa de la clínica */;
}

export function createInitialState(): CompanyState {
    return {
        systemKey: '',
        systemReseller: '',
        nameSplit: false,
        maxDate: '',
        maxServices: '',
        fwaAppDisableTemp: false,
        fwaClientNewFields: '',
        fwaClientNewRequiredFields: '',
        primaryColors: null,
        style: {},
        labels: {},
        clientAdvisor: false,
        clientAdvisorTabMode: true,
        offlineCustomLibraryLoaded: false,
        gMapsApiKey: ''
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'company', resettable: true })
export class CompanyStore extends Store<CompanyState> {
    constructor() {
        super(createInitialState());
    }
}
