import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface HeaderIconState {
    company: string;
    account: string;
    appointment: string;
    treatment: string;
    messages: string;
}

export function createInitialState(): HeaderIconState {
    return {
        company: '',
        account: '',
        appointment: '',
        treatment: '',
        messages: '',
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'header-icon' })
export class HeaderIconStore extends Store<HeaderIconState> {
    constructor() {
        super(createInitialState());
    }
}
