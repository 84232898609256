export interface ServiceWizard {
    id: string /** Id del servicio */;
    clinicId: string /** Id de la clínica del servicio */;
    clinicName: string /** Clínica del servicio */;
    familyId: string /** Id de la familia del servicio */;
    familySourceID: string /** SourceId de la familia del servicio */;
    description: string /** Nombre del servicio DEPRECATED, NO SE USA (SOLO RETROCOMPATIBILIDAD)*/;
    descriptionBasic: string /** Nombre del servicio (solamente la descripción, no se concatena nada desde API */;
    price: string /** Precio del servicio sin descuento */;
    promotedPrice: string /** Precio del servicio con descuento */;
    cabinets: string[] /** Cabinas donde se puede hacer este servicio */;
    profesionals: string[] /** Profesionales que pueden hacer este servicio. Si está deshabilitado el control de habilidades profesionales o no hay ningun profesional que pueda hacer ese servicio, llegará vacío. ¿OBSOLETO, no se usa en cita compuesta?*/;
    equipmentId: string /** TODO: ID del equipamiento requerido, será 0 si no se requiere de ninguno (lógica cita compuesta) ¿Para que se usa en cita compuesta ? */;
    currencyFormat: string /** Información de formato de la moneda del producto */;
    currencySymbol: string /** Información del simbolo de la moneda del producto */;
    currencySymbolPosition: string /** Información de la posición de la moneda del producto sobre el precio */;
    checked: boolean /** Indica si el usuario a seleccionado este servicio (lógica interna del asistente) */;
    order: number /** Indica el orden en el que se han marcado los servicios */;
    hidde: boolean
}

export interface ServiceWizardCheckedEvent {
    service: ServiceWizard;
    checkedEvent: boolean;
}

/**
 * A factory function that creates ServiceWizard
 */
export function createServiceWizard(params: Partial<ServiceWizard>) {
    return {} as ServiceWizard;
}

/**
 * Factoría que crea un Servicio para el asistente de pedir cita con los datos recogidos por la API
 */
export function serviceWizardFactory(params: any) {
    return {
        id: params.ServiceId,
        clinicId: params.ServiceClinicID,
        clinicName: params.ServiceClinicName,
        familyId: params.ServiceFamilyId,
        familySourceID: params.ServiceFamilySourceID,
        description: params.ServiceDescription,
        descriptionBasic: params.ServiceDescriptionBasic,
        price: params.ServicePrice,
        promotedPrice:
            params.ServicePromotedPrice === '0'
                ? ''
                : params.ServicePromotedPrice === '-1'
                ? '0'
                : params.ServicePromotedPrice,
        cabinets: params.ServiceCabinets,
        profesionals: params.ServiceProfessionals,
        equipmentId: params.ServiceEquipmentID,
        currencyFormat: params.CurrencyFormat,
        currencySymbol: params.CurrencySymbol,
        currencySymbolPosition: params.CurrencySymbolPosition,
        checked: false,
        order: 0,
        hidde: false,
    } as ServiceWizard;
}
