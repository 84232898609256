import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoadingStore } from './loading.store';

@Injectable({ providedIn: 'root' })
export class LoadingService {
    constructor(private loadingStore: LoadingStore, private http: HttpClient) {}

    showLoading() {
        this.loadingStore.update({ active: true });
    }

    hideLoading() {
        this.loadingStore.update({ active: false });
    }

    updateShowSpinner(newShowSpinner: boolean) {
        this.loadingStore.update({ showSpinner: newShowSpinner });
    }
}
