import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ProductWizardStore, ProductWizardState } from './product-wizard.store';
import { ProductWizard } from './product-wizard.model';

@Injectable({
    providedIn: 'root',
})
export class ProductWizardQuery extends QueryEntity<
    ProductWizardState,
    ProductWizard
> {
    constructor(protected store: ProductWizardStore) {
        super(store);
    }

    getUnitsByProductId(productWizardId) {
        return this.getEntity(productWizardId).unitsCart;
    }

    selectUnitsProductId(productWizardId) {
        return this.selectEntity(productWizardId, 'unitsCart');
    }
}
