import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    ProductWizard,
    ProductWizardCheckedEvent,
} from '../../../state/product-wizard/product-wizard.model';

@Component({
    selector: 'app-product-list',
    templateUrl: './product-list.component.html',
    styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent implements OnInit {
    @Input() productsWizard: ProductWizard[];
    @Input() appDelegate: boolean;
    @Output() productToggleEvent = new EventEmitter<any>();

    constructor() {}

    ngOnInit(): void {}

    onProductToggle(productWizard) {
        this.productToggleEvent.emit(productWizard);
    }
}
