import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-title',
    templateUrl: './title.component.html',
    styleUrls: ['./title.component.scss'],
})
export class TitleComponent implements OnInit {
    @Input() size: 'small' | 'default' | 'large'; 
    @Input() color: 'primary' | 'secondary' | 'tertiary'
    
    constructor() {}

    ngOnInit(): void {}
}
