import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-payment-wizard',
    templateUrl: './payment-wizard.component.html',
    styleUrls: ['./payment-wizard.component.scss'],
})
export class PaymentWizardComponent implements OnInit {
    @Input() url;

    constructor() {}

    ngOnInit(): void {}
}
