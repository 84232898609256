import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { SettingsQuery } from '../state/settings/settings.query';
import { UserService } from '../state/user/user.service';
import { VersionService } from '../../core/services/version/version.service';
import { NotFoundError } from '../errors/not-found.error';
import { StorageService } from 'src/app/core/services/storage/storage.service';

@Injectable({
    providedIn: 'root',
})
export class SystemkeyParamGuard implements CanActivate {
    constructor(
        private settingsQuery: SettingsQuery,
        private router: Router,
        private userService: UserService,
        private versionService: VersionService,
        private storage: StorageService
    ) {}

    /**
     * Comprobación del estado de la versión de la APP
     */
    checkAppVersionIsObsolete() {
        return this.settingsQuery.status === 'OBSOLETE';
    }

    /**
     * Comprobamos que si es una app personalizada no se le está pasando un SK distinto que  el que tiene configurado
     * en environment
     *
     * TODO: Por qué se comprueba la longitud del número de parámetros de la url?? y por qué 3?
     * TODO: Creo que es porque si que tenemos que permitir distintos SK para clínicas importadas
     * TODO: Para hacerlo más seguro, ya que ahora mismo podemos poner cualquier SK diferente y una clínica importada (mirar si esto no se comprueba en otro sitio)
     * Tenmos que hacer que si se pone otro SK disinto, que venga un query params con un parent SK que sea el de environment
     * TODO: Solo para personalizadas browser (aun no tenemos ninguna)
     */
    checkDiferentSystemKeyPersonalized(next, state) {
        return (
            this.settingsQuery.isPersonalized() &&
            next.params.systemKey &&
            this.settingsQuery.currentSystemKey !== '' &&
            next.params.systemKey !== '' &&
            this.settingsQuery.currentSystemKey !== next.params.systemKey &&
            state.url.split('/').length <= 3
        );
    }

    /**
     * Comprobamos que si es genérica, no se le pase ningún SK como un único parámetro porque esta página no existe
     * (si existe en personalizadas)
     */
    checkSystemKeyParamInGeneric(next, state) {
        return (
            this.settingsQuery.isGeneric() &&
            next.params.systemKey &&
            state.url.split('/').length === 2
        );
    }

    /**
     * Comprobamos que si no es ni personalizada ni genérica, que se le haya pasado un SK por parametros
     */
    checkAnySystemKeyInAreaDeClientes(next) {
        return this.settingsQuery.appRole === null && !next.params?.systemKey;
    }

    /**
     * Comprobamos que si si es personalizada, y no se le ha pasado el parámetro de SK por URL, redirigir
     */
    checkNoSystemKeyInPersonalized(next) {
        return this.settingsQuery.isPersonalized() && !next.params?.systemKey;
    }

    /**
     * Comprobamos que tenemos una clínica activa y que se cumplen los requisitos para redireccionar a ella
     */
    checkClinicActive(companyActive, next, state) {
        return (
            companyActive &&
            companyActive.SystemKey &&
            companyActive.ClinicID /** Siemrpe que entremos a la main page de una personalizada **/ &&
            ((this.settingsQuery.systemKey &&
                this.settingsQuery.systemKey !== '' &&
                state.url.split('/').length <= 2) ||
                /** Siempre que entramos a la main page de una Generic **/
                ((!this.settingsQuery.systemKey ||
                    this.settingsQuery.systemKey === '') &&
                    this.settingsQuery.genericKey &&
                    state.url.split('/').length <= 2) ||
                /** Siempre que sea browser personalizada y todo a null (personalizada generica)
             y coincidan la clínica a la que vamos a  entrar con el SK que hemos puesto **/
                (state.url.split('/').length <= 2 &&
                    next.params?.systemKey &&
                    next.params?.systemKey !== '' &&
                    (next.params?.systemKey === companyActive.SystemKey ||
                        (companyActive.ParentSystemKey &&
                            companyActive.ParentSystemKey !== '' &&
                            next.params?.systemKey ===
                                companyActive.ParentSystemKey))))
        );
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return new Promise((resolve) => {
            this.versionService
                .checkUpdateApp()
                .then(() => {
                    if (this.checkAppVersionIsObsolete()) {
                        resolve(this.router.parseUrl('/app-disabled'));
                        return;
                    }

                    if (this.checkDiferentSystemKeyPersonalized(next, state)) {
                        resolve(
                            this.router.parseUrl(
                                '/' + this.settingsQuery.currentSystemKey
                            )
                        );
                        return;
                    }

                    if (this.checkSystemKeyParamInGeneric(next, state)) {
                        this.storage
                            .remove('companyActive')
                            .then(() => {
                                resolve(this.router.parseUrl('/'));
                            })
                            .catch((error) => {
                                console.log(error);
                                resolve(this.router.parseUrl('/'));
                            });
                        return;
                    }

                    if (this.checkAnySystemKeyInAreaDeClientes(next)) {
                        resolve(this.router.parseUrl('/not-found'));
                        return;
                    }

                    if (this.checkNoSystemKeyInPersonalized(next)) {
                        resolve(
                            this.router.parseUrl(
                                '/' + this.settingsQuery.currentSystemKey
                            )
                        );
                        return;
                    }

                    /** Si no hay ningún error anterior, comprobamos redirecciones */

                    /** Antes de nada, comprobamos si tenemos un tokenID por URL para guardarlo en Akita para usarlo de login
                     * Después redirigimos para quitar el token de la URL del navegador */
                    if (next.queryParams?.tokenId) {
                        this.userService.updateTokenIdAux(
                            next.queryParams.tokenId
                        );

                        resolve(this.router.parseUrl(state.url.split('?')[0]));
                        return;
                    }

                    /** Si no hay ningún error anterior, comprobamos si tenemos alguna clínica guardada */
                    this.storage
                        .get('companyActive')
                        .then((companyActive) => {
                            if (
                                this.checkClinicActive(
                                    companyActive,
                                    next,
                                    state
                                )
                            ) {
                                let parentSystemKey = '';
                                if (
                                    companyActive.ParentSystemKey &&
                                    companyActive.ParentSystemKey !== ''
                                ) {
                                    parentSystemKey =
                                        '?parentSystemKey=' +
                                        companyActive.ParentSystemKey;
                                }
                                resolve(
                                    this.router.parseUrl(
                                        companyActive.SystemKey +
                                            '/' +
                                            companyActive.ClinicID +
                                            '/details' +
                                            parentSystemKey
                                    )
                                );
                                return;
                            }
                            /** Resolve por defecto */
                            resolve(true);
                        })
                        .catch((error) => {
                            console.log(error);
                            resolve(this.router.parseUrl('/'));
                            return;
                        });
                    return;
                })
                .catch((error) => {
                    console.log(error);
                    if (error instanceof NotFoundError) {
                        resolve(this.router.parseUrl('/not-found'));
                    }else{
                        resolve(this.router.parseUrl('/'));
                    }
                    
                    return;
                });
        });
    }
}
