import { Query } from '@datorama/akita';
import { SettingsState, SettingsStore } from './settings.store';
import { Injectable } from '@angular/core';
import { AppRolesModel } from '../../models/app-roles.model';
import { PrimaryColorsModel } from '../../models/primary-colors.model';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanyQuery } from '../company/company.query';
import { UserQuery } from '../user/user.query';
import { ClinicQuery } from '../clinic/clinic.query';

@Injectable({ providedIn: 'root' })
export class SettingsQuery extends Query<SettingsState> {
    constructor(
        protected store: SettingsStore,
        private companyQuery: CompanyQuery,
        private userQuery: UserQuery,
        private clinicQuery: ClinicQuery
    ) {
        super(store);
    }

    /*-----------*/
    /** GETTERS */

    /*----------*/

    get genericKey() {
        return this.getValue()?.genericKey;
    }

    get systemKey() {
        return this.getValue()?.systemKey;
    }

    get systemKeyURL() {
        return this.getValue()?.systemKeyURL;
    }

    get parentSystemKey() {
        return this.getValue()?.parentSystemKey;
    }

    get currentSystemKey() {
        return this.getValue()?.systemKey && this.getValue()?.systemKey !== ''
            ? this.getValue()?.systemKey
            : this.getValue()?.parentSystemKey &&
              this.getValue()?.parentSystemKey !== ''
            ? this.getValue()?.parentSystemKey
            : this.getValue()?.systemKeyURL;
    }

    get browser() {
        return this.getValue()?.browser;
    }

    get showClinicDetails() {
        return this.getValue()?.showClinicDetails;
    }

    get showHeader() {
        return this.getValue()?.showHeader;
    }

    get loginWithURL() {
        return this.getValue()?.loginWithURL;
    }

    get apiURL() {
        return this.getValue()?.apiURL;
    }

    get latitude() {
        return this.getValue()?.latitude;
    }

    get longitude() {
        return this.getValue()?.longitude;
    }

    get status() {
        return this.getValue()?.status;
    }

    get debug() {
        return this.getValue()?.debug;
    }

    get flowwwServicesHost() {
        return this.getValue()?.flowwwServicesHost;
    }

    /*-----------------*/
    /** GETTERS CUSTOM */

    /*-----------------*/

    /**
     * Devolvemos el rol de la aplicación dependiendo de la configuración de los settings iniciales
     * Esto lo vamos a usar para establecer las diferencias entre un tipo de aplicación y otra
     * Generic: La versión Generic va a ser igual en apps y browser independientemente de todos los settings
     * Peronsalized: La versión Personalized puede ser:
     * 1) Normal para las apps
     * 2) Personalizada para browser (saldrá un botón del logout por ejemplo)
     * 3) Personalizada para browser habiendose logeado por url ( no saldrá el botón de logout por ejemplo)
     */
    get appRole(): number {
        let customAppRole: number = null;
        if (this.genericKey !== null) {
            customAppRole = AppRolesModel.Generic;
            if (this.browser) {
                customAppRole = AppRolesModel.GenericBrowser;
            }
        } else {
            if (
                this.currentSystemKey !== null &&
                this.currentSystemKey !== ''
            ) {
                customAppRole = AppRolesModel.Personalized;
                if (this.browser) {
                    customAppRole = AppRolesModel.PersonalizedBrowser;
                }
            }
        }
        return customAppRole;
    }

    get primaryColors(): PrimaryColorsModel {
        return this.getValue()?.primaryColors;
    }

    /** Con esta función comprobamos si necesitamos cambiar los colores al inicializar una clínica */
    isNeedChangeLabelsColorsClinic() {
        return (
            this.appRole === AppRolesModel.Generic ||
            this.appRole === AppRolesModel.GenericBrowser
        );
    }

    isPersonalized() {
        return (
            this.appRole === AppRolesModel.Personalized ||
            this.appRole === AppRolesModel.PersonalizedBrowser
        );
    }

    isGeneric() {
        return (
            this.appRole === AppRolesModel.Generic ||
            this.appRole === AppRolesModel.GenericBrowser
        );
    }

    selectIsGeneric(): Observable<boolean> {
        return this.select(['systemKeyURL', 'browser', 'genericKey']).pipe(
            map(() => {
                return this.isGeneric();
            })
        );
    }

    selectIsPersonalized(): Observable<boolean> {
        return this.select(['systemKeyURL', 'browser', 'genericKey']).pipe(
            map(() => {
                return this.isPersonalized();
            })
        );
    }
    selectCurrentSystemKey(): Observable<string> {
        return this.select([
            'systemKeyURL',
            'systemKey',
            'parentSystemKey',
        ]).pipe(
            map(() => {
                return this.currentSystemKey;
            })
        );
    }

    selectIsUserAccountATab(): Observable<boolean> {
        const showClinicDetails$ = this.select('showClinicDetails');
        const clientAdvisor$ = this.companyQuery.select('clientAdvisor');
        const clientAdvisorTabMode$ = this.companyQuery.select(
            'clientAdvisorTabMode'
        );

        return combineLatest([
            showClinicDetails$,
            clientAdvisor$,
            clientAdvisorTabMode$,
        ]).pipe(
            map(([showClinicDetails, clientAdvisor, clientAdvisorTabMode]) => {
                return !(
                    showClinicDetails &&
                    clientAdvisor &&
                    clientAdvisorTabMode
                );
            })
        );
    }

    /**
     * Casos en los que la sección de user account está deshabilitada:
     * 1) Cuando es browser y se ha logeqado por URL
     * Ojo! Hay una configuración en la template en la que se puede poner
     * los datos del programa amigo en el tab de perfil. Si en ese escenario
     * se entra por URL no se podrá acceder a esa pestaña.
     * Aunque tiene fácil arreglo, no se ha desarrollado nada al respecto
     */
    selectIsEnabledUserAccount(): Observable<boolean> {
        const browser$ = this.select('browser');
        const user$ = this.userQuery.select();

        return combineLatest([browser$, user$]).pipe(
            map(([browser, user]) => {
                return !(browser && user.lastLoginByUrl);
            })
        );
    }

    selectIsFriendProgramATab(): Observable<boolean> {
        const clientAdvisor$ = this.companyQuery.select('clientAdvisor');
        const clientAdvisorTabMode$ = this.companyQuery.select(
            'clientAdvisorTabMode'
        );

        return combineLatest([clientAdvisor$, clientAdvisorTabMode$]).pipe(
            map(([clientAdvisor, clientAdvisorTabMode]) => {
                return clientAdvisor && clientAdvisorTabMode;
            })
        );
    }

    selectIsFriendProgramInUserAccount(): Observable<boolean> {
        const clientAdvisor$ = this.companyQuery.select('clientAdvisor');
        const clientAdvisorTabMode$ = this.companyQuery.select(
            'clientAdvisorTabMode'
        );

        return combineLatest([clientAdvisor$, clientAdvisorTabMode$]).pipe(
            map(([clientAdvisor, clientAdvisorTabMode]) => {
                return clientAdvisor && !clientAdvisorTabMode;
            })
        );
    }

    selectShowBackButtonHeader(): Observable<boolean> {
        return combineLatest([
            this.clinicQuery.select('clinicsFound'),
            this.selectIsGeneric(),
        ]).pipe(
            map(([clinics, isGeneric]) => {
                return clinics.length > 1 || isGeneric;
            })
        );
    }
}
