import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class ClientValidators {
    static validDash(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value && (control.value?.match(/-\s/) !== null || control.value?.match(/\s-/) !== null)){
                return { validDash: true };
            }
            return null;
        };
    }
}
