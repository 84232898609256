import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { FLOW_DATE_FULL_FORMAT } from 'src/app/core/constants';
import { ServicesGroup } from '../../state/services-group/services-group.model';
import { ServiceWizard } from '../../state/service-wizard/service-wizard.model';

@Component({
    selector: 'app-generic-appointment',
    templateUrl: './generic-appointment.component.html',
    styleUrls: ['./generic-appointment.component.scss'],
})
export class GenericAppointmentComponent implements OnInit {
    @Input() showDeleteButton = false;
    @Input() date: string;
    @Input() time: string;
    @Input() serviceList: string[];
    /** Flowwwer template */
    @Input() servicesGroups: ServicesGroup[];
    @Input() servicesChecked: ServiceWizard[];
    @Input() mini = false;
    @Input() dateFormatOriginData = 'YYYY-MM-DD';
    @Input() appDelegate = false;
    @Input() clinicName = '';
    @Input() showElipsis = true; 

    @Output() trashClicked = new EventEmitter();
    @Output() appointmentClicked = new EventEmitter<any>();

    /** Flowwwer template */
    FLOW_DATE_FULL_FORMAT = FLOW_DATE_FULL_FORMAT;

    constructor() {}

    ngOnInit(): void {}

    onTrashClicked() {
        this.trashClicked.emit();
    }

    onAppointmentClicked(responsiveHandler) {
        if (window.innerWidth > 768 && responsiveHandler) {
            return;
        }
        this.appointmentClicked.emit();
    }

    /** Flowwwer template */
    //TODO, SACAR ESTO A UNA FUNCION GLOBAL
    getDateMoment() {
        return moment(this.date, this.dateFormatOriginData);
    }

    getServiceDescriptionFromId(id: string): string {
        const service = this.servicesChecked.find((service: ServiceWizard) => {
            return service.id === id;
        });

        return service?.description;
    }

    getProfessionalImageFromDesc(desc: string): string {
        return `https://eu.ui-avatars.com/api/?name=${encodeURIComponent(
            desc
        )}&background=F2EAEA&color=D2CACA&rounded=true&bold=true`;
    }
}
