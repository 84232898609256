import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface ClientDataSocialLogin {
    name: string;
    surname: string;
    email: string;
    phone: string;
}
export interface SocialLoginState {
    socialLoginSuccess: boolean;
    socialLoginFail: boolean;
    clientData: ClientDataSocialLogin;
}

export function createInitialState(): SocialLoginState {
    return {
        socialLoginSuccess: false,
        socialLoginFail: false,
        clientData: null,
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'social-login', resettable: true })
export class SocialLoginStore extends Store<SocialLoginState> {
    constructor() {
        super(createInitialState());
    }
}
