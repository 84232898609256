import { Component, Input, OnInit } from '@angular/core';
import { UserRegisterModel } from '../../models/userRegister.model';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { KeyboardQuery } from '../../state/keyboard/keyboard.query';
import { LoadingService } from '../../state/loading/loading.service';
import { UserService } from '../../state/user/user.service';
import { ClinicDetailQuery } from '../../state/clinic-detail/clinic-detail.query';
import { LibraryService } from 'src/app/core/services/library/library.service';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-otp-modal',
    templateUrl: './otp-modal.component.html',
    styleUrls: ['./otp-modal.component.scss'],
})
export class OtpModalComponent implements OnInit {
    @Input() hashCode: string;
    /** Flowwwer template */
    @Input() email: string;

    keyboardIsShowing: Observable<boolean>;
    otpForm: FormGroup;

    /** Flowwwer template */
    clinicName$: Observable<string>;
    labelOtpInfo = '';

    constructor(
        private modalController: ModalController,
        private formBuilder: FormBuilder,
        private keyboardQuery: KeyboardQuery,
        private loadingService: LoadingService,
        private userService: UserService,
        private clinicDetailQuery: ClinicDetailQuery,
        private libraryService: LibraryService
    ) {}

    ngOnInit(): void {
        this.keyboardIsShowing = this.keyboardQuery.select('isShowing');
        this.initForm();

        /** Flowwwer template */
        this.clinicName$ = this.clinicDetailQuery.select('commercialName');

        const labelOppInfo = this.libraryService
            .getLabelAsync('lbl_mail_otp_info')
            .pipe(
                map((label) => {
                    return label.replace(
                        '###EMAIL###',
                        `<span class="email">${this.email}</span>`
                    );
                })
            )
            .subscribe((label) => {
                this.labelOtpInfo = label;
            });
    }

    private initForm() {
        this.otpForm = this.formBuilder.group({
            otp: [
                '',
                [
                    Validators.maxLength(6),
                    Validators.minLength(6),
                    Validators.required,
                    Validators.pattern(/^([0-9]\d*)?$/),
                ],
            ],
        });
    }

    get controls() {
        return this.otpForm.controls;
    }

    async submit() {
        if (this.otpForm.invalid) {
            Object.values(this.otpForm.controls).forEach((control) => {
                control.markAsTouched();
            });
            return;
        }
        this.loadingService.updateShowSpinner(true);
        await this.userService.loginWithOtp(
            this.otpForm.value.otp,
            this.hashCode
        );
        this.loadingService.updateShowSpinner(false);
        this.closeModal(OTPModalDismissActions.REGISTER);
    }

    closeModal(
        action: OTPModalDismissActions = OTPModalDismissActions.NONE
    ): void {
        this.modalController.dismiss({ action });
    }
}

export enum OTPModalDismissActions {
    NONE,
    REGISTER,
}
