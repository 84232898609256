import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-skeleton-social-login',
    templateUrl: './skeleton-social-login.component.html',
    styleUrls: ['./skeleton-social-login.component.scss'],
})
export class SkeletonSocialLoginComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
