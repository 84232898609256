import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { ClinicDetailState } from '../../state/clinic-detail/clinic-detail.store';
import { ClinicDetailQuery } from '../../state/clinic-detail/clinic-detail.query';

@Component({
    selector: 'app-privacity-modal',
    templateUrl: './privacity-modal.component.html',
    styleUrls: ['./privacity-modal.component.scss'],
})
export class PrivacityModalComponent implements OnInit {
    constructor(
        private modalController: ModalController,
        private clinicDetailQuery: ClinicDetailQuery
    ) {}
    clinic$: Observable<ClinicDetailState>;

    ngOnInit(): void {
        this.clinic$ = this.clinicDetailQuery.select();
    }

    dismissModal() {
        this.modalController.dismiss({
            dismissed: true,
        });
    }
}
