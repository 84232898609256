import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CompanyStore } from './company.store';
import { PrimaryColorsModel } from '../../models';
import { Observable } from 'rxjs';
import { ColorService } from '../../../core/services/color/color.service';
import { CompanyQuery } from './company.query';
import { SettingsQuery } from '../settings/settings.query';
import { LanguageService } from '../../../core/services/language/language.service';
import { LibraryService } from '../../../core/services/library/library.service';
import { ClinicService } from '../clinic/clinic.service';
import { ApiService } from 'src/app/core/services/api/api.service';
import { SettingsService } from '../settings/settings.service';
import { Capacitor } from '@capacitor/core';
import {
    StatusBar,
    BackgroundColorOptions,
    Style,
} from '@capacitor/status-bar';
import { ClinicDetailService } from '../clinic-detail/clinic-detail.service';
import { HeaderPictureService } from '../header-picture/header-picture.service';
import { HeaderBackgroundService } from '../header-background/header-background.service';
import { ValidatorFn, Validators } from '@angular/forms';
import { LocationService } from '../../../core/services/location/location.service';
import { filter, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CompanyService {
    constructor(
        private companyStore: CompanyStore,
        private colorService: ColorService,
        private companyQuery: CompanyQuery,
        private settingsQuery: SettingsQuery,
        private languageService: LanguageService,
        private libraryService: LibraryService,
        private clinicService: ClinicService,
        private apiService: ApiService,
        private settingsService: SettingsService,
        private clinicDetailService: ClinicDetailService,
        private headerPictureService: HeaderPictureService,
        private headerBacgroundService: HeaderBackgroundService,
        private locationService: LocationService,
        private http: HttpClient
    ) {}

    /*-----------*/
    /** UPDATES */
    /*----------*/

    setPrimaryColors(primaryColors: PrimaryColorsModel) {
        this.companyStore.update({ primaryColors });
    }

    updateOfflineCustomLibraryLoaded(newOfflineCustomLibraryLoaded: boolean) {
        this.companyStore.update({
            offlineCustomLibraryLoaded: newOfflineCustomLibraryLoaded,
        });
    }

    updateClientAdvisorTabMode(clientAdvisorTabMode: boolean) {
        this.companyStore.update({ clientAdvisorTabMode });
    }

    updateGMapsClinicDetails(gMapsApiKey: string) {
        this.companyStore.update({ gMapsApiKey });
    }

    /*---------------*/
    /** LLAMADAS API */
    /*---------------*/

    /**
     * Llamada api para obtener los datos de un Company  DESDE PERSONALIADA
     */
    private getCompanyApi(
        systemKey: string,
        latitude: number,
        longitude: number
    ): Observable<any> {
        let params = new HttpParams();
        params = params.append('SystemKey', systemKey);
        params = params.append('latitude', latitude ? latitude.toString() : '');
        params = params.append(
            'longitude',
            longitude ? longitude.toString() : ''
        );
        params = params.append('Cmd', 'c1011');
        params = params.append('Locale', this.languageService.getLocale());

        return this.apiService.callApi(params);
    }

    /**
     * Llamada api para obtener los datos de un Company DESDE GENERIC
     */
    private getCompanyFromGenericApi(systemKey: string): Observable<any> {
        let params = new HttpParams();
        params = params.append('SystemKey', systemKey);
        params = params.append('Cmd', 'c1013');
        params = params.append('Locale', this.languageService.getLocale());

        return this.apiService.callApi(params);
    }

    /**
     * Llamada api para obtener los labels de un Company
     */
    private getCompanyLabelsApi(systemKey: string): Observable<any> {
        let params = new HttpParams();
        params = params.append('SystemKey', systemKey);
        params = params.append('Cmd', 'c1012');
        params = params.append('Locale', this.languageService.getLocale());

        return this.apiService.callApi(params);
    }

    /*------------*/
    /** SERVICIOS */
    /*------------*/

    /**
     * Cargar una company de la API e inicializarla en la APP y en el Store. Cargar desde personalizada
     */
    async initCompany(systemKey: string) {
        /** Llamamos a la API para obtener los datos de la company */

        /** TODO: CHAPUZA. Esto lo hago para asegurarme que por muy rápido que se le de a buscar,
         * siempre me asegure que ya se han cargado la latitud y longitud
         * (fallaba si buscabas rápido la primera vez). Recordemos que lo que espera
         * a tener la apiURL es la llamada http
         * Es la única llamada que tiene la espera en su propio servicio en vez de en el servicio api.service
         * ya que algunos de los datos que necesitamos, los carga la misma llamada de cargar la apiURL
         * Unico a resolver: si se pierde el queryparams y le das a f5 pierdes el flujo y se rompera y cargara la SK original del centro
         */
        await this.settingsQuery
            .select('apiURL')
            .pipe(
                filter((apiURL) => apiURL !== ''),
                take(1)
            )
            .toPromise();

        this.locationService.loadLocationDevice(false);
        const latitude = this.settingsQuery.latitude;
        const longitude = this.settingsQuery.longitude;
        const companyData = await this.getCompanyApi(
            systemKey,
            latitude,
            longitude
        ).toPromise();

        /** Actuaizamos Store añadiendo los colores primarios */
        const primaryColors: PrimaryColorsModel = {
            primary: companyData.Style.app_primary_color,
            secondary: companyData.Style.app_secondary_color,
            tertiary: companyData.Style.app_tertiary_color,
            tertiaryContrast: companyData.Style.app_tertiary_color_contrast
        };

        /** Seteamos las clínicas en la Store */
        this.clinicService.setClinicsByApi(companyData.Clinics);

        /** Llamamos a la API para obtener los labels de la company */
        const companyLabelsData = await this.getCompanyLabelsApi(
            systemKey
        ).toPromise();

        this.companyStore.update({
            systemKey,
            systemReseller: companyData.SystemReseller,
            /** NameSplit_Ok es el nuevo campo de namesplit para no romper las apps antiguas */
            nameSplit: companyData.NameSplit_Ok === '-1',
            maxDate: companyData.MaxDate,
            maxServices: companyData.MaxServices,
            fwaAppDisableTemp: companyData.FWAAppDisableTemp === '-1',
            fwaClientNewFields: companyData.FWAClientNewFields,
            fwaClientNewRequiredFields: companyData.FWAClientNewRequiredFields,
            primaryColors,
            style: companyData.Style,
            labels: companyLabelsData.Labels,
            clientAdvisor: companyData.ClientAdvisor === '-1',
        });

        this.configColorsLabelsCompanyFromState();
        this.headerPictureService.updatePictures(companyData?.SystemPictures);
        this.headerBacgroundService.updateBackgroundsPersonalized(
            companyData?.HeaderPictures
        );
        this.clinicService.loadClinicsFavFromLocalStorage();
    }

    /**
     * Cargar una company de la API e inicializarla en la APP y en el Store DESDE GENERIC Seleccionando del buscador
     */
    async initCompanyFromGeneric(systemKey: string) {
        /** Llamamos a la API para obtener los datos de la company */
        const companyData = await this.getCompanyFromGenericApi(
            systemKey
        ).toPromise();

        /** Actuaizamos Store */
        const primaryColors: PrimaryColorsModel = {
            primary: companyData.Style.app_primary_color,
            secondary: companyData.Style.app_secondary_color,
            tertiary: companyData.Style.app_tertiary_color,
            tertiaryContrast: companyData.Style.app_tertiary_color_contrast
        };

        /** Llamamos a la API para obtener los labels de la company */
        const companyLabelsData = await this.getCompanyLabelsApi(
            systemKey
        ).toPromise();

        this.companyStore.update({
            systemKey,
            systemReseller: companyData.SystemReseller,
            /** NameSplit_Ok es el nuevo campo de namesplit para no romper las apps antiguas */
            nameSplit: companyData.NameSplit_Ok === '-1',
            maxDate: companyData.MaxDate,
            maxServices: companyData.MaxServices,
            fwaClientNewFields: companyData.FWAClientNewFields,
            fwaClientNewRequiredFields: companyData.FWAClientNewRequiredFields,
            primaryColors,
            style: companyData.Style,
            labels: companyLabelsData.Labels,
        });

        this.headerPictureService.updatePicturesFromGeneric(
            companyData?.SystemPictures
        );

        this.settingsService.initGenericApp();
        this.libraryService.loadCompanyLibrary(this.companyQuery.labels);
        this.configColorsLabelsCompanyFromState();
    }

    /** Configuramos los colores y las labels de la aplicación a partir de los datos de la Store */
    configColorsLabelsCompanyFromState() {
        /** Inicializamos colores de la company */
        this.configCompanyColors();

        /** Moficiamos el color del status bar  */
        this.updateStatusBarStyle();
        /** Inicializamos los labels de la company */
        this.libraryService.loadCompanyLibrary(this.companyQuery.labels);

        // Inicializamos los valores del header
        this.settingsService.setHeightHeader();
    }

    /**
     * Configuramos con ColorService los colores custom de la company que devuelve la API que tenemos almacenados en la STORE
     */
    configCompanyColors() {
        this.colorService.updatePrimaryColorsPalettes(
            this.companyQuery.primaryColors.primary,
            this.companyQuery.primaryColors.secondary,
            this.companyQuery.primaryColors.tertiary,
            this.companyQuery.primaryColors.tertiaryContrast
        );
    }

    async updateStatusBarStyle() {
        if (Capacitor.isNativePlatform()) {
            let hexPrimary = this.companyQuery.primaryColors.primary;
            if (hexPrimary.charAt(0) !== '#') {
                hexPrimary = `#${hexPrimary}`;
            }

            let templateConfig: any = await this.http
                .get('/assets/template/' + 'config.json')
                .toPromise();

            const options: BackgroundColorOptions = {
                color: templateConfig.statusBarBackgroundColor ?? hexPrimary,
            };
            StatusBar.setBackgroundColor(options);

            StatusBar.setStyle({
                style:
                    templateConfig.statusBarStyle ??
                    (this.colorService.getContrastColor(hexPrimary) ===
                    '#FFFFFF'
                        ? Style.Dark
                        : Style.Light),
            });
        }
    }

    /** Desarrollo para la versión Browser para poder cargar clínicas dentro de un SK distinto
     * al que pertenecen:
     * En Apps compiladas (con SK en environment relleno), no tenemos problema en cargar centros
     * de distintos SK, ya que tenemos el SK "padre" (en environment) y el SK de la clínica (en la ruta).
     * Pero en versión Brower compilación genérica (personlizada para cualquier SK) no tenemos el SK padre
     * en el que está incluida la clínica (APP), solo tenemos su SK de clínica.
     * Para solucionar esto, y solamente para versiones browser, con SK a null
     * Vamos a tener en cuenta un query param en el que se establezca el SK padre de la clínica a cargar.
     * Esto unido a la seguidad del sistema que solo permite cargar clínicas si pertenecen al SK configurado
     * mantiene al sistema robusto
     */
    async checkCompanyAndInit(parentSystemKey = null) {
        /** Significa que no tenemos cargada la company, colores, labels etc que nos solicitan */
        /** TODO: Mejorar para que aunque tenga un sk en generic no pete y no busque ESE sk en vez del seleccionado */
        let systemKey = this.settingsQuery.systemKey;

        if (!systemKey) {
            if (parentSystemKey) {
                systemKey = parentSystemKey;
            } else {
                systemKey = this.settingsQuery.systemKeyURL;
            }
        }
        if (this.companyQuery.systemKey === '') {
            if (this.settingsQuery.isGeneric()) {
                await this.initCompanyFromGeneric(systemKey);
            } else {
                await this.initCompany(systemKey);
            }
        } else {
            /**  Caso de recibir una notificación push, nos cambiamos de clínica pudiendo estar en otra
             *  VER SI CONFLICTA CON EL CASO DE DIVEA APP (QUÉ SK LLEGA EN SUS MENSAJES???)
             */

            if (this.companyQuery.systemKey !== systemKey) {
                /** Reseteamos la store de la clínica */
                this.clinicDetailService.resetStore();

                if (this.settingsQuery.isGeneric()) {
                    await this.initCompanyFromGeneric(systemKey);
                } else {
                    await this.initCompany(systemKey);
                }
            }
        }
    }

    isUserFormFieldVisible(field: string): boolean {
        return this.companyQuery.fwaClientNewFields.includes(field);
    }

    isUserFormFieldRequired(field: string): ValidatorFn {
        return this.companyQuery.fwaClientNewRequiredFields.includes(field)
            ? Validators.required
            : Validators.nullValidator;
    }

    resetStore() {
        this.companyStore.reset();
    }
}
