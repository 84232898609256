import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FamilyWizardModel } from '../../../models/family-wizard.model';
import { FamilyFolderModel } from '../../../models/family-folder.model';

@Component({
    selector: 'app-family-item',
    templateUrl: './family-item.component.html',
    styleUrls: ['./family-item.component.scss'],
})
export class FamilyItemComponent implements OnInit {
    @Input() family: FamilyFolderModel;
    @Input() iconFamilyItem: string;
    @Output() familyClickedEvent = new EventEmitter<FamilyFolderModel>();

    constructor() {}

    ngOnInit(): void {}

    onFamilyClicked(family: FamilyFolderModel) {
        this.familyClickedEvent.emit(family);
    }
}
