import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {ServiceWizardService} from '../../../../shared/state/service-wizard/service-wizard.service';
import {LoadingService} from '../../../../shared/state/loading/loading.service';
import {KeyboardQuery} from '../../../../shared/state/keyboard/keyboard.query';
import {ModalController} from '@ionic/angular';
import { ClinicDetailQuery } from 'src/app/shared/state/clinic-detail/clinic-detail.query';

@Component({
    selector: 'app-service-view',
    templateUrl: './service-view.component.html',
    styleUrls: ['./service-view.component.scss']
})
export class ServiceViewComponent implements OnInit {
    @ViewChild('dataContainer') dataContainer: ElementRef;
    @Input() serviceId: string;
    keyboardIsShowing: Observable<boolean>;

    /** Flowwwer template */
    clinicName$: Observable<string>;

    constructor(
        private serviceWizardService: ServiceWizardService,
        private loadingService: LoadingService,
        private keyboardQuery: KeyboardQuery,
        private modalController: ModalController,
        private clinicDetailQuery: ClinicDetailQuery
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.keyboardIsShowing = this.keyboardQuery.select('isShowing');
        this.loadingService.updateShowSpinner(true);
        const productHtmlPage = await this.serviceWizardService.getServiceHtmlPage(this.serviceId);

        this.loadingService.updateShowSpinner(false);
        this.dataContainer.nativeElement.innerHTML = productHtmlPage;

        /** Flowwwer template */
        this.clinicName$ = this.clinicDetailQuery.select('commercialName');
    }

    dismissModal() {
        this.modalController.dismiss();
    }
}
