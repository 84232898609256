import { Pipe, PipeTransform } from '@angular/core';
import { FLOW_DATE_FORMAT } from '../../../core/constants';
import { Moment } from 'moment';
import { LanguageService } from 'src/app/core/services/language/language.service';

@Pipe({
    name: 'moment'
})
export class MomentPipe implements PipeTransform {
    constructor(private language: LanguageService) {
    }

    transform(value: Moment, format?: string): string | Moment {
        if (value?.isValid()) {
            const userLanguage = this.language.getLocale();
            const translatedMoment = value.clone().locale(userLanguage);

            if (format) {
                return translatedMoment.format(format);
            }
            return translatedMoment.format(FLOW_DATE_FORMAT);
        }

        return value;
    }

}
