import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateWizard',
})
export class DateWizardPipe implements PipeTransform {
    transform(date: string, ...args: unknown[]): unknown {
        if (date) {
            return `${
                date.split('-')[2].length > 1
                    ? date.split('-')[2]
                    : '0' + date.split('-')[2]
            }/${
                date.split('-')[1].length > 1
                    ? date.split('-')[1]
                    : '0' + date.split('-')[1]
            }/${date.split('-')[0]}`;
        }
        return date;
    }
}
