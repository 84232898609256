import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClinicQuery } from '../clinic/clinic.query';
import { ClinicDetailStore, ClinicDetailState } from './clinic-detail.store';

@Injectable({ providedIn: 'root' })
export class ClinicDetailQuery extends Query<ClinicDetailState> {
    constructor(protected store: ClinicDetailStore, private clinicQuery: ClinicQuery) {
        super(store);
    }

    /*-----------*/
    /** GETTERS */
    /*----------*/

    get id(): string {
        return this.getValue()?.id;
    }

    get systemKey(): string {
        return this.getValue()?.systemKey;
    }

    get RGPDDisable(): boolean {
        return this.getValue()?.RGPDDisable;
    }

    get comercialName(): string {
        return this.getValue()?.commercialName;
    }

    get title(): string {
        return this.getValue()?.title;
    }

    get text(): string {
        return this.getValue()?.text;
    }

    get image(): Array<string> {
        return this.getValue()?.image;
    }

    get email(): string {
        return this.getValue()?.email;
    }

    get facebook(): string {
        return this.getValue()?.facebook;
    }

    get twitter(): string {
        return this.getValue()?.twitter;
    }

    get website(): string {
        return this.getValue()?.website;
    }

    get instagram(): string {
        return this.getValue()?.instagram;
    }

    get phone(): string {
        return this.getValue()?.phone;
    }

    get latitude(): string {
        return this.getValue()?.latitude;
    }

    get longitude(): string {
        return this.getValue()?.longitude;
    }

    get openAppointmentWizard(): boolean {
        return this.getValue()?.openAppointmentWizard;
    }

    get flowwwerReadOnly(): boolean {
        return this.getValue()?.flowwwerReadOnly;
    }

    get city(): string {
        return this.getValue()?.city;
    }

    get appDelegate(): boolean {
        return this.getValue()?.appDelegate;
    }

    get distance(): string {
        return this.getValue()?.distance;
    }

    selectIsCurrentClinicFav() {
        return combineLatest([
            this.clinicQuery.select('clinicsFav'),
            this.select()
        ]).pipe(
            map(([clinicsFav, clinic]) => {
                return !!clinicsFav.find((clinicFav) => {
                    return clinicFav.entityId == clinic.systemKey + '_' + clinic.id;
                });
            })
        );
    }
}
