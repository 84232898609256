import { Component, Input } from '@angular/core';
import { UserQuery } from '../../state/user/user.query';
import { Observable } from 'rxjs';
import { UserState } from '../../state/user/user.store';

type Icon = 'appointment' | 'cart' | 'message';

@Component({
  selector: 'app-empty-list',
  templateUrl: './empty-list.component.html',
  styleUrls: ['./empty-list.component.css']
})

export class EmptyListComponent {
  @Input() icon: Icon;
  public isTemporal: boolean = false;

  constructor(
      public userQuery: UserQuery
  ) {}

  ngOnInit() {
    this.userQuery.select().subscribe( state => {
        this.isTemporal = state.isTemporal;
    })
  }
}
