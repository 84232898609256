import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SocialLoginStore } from './social-login.store';
import { Observable } from 'rxjs';
import { SettingsQuery } from '../settings/settings.query';
import { ClinicDetailQuery } from '../clinic-detail/clinic-detail.query';
import { LanguageService } from '../../../core/services/language/language.service';
import { ApiService } from '../../../core/services/api/api.service';
import { UserService } from '../user/user.service';
import { StorageService } from 'src/app/core/services/storage/storage.service';

@Injectable({ providedIn: 'root' })
export class SocialLoginService {
    constructor(
        private socialLoginStore: SocialLoginStore,
        private http: HttpClient,
        private settingsQuery: SettingsQuery,
        private clinicDetailQuery: ClinicDetailQuery,
        private languageService: LanguageService,
        private apiService: ApiService,
        private userSerivce: UserService,
        private storage: StorageService
    ) {}

    /**
     * Registrar usuario en una company y en una clínica
     */
    private socialLoginApi(
        compnaySystemKey,
        clinicId,
        accesToken,
        provider,
        clientDeviceId
    ): Observable<any> {
        let params = new HttpParams();
        params = params.append('systemKey', compnaySystemKey);
        params = params.append('clinicId', clinicId);
        params = params.append('accessToken', accesToken);
        params = params.append('provider', provider);
        params = params.append('clientDeviceId', clientDeviceId);
        params = params.append('Cmd', 'c1026');
        params = params.append('Locale', this.languageService.getLocale());

        return this.apiService.callApi(params);
    }

    async socialLogin(accessToken: string, provider: string): Promise<void> {
        const deviceId = await this.storage.get('idDevice');
        const socialLoginData = await this.socialLoginApi(
            this.settingsQuery.systemKeyURL,
            this.clinicDetailQuery.id,
            accessToken,
            provider,
            deviceId
        ).toPromise();

        if (socialLoginData.ClientToken && socialLoginData.ClientToken !== '') {
            await this.userSerivce.saveCurrentTokenLocalStorage(
                socialLoginData.ClientToken,
                this.clinicDetailQuery.id
            );
            this.socialLoginStore.update({
                socialLoginSuccess: true,
            });
        } else {
            this.socialLoginStore.update({
                socialLoginFail: true,
                clientData: socialLoginData.ClientData,
            });
        }
    }

    resetStore() {
        this.socialLoginStore.reset();
    }
}
