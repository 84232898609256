import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Clinic } from '../../state/clinic/clinic.store';

@Component({
    selector: 'app-clinic-preview',
    templateUrl: './clinic-preview.component.html',
    styleUrls: ['./clinic-preview.component.scss'],
})
export class ClinicPreviewComponent implements OnInit {
    @Input() showImages = true;
    @Input() showCheck = false;
    @Input() clinic: Clinic;
    @Output() clinicClickedEvent = new EventEmitter<Clinic>();

    imagesCount: number;

    @HostListener('click', ['$event'])
    onClinicClicked() {
        this.clinicClickedEvent.emit(this.clinic);
    }
    
    constructor() {}

    ngOnInit(): void {
        if (!Array.isArray(this.clinic.images)) {
            this.clinic.images = [];
        }
        this.clinic.images = [...this.clinic.images].slice(0, 3);
        this.imagesCount = this.clinic.images.length;
    }
}
