import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ClinicDetailStore } from './clinic-detail.store';
import { Observable } from 'rxjs';
import { LanguageService } from '../../../core/services/language/language.service';
import { ClinicDetailQuery } from './clinic-detail.query';
import { ApiService } from 'src/app/core/services/api/api.service';
import { Storage } from '@ionic/storage';
import { ClinicQuery } from '../clinic/clinic.query';
import { filter, take } from 'rxjs/operators';
import { SettingsQuery } from '../settings/settings.query';
import { Clinic } from '../clinic/clinic.store';
import { ClinicService } from '../clinic/clinic.service';
import {
    removeDuplicateClinics,
} from 'src/app/core/services/utils/utils';
import { LocationService } from 'src/app/core/services/location/location.service';
import { StorageService } from 'src/app/core/services/storage/storage.service';

@Injectable({ providedIn: 'root' })
export class ClinicDetailService {
    constructor(
        private clinicDetailStore: ClinicDetailStore,
        private clinincDetailQuery: ClinicDetailQuery,
        private languageService: LanguageService,
        private apiService: ApiService,
        private storage: StorageService,
        private clinicQuery: ClinicQuery,
        private settingsQuery: SettingsQuery,
        private clinicService: ClinicService,
        private locationService: LocationService
    ) {}

    /*-----------*/
    /** UPDATES */

    /*----------*/

    updateOpenAppointmentWizard(newOpenAppointmentWizard) {
        this.clinicDetailStore.update({
            openAppointmentWizard: newOpenAppointmentWizard,
        });
    }

    /**
     * Crea la clínica y la carga en la Store
     */
    updateClinic(clinic, clinicId, clinicSystemKey) {
        this.clinicDetailStore.update({
            id: clinicId,
            systemKey: clinicSystemKey,
            commercialName: clinic.ClinicCommercialName,
            appDelegate: clinic.ClinicAppDelegated === '-1',
            address1: clinic.ClinicAddress1,
            address2: clinic.ClinicAddress2,
            address3: clinic.ClinicAddress3,
            phone: clinic.ClinicPhone,
            phone2: clinic.ClinicPhone2,
            email: new RegExp(
                '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$',
                'i'
            ).test(clinic.ClinicEMail)
                ? clinic.ClinicEMail
                : '',
            twitter: new RegExp('^(http|https)://', 'i').test(
                clinic.ClinicTwitter
            )
                ? clinic.ClinicTwitter
                : '',
            facebook: new RegExp('^(http|https)://', 'i').test(
                clinic.ClinicFacebook
            )
                ? clinic.ClinicFacebook
                : '',
            website: new RegExp('^(http|https)://', 'i').test(
                clinic.ClinicWebSite
            )
                ? clinic.ClinicWebSite
                : '',
            instagram: new RegExp('^(http|https)://', 'i').test(
                clinic.ClinicInstagram
            )
                ? clinic.ClinicInstagram
                : '',
            title: clinic.ClinicTitle,
            text: clinic.ClinicText,
            latitude: clinic.ClinicLatitude,
            longitude: clinic.ClinicLongitude,
            weekDayStart: clinic.ClinicWeekDayStart,
            legalCommercialPolicy: clinic.ClinicLegalCommercialPolicy,
            legalMobileDevicePolicy: clinic.ClinicLegalMobileDevicePolicy,
            RGPDDisable:
                clinic.RGPDDisable != null
                    ? clinic.RGPDDisable === '-1'
                    : false,
            image: clinic.ClinicImage,
            distance: clinic.distance,
            flowwwerReadOnly: clinic.flowwwerReadOnly === -1,
            city: clinic.ClinicCity,
        });
    }

    /*---------------*/
    /** LLAMADAS API */

    /*---------------*/

    /**
     * TODO: picWidth
     * Llamada api para obtener los detalles de una clínica seleccionada
     */
    private getClinicDetailApi(
        systemKey: string,
        clinicId: string,
        latitude: number,
        longitude: number
    ): Observable<any> {
        let params = new HttpParams();
        params = params.append('systemKey', systemKey);
        params = params.append('clinicId', clinicId);
        params = params.append('picWidth', '');
        params = params.append('latitude', latitude ? latitude.toString() : '');
        params = params.append(
            'longitude',
            longitude ? longitude.toString() : ''
        );
        params = params.append('Cmd', 'c1031');
        params = params.append('Locale', this.languageService.getLocale());

        return this.apiService.callApi(params);
    }

    /*------------*/
    /** SERVICIOS */

    /*------------*/

    async checkClinicAndInit(systemKey: string, clinicId: string) {
        if (this.settingsQuery.isPersonalized()) {
            /** Comprobamos primero si nuestra SK compilado tiene acceso a esta clínica SOLO PERSONALIZADAS */
            /** TODO: ver como hacerlo mejor */
            /** Esperamos a que akita guarde las clínicas */
            await this.clinicQuery.select('clinicsFound').pipe(
                filter((data) => data !== null && data !== undefined),
                take(1)
            );
            const myClinic = this.clinicQuery.findClinicByEntityId(
                systemKey + '_' + clinicId
            );
            if (!myClinic) {
                throw Error('No privileges');
            }
        }

        if (
            this.clinincDetailQuery.id === '' ||
            this.clinincDetailQuery.id !== clinicId ||
            this.clinincDetailQuery.systemKey !== systemKey
        ) {
            if (this.clinincDetailQuery.id !== clinicId) {
                this.resetStore();
            }

            /** TODO: CHAPUZA. Esto lo hago para asegurarme que por muy rápido que se le de a buscar,
             * siempre me asegure que ya se han cargado la latitud y longitud
             * (fallaba si buscabas rápido la primera vez). Recordemos que lo que espera
             * a tener la apiURL es la llamada http
             * Es la única llamada que tiene la espera en su propio servicio en vez de en el servicio api.service
             * ya que algunos de los datos que necesitamos, los carga la misma llamada de cargar la apiURL
             * Unico a resolver: si se pierde el queryparams y le das a f5 pierdes el flujo y se rompera y cargara la SK original del centro
             */
            await this.settingsQuery
                .select('apiURL')
                .pipe(
                    filter((apiURL) => apiURL !== ''),
                    take(1)
                )
                .toPromise();

            this.locationService.loadLocationDevice(false);
            const latitude = this.settingsQuery.latitude;
            const longitude = this.settingsQuery.longitude;
            /** Comprobamos si la clínica ya está inizializada */
            const clinicData = await this.getClinicDetailApi(
                systemKey,
                clinicId,
                latitude,
                longitude
            ).toPromise();

            /**
             * Cargamos los detalles de la clínica a la Store
             */
            this.updateClinic(clinicData.Clinic, clinicId, systemKey);

            /** Cargamos la clínica activa en los datos de caché de la app */
            if (this.settingsQuery.currentSystemKey !== systemKey) {
                this.storage.set('companyActive', {
                    ClinicID: clinicId,
                    SystemKey: systemKey,
                    ParentSystemKey: this.settingsQuery.currentSystemKey,
                });
            } else {
                this.storage.set('companyActive', {
                    ClinicID: clinicId,
                    SystemKey: systemKey,
                });
            }
        }
    }

    async togleFavCurrentClinic(): Promise<void> {
        let currentClinicsFavStorage =
            (await this.storage.get(
                this.clinicService.getKeyStoreClinicsFav()
            )) ?? [];

        //Si ya está la clínica marcada como favorita, la eliminamos
        const clinicIndex = currentClinicsFavStorage.findIndex(
            (clinicFav: Clinic) => {
                return (
                    clinicFav.entityId ===
                    this.clinincDetailQuery.systemKey +
                        '_' +
                        this.clinincDetailQuery.id
                );
            }
        );
        if (clinicIndex == -1) {
            const clinicFav = this.getCurentClinicDeatil2Clinic();
            const clinicsFav = removeDuplicateClinics([
                clinicFav,
                ...currentClinicsFavStorage,
            ]);
            this.storage.set(
                this.clinicService.getKeyStoreClinicsFav(),
                clinicsFav
            );
            this.clinicService.updateClinicsFav(clinicsFav);
        } else {
            currentClinicsFavStorage.splice(clinicIndex, 1);
            this.storage.set(
                this.clinicService.getKeyStoreClinicsFav(),
                currentClinicsFavStorage
            );
            this.clinicService.updateClinicsFav(currentClinicsFavStorage);
        }
    }

    getCurentClinicDeatil2Clinic() {
        return {
            entityId:
                this.clinincDetailQuery.systemKey +
                '_' +
                this.clinincDetailQuery.id,
            id: this.clinincDetailQuery.id,
            systemKey: this.clinincDetailQuery.systemKey,
            name: this.clinincDetailQuery.comercialName,
            city: this.clinincDetailQuery.city,
            distance: '434 m', //TODO,
            images: this.clinincDetailQuery.image,
            flowwwerReadOnly: this.clinincDetailQuery.flowwwerReadOnly,
        } as Clinic;
    }

    resetStore() {
        this.clinicDetailStore.reset();
    }
}
