import { Injectable } from '@angular/core';
import { HeaderPictureStore } from './header-picture.store';
import { SettingsQuery } from '../settings/settings.query';

@Injectable({ providedIn: 'root' })
export class HeaderPictureService {
    constructor(
        private headerPictureStore: HeaderPictureStore,
        private settingsQuery: SettingsQuery
    ) {}

    /*-----------*/
    /** UPDATES */
    /*----------*/

    updatePictures(dataPictures: any) {
        this.setHeaderPicture('home', dataPictures?.logo);
        this.setHeaderPicture('homeCompany', dataPictures?.logo);
        this.setHeaderPicture(
            'company',
            !this.settingsQuery.browser
                ? 'assets/sections/home.jpg'
                : dataPictures?.clinic
        );
        this.setHeaderPicture(
            'account',
            !this.settingsQuery.browser
                ? 'assets/sections/profile.jpg'
                : dataPictures?.user
        );
        this.setHeaderPicture(
            'appointment',
            !this.settingsQuery.browser
                ? 'assets/sections/appointments.jpg'
                : dataPictures?.sched
        );
        this.setHeaderPicture(
            'treatment',
            !this.settingsQuery.browser
                ? 'assets/sections/treatments.jpg'
                : dataPictures?.prepaid
        );
        this.setHeaderPicture(
            'messages',
            !this.settingsQuery.browser
                ? 'assets/sections/messages.jpg'
                : dataPictures?.inbox
        );
    }

    updatePicturesFromGeneric(dataPictures?: any) {
        this.setHeaderPicture('home', dataPictures?.logo);
        this.setHeaderPicture('homeCompany', dataPictures?.logo);
        this.setHeaderPicture('company', dataPictures?.clinic);
        this.setHeaderPicture('account', dataPictures?.user);
        this.setHeaderPicture('appointment', dataPictures?.sched);
        this.setHeaderPicture('treatment', dataPictures?.prepaid);
        this.setHeaderPicture('messages', dataPictures?.inbox);
    }

    setHeaderPicture(key, img) {
        this.headerPictureStore.update({
            [key]: img,
        });

        const objImage = new Image();
        objImage.onload = () => {
            this.headerPictureStore.update({
                [key]: img,
            });
        };
        objImage.onerror = () => {
            this.headerPictureStore.update({
                [key]: null,
            });
        };
        objImage.src = img;
    }
}
