import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { HeaderPictureStore, HeaderPictureState } from './header-picture.store';

@Injectable({ providedIn: 'root' })
export class HeaderPictureQuery extends Query<HeaderPictureState> {

  constructor(protected store: HeaderPictureStore) {
    super(store);
  }

}
