import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: 'root',
})

/**
 * Este servicio se encarga de obtener el idioma del navegador y setear la etiqueta LANG del index.html al idioma correspondiente
 * Esto se hace para que el navegador no detecte la aplicación está en otro idioma y traduzca automáticamente
 */
export class LanguageService {
    locale = 'en-GB';

    constructor(@Inject(DOCUMENT) private document: Document) {}

    loadBrowserLocale() {
        const navigatorLocale = navigator.languages
            ? navigator.languages[0]
            : navigator.language;

        if (navigatorLocale !== '') {
            this.locale = navigatorLocale;
        }
        this.document.documentElement.lang = this.locale.substr(0, 2);
    }

    getLocale() {
        return this.locale.substr(0, 2);
    }
}
