import { Injectable } from '@angular/core';
import { GetResult, Preferences } from '@capacitor/preferences';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
    private storageMode: StorageOptions = StorageOptions.Preferences;
  
    constructor(
        private sqlLite: Storage
    ) { }

    /**
     * Get the value from a given key.
     */
    async get(key: string): Promise<any> {
        let value: GetResult;
        switch(this.storageMode) {
            case StorageOptions.SQLLite:
                return this.sqlLite.get(key);
            case StorageOptions.Preferences:
                value = await Preferences.get({key: key});
                return JSON.parse(value.value);
        }
    }

    /**
     * Set the value for a given key.
     */
    set(key: string, data: any):  Promise<void> {
        switch(this.storageMode) {
            case StorageOptions.SQLLite:
                return this.sqlLite.set(key, data);
            case StorageOptions.Preferences:
                return Preferences.set({key: key, value: JSON.stringify(data)});
        }
    }

    /**
     * Remove the value from a given key, if any.
     */
    remove(key: string): Promise<void>{
        switch(this.storageMode) {
            case StorageOptions.SQLLite:
                return this.sqlLite.remove(key);
            case StorageOptions.Preferences:
                return Preferences.remove({key: key});
        }
    }

    async keys(): Promise<string[]> {
        switch(this.storageMode) {
            case StorageOptions.SQLLite:
                const keys: string[] = await this.sqlLite.keys();
                return keys;
            case StorageOptions.Preferences:
                return (await Preferences.keys()).keys;
        }
    }

    async migrateSQLLiteDataToPreferences() {
        const migrationStatus = await Preferences.get({key: 'migration'});

        if (migrationStatus.value) return;

        try {
            const keys = await this.sqlLite.keys();
     
            keys.forEach( async key => {
                await this.sqlLite.get(key).then(
                    data => {
                        Preferences.set({
                            key: key,
                            value: JSON.stringify(data)
                        })
                    }
                )
            });
            this.setMigrationStatus(true);
  
        } catch (error) {
           this.setMigrationStatus(false);
        }
       
    }

    setMigrationStatus(status: boolean) {
        Preferences.set({key: 'magration', value: status.toString()});
    }


}

enum StorageOptions {
    SQLLite,
    Preferences
}
