import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, of, Subscriber, Subscription } from 'rxjs';
import { PackWizard } from 'src/app/shared/state/pack-wizard/pack-wizard.model';
import { PackWizardQuery } from 'src/app/shared/state/pack-wizard/pack-wizard.query';

@Component({
  selector: 'app-pack-item',
  templateUrl: './pack-item.component.html',
  styleUrls: ['./pack-item.component.scss']
})
export class PackItemComponent {
  @Input() pack: PackWizard;
  @Input() packIsChecked$: Observable<boolean>;
  @Output() packToggleEvent = new EventEmitter<any>();

  public checked: boolean;

  private subscription$: Subscription;

  constructor(
    private packWizardQuery: PackWizardQuery
    ) {}

  ngOnInit() {
    this.subscription$ = this.packWizardQuery.selectEntity(
      e => e.id === this.pack.id
    ).subscribe(({isChecked}) => {
      this.checked = isChecked;
    });
    
  }

  onPackToggle() {
    this.packToggleEvent.emit(this.pack);
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }
}
