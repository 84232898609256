import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AlertController, Platform } from '@ionic/angular';
import { Observable, Subscription, distinctUntilChanged, filter, map } from 'rxjs';
import { fade_in, fade_out_absolute } from 'src/app/core/animations/fade';
import { LibraryService } from 'src/app/core/services/library/library.service';
import { RouterService } from 'src/app/core/services/router/router.service';
import { ClinicDetailQuery } from '../../state/clinic-detail/clinic-detail.query';
import { ClinicDetailState } from '../../state/clinic-detail/clinic-detail.store';
import { HeaderPictureQuery } from '../../state/header-picture/header-picture.query';
import { Message } from '../../state/message/message.model';
import { MessageQuery } from '../../state/message/message.query';
import { SettingsQuery } from '../../state/settings/settings.query';
import { UserQuery } from '../../state/user/user.query';
import { UserService } from '../../state/user/user.service';
import { UserState } from '../../state/user/user.store';
import { InputSearchComponent } from '../input-search/input-search.component';
import { Capacitor } from '@capacitor/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-header-desktop',
    animations: [fade_in, fade_out_absolute],
    templateUrl: './header-desktop.component.html',
    styleUrls: ['./header-desktop.component.scss'],
})
export class HeaderDesktopComponent implements OnInit {
    @Input() navigationBack = true; //TODO: QUien implemente esto, tiene que tener en cuenta que si hay solo 1 clínica no debe de mostrar el botón
    @Input() loginButton = true;
    @Input() logoUrl = '';
    @Input() search = false;
    @Input() login = false;
    @Input() showSkeleton = false;
    @Input() skeletonBackButton = false;
    @Input() hideSearch = true;
    @Input() favouriteActive = true;
    @Input() favourite = false;
    @Output() onClickBack = new EventEmitter<void>();
    @Output() onWriteSearch = new EventEmitter<string>();
    @Output() onSearch = new EventEmitter<string>();
    @Output() onHideSearch = new EventEmitter<void>();
    @Output() onClickInputSearch = new EventEmitter<void>();
    @Output() onFavourite = new EventEmitter<void>();

    @ViewChild(InputSearchComponent) inputSearch: InputSearchComponent;

    currentTab = '';
    user: UserState;
    user$: Observable<UserState>;
    isGeneric$: Observable<boolean>;
    clinic$: Observable<ClinicDetailState>;
    
    private readonly routesLargeLogo: string[] = ['details'];

    public showLargeLogo: boolean = true;
    public isNative: boolean = Capacitor.isNativePlatform();
    public isFlowwwer: boolean = !!environment.genericKey;
    public isCustom: boolean = !!environment.systemKey;
    public isBrowser: boolean = !!environment.browser;

    private router$?: Subscription;

    routes = [
        {
            route: 'details',
            icon: 'home-outline',
            badge: 0,
            visible: true,
        },
        {
            route: 'user-account',
            icon: 'person-outline',
            badge: 0,
            visible: true,
        },
        {
            route: 'treatment',
            icon: 'cart-outline',
            badge: 0,
            visible: true,
        },
        {
            route: 'appointment',
            icon: 'today-outline',
            badge: 0,
            visible: true,
        },
        {
            route: 'message',
            icon: 'mail-outline',
            badge: 0,
            visible: true,
        },
    ];
    constructor(
        private router: Router,
        private settingsQuery: SettingsQuery,
        private clinicDetailQuery: ClinicDetailQuery,
        private userQuery: UserQuery,
        private alertCtrl: AlertController,
        private libraryService: LibraryService,
        private platform: Platform,
        private userService: UserService,
        private routerService: RouterService,
        private headerPicturesQuery: HeaderPictureQuery,
        private messageQuery: MessageQuery
    ) {
        this.currentTab = this.router.url.split('/')[3]?.split('?')[0];

        this.user$ = this.userQuery.select();
        this.user$.subscribe((user) => {
            this.user = user;
        });

        this.clinic$ = this.clinicDetailQuery.select();
    }

    ngOnInit(): void {
        this.isGeneric$ = this.settingsQuery.selectIsGeneric();
        if (this.logoUrl === '') {
            this.headerPicturesQuery
                .select('homeCompany')
                .subscribe((homeCompany) => {
                    this.logoUrl = homeCompany;
                });
        }

        this.messageQuery
            .selectAll({
                filterBy: ({ read }) => read === false,
            })
            .subscribe((messages) => {
                this.routes[4].badge = messages.length;
            });

        this.settingsQuery
            .select('showClinicDetails')
            .subscribe((showClinicDetails) => {
                this.routes[0].visible = showClinicDetails;
            });

        this.router$ = this.router.events
        .pipe(
            filter((event) => event instanceof NavigationEnd),
            map( (e: NavigationEnd) => e.url),
            distinctUntilChanged()
        )
        .subscribe( (path: string) => {
            const url = path.split('/').pop();
            this.showLargeLogo = this.routesLargeLogo.includes(url);
        });
    }

    clickBackButton(): void {
        this.onClickBack.emit();
    }

    clickTabButton(route): void {
        this.router.navigate([
            '/' +
                this.settingsQuery.systemKeyURL +
                '/' +
                this.clinicDetailQuery.id +
                '/' +
                route,
        ]);
    }

    get userImage() {
        const avatarBackground = window
            .getComputedStyle(document.documentElement)
            .getPropertyValue('--ion-color-secondary')
            .slice(1);
        const avatarColor = window
            .getComputedStyle(document.documentElement)
            .getPropertyValue('--ion-color-secondary-contrast')
            .slice(1);
        return `https://eu.ui-avatars.com/api/?name=${encodeURIComponent(
            this.user.nameFull
        )}&background=${avatarBackground}&color=${avatarColor}&rounded=true&bold=true`;
    }

    async showConfirmLogout() {
        const alert = await this.alertCtrl.create({
            mode: this.platform.is('android') ? 'md' : 'ios',
            message: this.libraryService.getLabel('app_con_logout'),
            buttons: [
                {
                    text: this.libraryService.getLabel(
                        'app_ask_sched_step_3_button'
                    ),
                    role: 'confirm',
                    handler: () => {
                        this.logout();
                    },
                },
                {
                    text: this.libraryService.getLabel('app_action_cancel'),
                    role: 'cancel',
                },
            ],
        });
        await alert.present();
    }

    logout() {
        this.userService.deleteCurrentTokenLocalStorage();
        this.userService.resetStore();
        this.routerService.goToMainTabWithoutLogin();
    }

    signUp() {
        this.router.navigate([
            '/' +
                this.settingsQuery.systemKeyURL +
                '/' +
                this.clinicDetailQuery.id +
                //'/register/social', E.C.S Se oculta el login social
                '/register/default'
        ]);
    }

    writeInputSearch(searchText) {
        this.onWriteSearch.emit(searchText);
    }

    searchInputSearch(searchText) {
        this.onSearch.emit(searchText);
    }

    clearSearchInput() {
        this.inputSearch.clearInput();
    }

    clickShowHideButton() {
        this.onHideSearch.emit();
    }

    clickInputSearch(): void {
        this.onClickInputSearch.emit();
    }

    clickFavourite(): void {
        this.onFavourite.emit();
    }

    ngOnDestroy() {
        this.router$?.unsubscribe();
    }

}
