import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface LoadingState {
    active: boolean;
    showSpinner: boolean;
}

export function createInitialState(): LoadingState {
    return {
        active: false,
        showSpinner: false,
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'loading' })
export class LoadingStore extends Store<LoadingState> {
    constructor() {
        super(createInitialState());
    }
}
