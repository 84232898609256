import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';

export const fade_out = trigger('fade', [
    // the "in" style determines the "resting" state of the element when it is visible.
    state('in', style({ opacity: 1 })),

    // fade out when destroyed. this could also be written as transition('void => *')
    transition(':leave', animate(300, style({ opacity: 0 }))),
]);

export const fade_out_absolute = trigger('fade_out_absolute', [ //El fade_out_absolute por ahora solo lo usamos para skeletons de footers y para el del header, que no pueden ser de primeras absolutos porque no aparecen
    // the "in" style determines the "resting" state of the element when it is visible.
    state('in', style({ opacity: 1 })),

    // fade out when destroyed. this could also be written as transition('void => *')
    transition(':leave', [
        style({ opacity: 1, position: 'absolute', top: '0px' }),
        animate(300, style({ opacity: 0 })),
    ]),
]);


export const fade_in = trigger('fade_in', [
    // the "in" style determines the "resting" state of the element when it is visible.
    state('in', style({ opacity: 1 })),

    // fade out when destroyed. this could also be written as transition('void => *')
    transition(':enter', [style({ opacity: 0 }), animate(300)]),
]);
