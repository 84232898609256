import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { CompanyStore, CompanyState } from './company.store';
import { PrimaryColorsModel } from '../../models/primary-colors.model';

@Injectable({ providedIn: 'root' })
export class CompanyQuery extends Query<CompanyState> {
    constructor(protected store: CompanyStore) {
        super(store);
    }

    /*-----------*/
    /** GETTERS */
    /*----------*/

    get systemKey(): string {
        return this.getValue()?.systemKey;
    }

    get systemReseller(): string {
        return this.getValue()?.systemReseller;
    }

    get nameSplit(): boolean {
        return this.getValue()?.nameSplit;
    }

    get maxDate(): string {
        return this.getValue()?.maxDate;
    }

    get maxServices(): string {
        return this.getValue()?.maxServices;
    }

    get fwaAppDisableTemp(): boolean {
        return this.getValue()?.fwaAppDisableTemp;
    }

    get fwaClientNewFields(): string {
        return this.getValue()?.fwaClientNewFields;
    }

    get fwaClientNewRequiredFields(): string {
        return this.getValue()?.fwaClientNewRequiredFields;
    }

    get primaryColors(): PrimaryColorsModel {
        return this.getValue()?.primaryColors;
    }

    get style(): any {
        return this.getValue()?.style;
    }

    get labels(): any {
        return this.getValue()?.labels;
    }

    get clientAdvisor(): boolean {
        return this.getValue()?.clientAdvisor;
    }

    get clientAdvisorTabMode(): boolean {
        return this.getValue()?.clientAdvisorTabMode;
    }

    get offlineCustomLibraryLoaded(): boolean {
        return this.getValue()?.offlineCustomLibraryLoaded;
    }
}
