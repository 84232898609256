import { Injectable } from '@angular/core';
import { StoreConfig, Store } from '@datorama/akita';

//TODO: OJO! En personalizadas, clinics Found son las clinicas totales de la company, los demás arrays tienen que tener solo clínicas de ese array, si no, fallaría todo
export interface ClinicState {
    clinicsFav: Clinic[];
    clinicsLatestResults: Clinic[];
    clinicsNearest: Clinic[];
    clinicsFound: Clinic[];
}

export function createInitialState(): ClinicState {
    return {
        clinicsFav: [],
        clinicsLatestResults: [],
        clinicsNearest: [],
        clinicsFound: [],
    };
}
export interface Clinic {
    entityId: string /** ID único generado manualmente a través del systemKey y el clínicID para el ID (único) de Akita */;
    id: string /** ClinicId */;
    systemKey: string /** SystemKey al que pertenece la cínica */;
    name: string /** Nombre de la clínica */;
    city: string /** Ciudad de la clínica */;
    latitude: string /** latitude de la clínica */;
    longitude: string /** longitude de la clínica */;
    distance: string /** Distancia de la clínica */
    flowwwerReadOnly: boolean /** Campo que nos indica si esta Clínica es de solo lectura en modo Generic. Este dato se importará en clinicDetailQuery para un mejor uso del valor. Habrá fallos de seguridad controlados si no se pasa por el buscador */;
    images: string[] /** Imágenes que se usan para mostrar los centros favoritos. Es un array de links de imágenes */;
    address1: string;
    address2: string;
    address3: string;
}

/**
 * Factoría que crea una Clinica con los datos recogidos por la API y un systemKey
 */
export function clinicFactory(params: any, currentSystemKey: string) {
    return {
        entityId:
            (params.SystemKey ?? currentSystemKey) + '_' + params.ClinicID,
        id: params.ClinicID,
        systemKey: params.SystemKey ?? currentSystemKey,
        name: params.ClinicName,
        city: params.ClinicCity,
        latitude: params.ClinicLatitude,
        longitude: params.ClinicLongitude,
        distance: params.ClinicDistance,
        flowwwerReadOnly:
            params.FLOWwwerReadOnly != null
                ? params.FLOWwwerReadOnly === -1
                : false,
        images: params.images,
        address1: params.ClinicAppAddress1,
        address2: params.ClinicAppAddress2,
        address3: params.ClinicAppAddress3

    } as Clinic;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'clinic', resettable: true })
export class ClinicStore extends Store<ClinicState> {
    constructor() {
        super(createInitialState());
    }
}
