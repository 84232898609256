import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FamilyFolderModel } from '../../../models/family-folder.model';
import { ProductWizard } from '../../../state/product-wizard/product-wizard.model';

@Component({
    selector: 'app-family-product-list',
    templateUrl: './family-product-list.component.html',
    styleUrls: ['./family-product-list.component.scss'],
})
export class FamilyProductListComponent implements OnInit {
    @Input() families: FamilyFolderModel[];
    @Input() iconFamilyItem: string;
    @Input() isLoadingContent: boolean;
    @Input() productsWizard: ProductWizard[];
    @Input() appDelegate: boolean;

    @Output() familyClickedEvent = new EventEmitter<FamilyFolderModel>();
    @Output() productToggleEvent = new EventEmitter<any>();
    @Output() productClickToPresentModalEvent = new EventEmitter<any>();
    /** Flowwwer template */
    @Output() productClickEvent = new EventEmitter<any>();

    constructor() {}

    ngOnInit(): void {}

    onFamilyClicked(family: FamilyFolderModel) {
        this.familyClickedEvent.emit(family);
    }

    onProductToggle(productWizard) {
        this.productToggleEvent.emit(productWizard);
    }

    onProductClickToPresentModal(productWizard) {
        this.productClickToPresentModalEvent.emit(productWizard);
    }

    /** Flowwwer template */
    onProductClick(product) {
        this.productClickEvent.emit(product);
    }
}
