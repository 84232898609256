import { Injectable } from '@angular/core';
import { QueryEntity, StoreConfig } from '@datorama/akita';
import { PackWizardState, PackWizardStore } from './pack-wizard.store';
import { PackWizard } from './pack-wizard.model';

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'service-wizard', resettable: true })
export class PackWizardQuery extends QueryEntity<
    PackWizardState,
    PackWizard
> {
    constructor(protected store: PackWizardStore) {
        super(store);
    }
}
