import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-social-login-button',
    templateUrl: './social-login-button.component.html',
    styleUrls: ['./social-login-button.component.scss'],
})
export class SocialLoginButtonComponent implements OnInit {
    @Input() logoSrc;
    @Input() label;
    @Output() buttonClickEvent = new EventEmitter<any>();

    constructor() {}

    ngOnInit(): void {}

    click(): any {
        this.buttonClickEvent.emit();
    }
}
