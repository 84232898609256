import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { ClinicStore, ClinicState, Clinic } from './clinic.store';

@Injectable({
    providedIn: 'root',
})
export class ClinicQuery extends Query<ClinicState> {
    constructor(protected store: ClinicStore) {
        super(store);
    }


    get clinicNearest(){
        return this.getValue().clinicsNearest;
    }

    /** TODO: Mirar si podemos eliminar esta función, ya que donde se usa es una chapuza que alomejor quitamos */
    findClinicByEntityId(entityId: string): Clinic {
        const clinic = [
            ...this.getValue().clinicsFav,
            ...this.getValue().clinicsLatestResults,
            ...this.getValue().clinicsFound,
            ...this.getValue().clinicsNearest,
        ].find((clinic: Clinic) => clinic.entityId === entityId);
        return clinic;
    }
}
