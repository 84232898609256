import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { LoadingService } from '../../shared/state/loading/loading.service';

@Injectable()

/** Clase que capturará todos los errores que no sean capturados por el código
 * Nos ayudará a añadir lógica cuando ocurra cualquier error
 * IMPORTANTE: Si un error tiene un Catch en el código, no entrará aquí
 */
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private loadingService: LoadingService) {}
    handleError(error) {
        if (!(error instanceof HttpErrorResponse)) {
            this.loadingService.updateShowSpinner(false);
        }
        console.error(error);
    }
}
