import { Clinic } from 'src/app/shared/state/clinic/clinic.store';

export function removeDuplicateObjects(array: any[]) {
    return [...new Set(array.map((s) => JSON.stringify(s)))].map((s) =>
        JSON.parse(s)
    );
}

export function removeDuplicateClinics(array: Clinic[]) {
    const arrayWithoutDuplicity = [];

    array.map((clinic: Clinic) => {
        const exist = arrayWithoutDuplicity.some((clinicAux: Clinic) => {
            return clinic.entityId == clinicAux.entityId;
        });
        if (!exist) {
            arrayWithoutDuplicity.push(clinic);
        }
    });

    return arrayWithoutDuplicity;
}
