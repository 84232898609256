import { TemplateFunctionsModel } from 'src/app/shared/models/template-functions';

export function templateUpdatePrimaryColorsPalettesDefault(
    { flowwwerTemplate }: TemplateFunctionsModel,
    primary,
    secondary,
    tertiary,
    tertiaryContrast
): void {
    flowwwerTemplate(primary, secondary, tertiary, tertiaryContrast);
}
