import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { IonSearchbar } from '@ionic/angular';

@Component({
    selector: 'app-service-searchbar',
    templateUrl: './service-searchbar.component.html',
    styleUrls: ['./service-searchbar.component.scss'],
})
export class ServiceSearchbarComponent implements OnInit {
    @Input()
    placeholderLabel = '';

    @Output() changeServiceSearchbarEvent: EventEmitter<any> = new EventEmitter<
        any
    >();

    @ViewChild(IonSearchbar) searchBar: IonSearchbar;

    constructor() {}

    ngOnInit(): void {}

    onChangeClinicSearchbar(ev: any) {
        const searchText = ev.target.value.toLowerCase();
        this.changeServiceSearchbarEvent.emit(searchText);
    }

    clearInput() {
        this.searchBar.value = '';
    }
}
