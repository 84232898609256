import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ClinicActiveGuard } from './shared/guards/clinic-active.guard';
import { SystemkeyParamGuard } from './shared/guards/systemkey-param.guard';

const routes: Routes = [
    {
        path: 'not-found',
        loadChildren: () =>
            import('./modules/not-found/not-found.module').then(
                (m) => m.NotFoundModule
            ),
    },
    {
        path: 'app-disabled',
        loadChildren: () =>
            import('./modules/app-disabled/app-disabled.module').then(
                (m) => m.AppDisabledModule
            ),
    },
    {
        path: 'logout',
        loadChildren: () =>
            import('./modules/logout/logout.module').then(
                (m) => m.LogoutModule
            ),
    },
    /** E.C.S: Se desactiva esta funcionalidad momentaneamente (Login social)
    {
        path: 'social-login',
        loadChildren: () =>
            import('./modules/social-login/social-login.module').then(
                (m) => m.SocialLoginModule
            ),
    },*/
    /** TODO: Ver si se puede mejorar parámetro opcional */
    {
        path: ':systemKey',
        canActivate: [SystemkeyParamGuard],
        canDeactivate: [ClinicActiveGuard],
        loadChildren: () =>
            import('./modules/home/home.module').then((m) => m.HomeModule),
    },
    {
        path: '',
        canActivate: [SystemkeyParamGuard],
        canDeactivate: [ClinicActiveGuard],

        loadChildren: () =>
            import('./modules/home/home.module').then((m) => m.HomeModule),
    },
    {
        path: '**',
        loadChildren: () =>
            import('./modules/not-found/not-found.module').then(
                (m) => m.NotFoundModule
            ),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
