import { Injectable } from '@angular/core';
import * as Color from 'color';
import { templateUpdatePrimaryColorsPalettesDefault } from './color.functions';

@Injectable({
    providedIn: 'root',
})
export class ColorService {
    shadeRatio = 0.1;
    tintRatio = 0.1;

    constructor() {}

    /**
     * Actualiza los estilos primario, secundario y terciario y sus paletas, a partir de 3 valores hexadecimales
     * Esto va a servir para actualizar todos los demás colores que se basan n estos
     */
    updatePrimaryColorsPalettes(
        primary: string,
        secondary: string,
        tertiary: string,
        tertiaryContrast: string
    ) {
        templateUpdatePrimaryColorsPalettesDefault(
            {
                defaultTemplate: this.updatePrimaryColorsPalettesDefault,
                flowwwerTemplate: this.updatePrimaryColorsPalettesFlowwwer,
            },
            primary,
            secondary,
            tertiary,
            tertiaryContrast
        );
    }

    updatePrimaryColorsPalettesDefault = (
        primary: string,
        secondary: string,
        tertiary: string
    ) => {
        this.createColorPalette('ion-color-primary', primary);
        this.createColorPalette('ion-color-secondary', secondary);
        this.createColorPalette('ion-color-tertiary', tertiary);
    };

    /** En la plantilla de flowww, hemos cambiado la configuración de colores primarios, por lo que para no modificar los colores de los sistemas en bbdd
     * y que el cambio entre plantillas sea fácil, hemos cambiado los colores mediante esta función
     */
    updatePrimaryColorsPalettesFlowwwer = (
        primary: string,
        secondary: string,
        tertiary: string,
        tertiaryContrast: string
    ) => {
        this.createColorPalette('ion-color-primary', secondary);
        this.createColorPalette('ion-color-secondary', tertiary, {
            hexContrast: tertiaryContrast,
        });
        this.createColorPalette('ion-color-tertiary', primary);
    };

    /**
     * Crea una paleta de colores completa para un color y la actualiza en la página
     */
    createColorPalette(
        key: string,
        hex: string,
        { hexContrast } = { hexContrast: null }
    ): void {
        if (key !== '' && hex !== '') {
            hex = this.checkHexadecimalColor(hex);
            try {
                document.documentElement.style.setProperty(`--${key}`, hex);
                document.documentElement.style.setProperty(
                    `--${key}-rgb`,
                    this.getRGBColor(hex).join(',')
                );
                document.documentElement.style.setProperty(
                    `--${key}-contrast`,
                    this.checkHexadecimalColor(hexContrast)
                        ? this.checkHexadecimalColor(hexContrast)
                        : this.getContrastColor(hex)
                );
                document.documentElement.style.setProperty(
                    `--${key}-contrast-rgb`,
                    this.getRGBColor(this.getContrastColor(hex)).join(',')
                );
                document.documentElement.style.setProperty(
                    `--${key}-shade`,
                    this.getShadeColor(hex)
                );
                document.documentElement.style.setProperty(
                    `--${key}-tint`,
                    this.getTintColor(hex)
                );
            } catch (e) {
                console.log(e);
            }
        }
    }

    checkHexadecimalColor(hex) {
        if (hex?.charAt(0) !== '#') {
            hex = `#${hex}`;
        }
        if (!new RegExp('^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$', 'i').test(hex)) {
            return null;
        }
        return hex;
    }

    getRGBColor(hex: string): Array<number> {
        const hexVal = hex.substring(1, hex.length);
        const bigint = parseInt(hexVal, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return [r, g, b];
    }

    getContrastColor(color: string): string {
        const c = Color(color);
        return c.isDark() ? '#FFFFFF' : '#000000';
    }

    getShadeColor(hex: string): string {
        return Color(hex).darken(this.shadeRatio);
    }

    getTintColor(hex: string): string {
        return Color(hex).lighten(this.tintRatio);
    }
}
