import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-height-no-compatible',
  templateUrl: './height-no-compatible.component.html',
  styleUrls: ['./height-no-compatible.component.scss']
})
export class HeightNoCompatibleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
