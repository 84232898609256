import { Injectable } from '@angular/core';
import { Badge } from '@capawesome/capacitor-badge';
import { MessageQuery } from 'src/app/shared/state/message/message.query';
import { Capacitor } from '@capacitor/core';

@Injectable({
    providedIn: 'root',
})
export class BadgeService {
    subscriptionMessage;

    constructor(private messageQuery: MessageQuery) {}

    initListenerBadge() {
        if (Capacitor.isNativePlatform()) {
            this.messageQuery
                .selectAll({
                    filterBy: ({ read }) => read === false,
                })
                .subscribe((messages) => {
                    Badge.set({ count: messages.length });
                });
        }
    }
}
