import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { Market } from '@awesome-cordova-plugins/market/ngx';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { ApiService } from '../api/api.service';
import { SettingsService } from '../../../shared/state/settings/settings.service';
import { SettingsQuery } from 'src/app/shared/state/settings/settings.query';
import { filter, take } from 'rxjs/operators';
import { NotFoundError } from 'src/app/shared/errors/not-found.error';

@Injectable({
    providedIn: 'root',
})
export class VersionService {
    constructor(
        private appVersion: AppVersion,
        private market: Market,
        private apiService: ApiService,
        private settingsQuery: SettingsQuery,
        private settingsService: SettingsService
    ) {}

    /**
     * Llamada api para obtener los grupos de servicios
     */
    private checkUpdateAppApi(versionNumber: string): Observable<any> {
        let params = new HttpParams();
        params = params.append('VersionNumber', versionNumber);
        params = params.append('cmd', 'c9001');

        return this.apiService.callApi(params);
    }

    /** TODO: Obtener números de versión desde la API */
    async checkUpdateApp(): Promise<any> {
        if (Capacitor.isNativePlatform()) {
            try {
                let appVersion = await this.appVersion.getVersionNumber();
                appVersion =
                    appVersion.split('.')[0] +
                    ('000' + appVersion.split('.')[1]).slice(-3);
                const versionData: any = await this.checkUpdateAppApi(
                    appVersion
                ).toPromise();
                this.settingsService.updateStatus(versionData.Status);

                //Con esto me aseguro que ya se ha guardado en Akita el status correcto
                await new Promise((resolve) => {
                    this.settingsQuery
                        .select('status')
                        .pipe(
                            filter((status) => status == versionData.Status),
                            take(1)
                        )
                        .subscribe((_) => {
                            resolve(true);
                        });
                });
            } catch (e) {
                throw new NotFoundError('Not found error');
            }
        }
    }

    async goToMarket() {
        const pacakgeName = await this.appVersion.getPackageName();
        console.log('package name', pacakgeName);
        this.market.open(pacakgeName);
    }
}
