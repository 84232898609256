import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HeaderIconStore } from './header-icon.store';

@Injectable({ providedIn: 'root' })
export class HeaderIconService {
    constructor(
        private headerIconStore: HeaderIconStore,
        private http: HttpClient
    ) {}

    /*-----------*/
    /** UPDATES */
    /*----------*/

    updateIcons() {
        this.headerIconStore.update({
            company: 'business-outline',
            treatment: 'basket-outline',
            appointment: 'time-outline',
            account: 'person-outline',
            messages: 'chatbox-ellipses-outline',
        });
    }
}
