import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ServiceWizard } from '../../../state/service-wizard/service-wizard.model';
import { AppointmentWizardDate } from '../../../state/appointment-wizard-date/appointment-wizard-date.model';
import { AppointmentWizardTime } from '../../../state/appointment-wizard-time/appointment-wizard-time.model';
import { ServicesGroup } from 'src/app/shared/state/services-group/services-group.model';
import { Clinic } from 'src/app/shared/state/clinic/clinic.store';
import { ClinicDetailService } from 'src/app/shared/state/clinic-detail/clinic-detail.service';
import { ProductWizard } from 'src/app/shared/state/product-wizard/product-wizard.model';

@Component({
    selector: 'app-result-wizard',
    templateUrl: './result-wizard.component.html',
    styleUrls: ['./result-wizard.component.scss'],
})
export class ResultWizardComponent implements OnInit {
    @Input() servicesCheked: ServiceWizard[];
    @Input() dateActive: AppointmentWizardDate;
    @Input() timeActive: AppointmentWizardTime;
    @Input() labelResult: string;
    @Input() labelDissmisModal: string;
    @Input() showCalendarButton: boolean;
    /** Flowwwer template */
    @Input() servicesGroups: ServicesGroup[];
    @Input() productsWithUnits: ProductWizard[];

    @Output() dissmisModalEvent = new EventEmitter<any>();

    /** Flowwwer template */
    currentClinic: Clinic;

    constructor(private clinicDetailService: ClinicDetailService) {}

    ngOnInit(): void {
        this.currentClinic = this.clinicDetailService.getCurentClinicDeatil2Clinic();
    }

    goToAppointments() {
        this.dissmisModalEvent.emit();
    }

    get servicesDescription() {
        if (this.servicesCheked && this.servicesCheked.length > 0) {
            return this.servicesCheked.map((s) => s.description);
        }

        return [];
    }
}
