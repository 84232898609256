import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ClinicStore } from './clinic.store';
import { Clinic, clinicFactory } from './clinic.store';
import { Observable } from 'rxjs';
import { LanguageService } from '../../../core/services/language/language.service';
import { SettingsQuery } from '../settings/settings.query';
import { LocationService } from 'src/app/core/services/location/location.service';
import { ApiService } from 'src/app/core/services/api/api.service';
import { filter, take } from 'rxjs/operators';
import {
    removeDuplicateClinics,
} from 'src/app/core/services/utils/utils';
import { StorageService } from 'src/app/core/services/storage/storage.service';

@Injectable({ providedIn: 'root' })
export class ClinicService {
    MAX_CLINICS_LATEST_RESULTS = 10;
    constructor(
        private clinicStore: ClinicStore,
        private http: HttpClient,
        private languageService: LanguageService,
        private settingsQuery: SettingsQuery,
        private locationService: LocationService,
        private apiService: ApiService,
        private storage: StorageService
    ) {}

    /*---------------------*/
    /** OPERACIONES ENTITY */

    /*---------------------*/

    updateClinicsFav(newClinicsFav: Clinic[]): void {
        this.clinicStore.update({ clinicsFav: newClinicsFav });
    }

    updateClinicsLatestResults(newClinicsLatestResults: Clinic[]): void {
        this.clinicStore.update({
            clinicsLatestResults: newClinicsLatestResults,
        });
    }

    updateClinicsNearest(newClinicsNearest: Clinic[]): void {
        this.clinicStore.update({ clinicsNearest: newClinicsNearest });
    }

    updateClinicsFound(newClinicsFound: Clinic[]): void {
        this.clinicStore.update({ clinicsFound: newClinicsFound });
    }

    /*---------------*/
    /** LLAMADAS API */

    /*---------------*/

    /**
     * Llamada api para obtener las clínicas de Generic mediante una key
     */
    private getClinicsByKeyApi(
        key: string,
        latitude: number,
        longitude: number
    ): Observable<any> {
        let params = new HttpParams();
        params = params.append('search', key);
        params = params.append(
            'promoterId',
            this.settingsQuery.genericKey.toString()
        );
        params = params.append('latitude', latitude ? latitude.toString() : '');
        params = params.append(
            'longitude',
            longitude ? longitude.toString() : ''
        );
        params = params.append('Cmd', 'c1101');
        params = params.append('Locale', this.languageService.getLocale());

        return this.apiService.callApi(params);
    }

    /*------------*/
    /** SERVICIOS */

    /*------------*/

    /**
     * Busca las clínicas mediante una key y las inicializa en la Store
     */
    async findClinicsByKey(key: string) {
        if (key === '') {
            return false;
        }

        /** TODO: CHAPUZA. Esto lo hago para asegurarme que por muy rápido que se le de a buscar,
         * siempre me asegure que ya se han cargado la latitud y longitud
         * (fallaba si buscabas rápido la primera vez). Recordemos que lo que espera
         * a tener la apiURL es la llamada http
         * Es la única llamada que tiene la espera en su propio servicio en vez de en el servicio api.service
         * ya que algunos de los datos que necesitamos, los carga la misma llamada de cargar la apiURL
         * Unico a resolver: si se pierde el queryparams y le das a f5 pierdes el flujo y se rompera y cargara la SK original del centro
         */
        await this.settingsQuery
            .select('apiURL')
            .pipe(
                filter((apiURL) => apiURL !== ''),
                take(1)
            )
            .toPromise();

        this.locationService.loadLocationDevice(false);
        const latitude = this.settingsQuery.latitude;
        const longitude = this.settingsQuery.longitude;
        const clinicsData = await this.getClinicsByKeyApi(
            key,
            latitude,
            longitude
        ).toPromise();
        /**
         * Cargamos las clínicas a la Store
         */
        this.setClinicsByApi(clinicsData.Clinics, true);
    }

    /**
     * Crea las clínicas a partir de una Array y las carga en el Store
     */
    async setClinicsByApi(clinics, updateLatestResults = false) {
        const clinicEntities: Clinic[] = [];
        clinics.forEach((clinic) => {
            clinicEntities.push(
                clinicFactory(clinic, this.settingsQuery.currentSystemKey)
            );
        });

        this.updateClinicsFound(clinicEntities);
        if (updateLatestResults) {
            this.updateClinicsLatestResultsLocalStorageAndStore(clinicEntities);
        }
    }

    /**
     * Busca las clínicas mediante una key y las inicializa en la Store
     * TODO, ver como refactorizar esto
     */
    async findClinicsNearest() {
        /** TODO: CHAPUZA. Esto lo hago para asegurarme que por muy rápido que se le de a buscar,
         * siempre me asegure que ya se han cargado la latitud y longitud
         * (fallaba si buscabas rápido la primera vez). Recordemos que lo que espera
         * a tener la apiURL es la llamada http
         * Es la única llamada que tiene la espera en su propio servicio en vez de en el servicio api.service
         * ya que algunos de los datos que necesitamos, los carga la misma llamada de cargar la apiURL
         * Unico a resolver: si se pierde el queryparams y le das a f5 pierdes el flujo y se rompera y cargara la SK original del centro
         */
        await this.settingsQuery
            .select('apiURL')
            .pipe(
                filter((apiURL) => apiURL !== ''),
                take(1)
            )
            .toPromise();

        this.locationService.loadLocationDevice(false);
        const latitude = this.settingsQuery.latitude;
        const longitude = this.settingsQuery.longitude;
        const clinicsData = await this.getClinicsByKeyApi(
            '',
            latitude,
            longitude
        ).toPromise();
        /**
         * Cargamos las clínicas a la Store
         */
        this.setClinicsNearestByApi(clinicsData.Clinics);
    }

    /**
     * Crea las clínicas a partir de una Array y las carga en el Store
     */
    async setClinicsNearestByApi(clinics) {
        const clinicEntities: Clinic[] = [];
        clinics.forEach((clinic) => {
            clinicEntities.push(
                clinicFactory(clinic, this.settingsQuery.currentSystemKey)
            );
        });

        this.updateClinicsNearest(clinicEntities);
    }

    async updateClinicsLatestResultsLocalStorageAndStore(clinicEntities) {
        const clinicLatestResultsLocalStorage = await this.updateClinicsLatestResultsLocalStorage(
            clinicEntities
        );
        this.updateClinicsLatestResults(clinicLatestResultsLocalStorage);
    }

    async updateClinicsLatestResultsLocalStorage(
        newClinicsLatestResults: Clinic[]
    ): Promise<Clinic[]> {
        let clinicsLatestResults: Clinic[] =
            (await this.storage.get(this.getKeyStoreClinicsLatestResults())) ??
            [];

        clinicsLatestResults = removeDuplicateClinics([
            ...newClinicsLatestResults,
            ...clinicsLatestResults,
        ]).slice(0, this.MAX_CLINICS_LATEST_RESULTS);

        this.storage.set(
            this.getKeyStoreClinicsLatestResults(),
            clinicsLatestResults
        );
        return clinicsLatestResults;
    }

    async loadClinicsLatestResultsFromLocalStorage(): Promise<void> {
        const clinicsLatestResults: Clinic[] =
            (await this.storage.get(this.getKeyStoreClinicsLatestResults())) ??
            [];
        this.updateClinicsLatestResults(clinicsLatestResults);
    }

    clearClinicsLatestResults() {
        this.clinicStore.update({
            clinicsLatestResults: [],
        });
        this.storage.set(this.getKeyStoreClinicsLatestResults(), []);
    }

    async loadClinicsFavFromLocalStorage(): Promise<void> {
        let clinicsFav: Clinic[] =
            (await this.storage.get(this.getKeyStoreClinicsFav())) ?? [];
        this.updateClinicsFav(clinicsFav);
    }

    getKeyStoreClinicsLatestResults() {
        let keyStore = 'clinicsLatestResults';
        if (this.settingsQuery.isPersonalized()) {
            keyStore = this.settingsQuery.currentSystemKey + '-' + keyStore;
        }
        return keyStore;
    }

    getKeyStoreClinicsFav() {
        let keyStore = '';
        if (this.settingsQuery.isPersonalized()) {
            keyStore = this.settingsQuery.currentSystemKey + '-' + 'clinicsFav';
        } else if (this.settingsQuery.isGeneric()) {
            keyStore = 'clinicsFav';
        }
        return keyStore;
    }
}
