import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { ClinicDetailQuery } from 'src/app/shared/state/clinic-detail/clinic-detail.query';
import { MessageService } from 'src/app/shared/state/message/message.service';
import { SettingsQuery } from 'src/app/shared/state/settings/settings.query';
import { UserQuery } from 'src/app/shared/state/user/user.query';
import { NavController } from '@ionic/angular';
import OneSignal from 'onesignal-cordova-plugin';
import { StorageService } from '../storage/storage.service';

@Injectable({
    providedIn: 'root',
})
export class PushService {
    constructor(
        private router: Router,
        private userQuery: UserQuery,
        private messageService: MessageService,
        private ngZone: NgZone,
        private clinicDetailQuery: ClinicDetailQuery,
        private settingsQuery: SettingsQuery,
        private navCtrl: NavController,
        private storage: StorageService
    ) {}

    async initNotificationPush() {
        await this.storage.set('idDevice', '');

        if (Capacitor.isNativePlatform()) {
            this.initConfiguration();
        }
    }

    initConfiguration() {
        OneSignal.setAppId('d8829bd5-00d9-4eba-bb91-6e2bbd4895b2');
        OneSignal.setNotificationOpenedHandler((jsonData: any) => {
            // do something when a notification is opened
            const systemKey =
                jsonData.notification.additionalData.SystemKey;
            const clinicId = jsonData.notification.additionalData.ClinicID;

            this.ngZone.run(() => {
                if (
                    this.clinicDetailQuery.id === clinicId &&
                    this.settingsQuery.systemKeyURL === systemKey &&
                    this.userQuery.tokenId !== ''
                ) {
                    this.messageService.getMessages(null);
                }
            });

            this.ngZone.run(() => {
                /** Si estoy en un sk o un en una clínica distinta a la que voy a entrar, la hago root */
                if (
                    this.settingsQuery.systemKeyURL !== systemKey ||
                    this.clinicDetailQuery.id !== clinicId
                ) {
                    this.navCtrl.navigateRoot(
                        systemKey + '/' + clinicId + '/message'
                    );
                } else {
                    this.router.navigate([
                        systemKey + '/' + clinicId + '/message',
                    ]);
                }
            });
        });
        OneSignal.promptForPushNotificationsWithUserResponse(function (
            accepted
        ) {
            console.log('User accepted notifications: ' + accepted);
        });

        OneSignal.addSubscriptionObserver(async (event) => {
            console.log('Event', event)
            if (event.to.isSubscribed) {
                OneSignal.getDeviceState((resp) => {
                    console.log('Update device', resp);
                    console.log('DeviceId', resp.userId);
                    if (resp.userId !== undefined && resp.userId != null) {
                        this.storage.set('idDevice', resp.userId);
                    }
                });
            }
        });
    }
}
