import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    ServiceWizard,
    ServiceWizardCheckedEvent,
} from '../../../state/service-wizard/service-wizard.model';

@Component({
    selector: 'app-service-list',
    templateUrl: './service-list.component.html',
    styleUrls: ['./service-list.component.scss'],
})
export class ServiceListComponent implements OnInit {
    @Input() servicesWizard: ServiceWizard[];
    @Input() isLoadingContent: boolean;
    @Input() noMoreServices: boolean;
    @Input() appDelegate: boolean;
    @Input() servicesWizardChecked: ServiceWizard[];

    @Output() serviceClickedEvent = new EventEmitter<
        ServiceWizardCheckedEvent
    >();
    @Output() serviceToggleEvent = new EventEmitter<any>();
    @Output() changeServiceSearchbarEvent: EventEmitter<any> = new EventEmitter<
        any
    >();

    constructor() {}

    ngOnInit(): void {}

    onServiceClicked(serviceAndCheckedEvent: ServiceWizardCheckedEvent) {
        this.serviceClickedEvent.emit(serviceAndCheckedEvent);
    }

    onServiceToggle(service: ServiceWizard) {
        this.serviceToggleEvent.emit(service);
    }
}
