import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map, timeout } from 'rxjs/operators';
import { SettingsService } from '../../shared/state/settings/settings.service';
import { ToastService } from '../services/toast/toast.service';
import { LoadingService } from '../../shared/state/loading/loading.service';
import { SettingsQuery } from '../../shared/state/settings/settings.query';
import { UserQuery } from '../../shared/state/user/user.query';
import { UserService } from '../../shared/state/user/user.service';
import { Router } from '@angular/router';
import { LibraryService } from '../services/library/library.service';

/**
 * Este interceptor lo utilizaremos para manejar todos los errores desde el mismo punto y para mangejar la ApiURL que nos devolverá en
 * cada respuesta
 * Esto nos evitará estar contemplando errores y otras tareas comunes a todas las llamadas manualmente en cada llamada del servicio
 */

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable()
export class FlowwwerInterceptorService implements HttpInterceptor {
    activeRequests = 0;

    constructor(
        private settingsService: SettingsService,
        private settingsQuery: SettingsQuery,
        private toastService: ToastService,
        private loadingService: LoadingService,
        private userQuery: UserQuery,
        private userService: UserService,
        private router: Router,
        private libraryService: LibraryService,
        @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const timeoutValue =
            request.headers.get('timeout') || this.defaultTimeout;
        const timeoutValueNumeric = Number(timeoutValue);

        if (request.body !== null) {
            if (this.activeRequests === 0) {
                this.loadingService.showLoading();
            }
            this.activeRequests += 1;
        }

        return next.handle(request).pipe(
            map((event: HttpResponse<any>) => {
                if (event instanceof HttpResponse) {
                    if (
                        event.body &&
                        event.body.Result &&
                        event.body.Result.ErrorNumber !== '' &&
                        event.body.Result.ErrorNumber !== '0'
                    ) {
                        throw {
                            message: event.body.Result.ErrorDescription,
                            errorNumber: event.body.Result.ErrorNumber,
                        };
                    }
                    /** Comprobamos la RegionApiUrl que viene en todas las llamadas */
                    if (
                        event.body &&
                        event.body.Resources &&
                        event.body.Resources.RegionAPIUrl &&
                        event.body.Resources.RegionAPIUrl !== '' &&
                        !this.settingsQuery.debug
                    ) {
                        if (
                            event.body.Resources.RegionAPIUrl !==
                            this.settingsQuery.apiURL
                        ) {
                            this.settingsService.updateApiURL(
                                event.body.Resources.RegionAPIUrl
                            );
                        }
                    }

                    /** Comprobamos el ClientTMP que viene en todas las llamadas del usuario */
                    if (
                        event.body &&
                        event.body.ClientTMP &&
                        event.body.ClientTMP !== ''
                    ) {
                        if (
                            this.userQuery.tokenId !== '' &&
                            (event.body.ClientTMP === '-1') !==
                                this.userQuery.clientTMP
                        ) {
                            this.userService.updateIsTemporal(
                                event.body.ClientTMP === '-1'
                            );
                        }
                    }

                    /** Comprobamos el OnlineShop que viene en las llamadas API de tratamientos */
                    if (
                        event.body &&
                        event.body.OnlineShop &&
                        event.body.OnlineShop !== ''
                    ) {
                        if (
                            this.userQuery.tokenId !== '' &&
                            (event.body.OnlineShop === '-1') !==
                                this.userQuery.onlineShop
                        ) {
                            this.userService.updateOnlineShop(
                                event.body.OnlineShop === '-1'
                            );
                        }
                    }

                    /** Comprobamos si la APP se puede utilizar mirando el parámetro XXXX de la api */
                    if (
                        event.body &&
                        event.body.XXXXXX &&
                        event.body.XXXXXX !== ''
                    ) {
                        if (event.body.XXXXXX === '0') {
                            this.router.navigate(['/app-disabled']);
                        }
                    }
                }
                return event;
            }),
            finalize(() => {
                if (request.body !== null) {
                    this.activeRequests -= 1;
                    if (this.activeRequests === 0) {
                        this.loadingService.hideLoading();
                    }
                }
            }),
            timeout(timeoutValueNumeric),
            catchError((error: HttpErrorResponse | any) => {
                this.loadingService.updateShowSpinner(false);

                let errorMessage = '';
                if (error.error instanceof ErrorEvent) {
                    // client-side error
                    errorMessage = `${error.error.message}`;
                } else {
                    // server-side error
                    errorMessage = `${error.message}`;
                }
                /** Con esto no mostramos los mensajes producidos por leer archivos que no existen */
                if (!error.url || !error.url.includes('/assets/i18n')) {
                    if (error.errorNumber && error.errorNumber !== '') {
                        const errorLabel = this.getErrorLabel(
                            error.errorNumber
                        );
                        if (errorLabel) {
                            errorMessage = `${this.libraryService.getLabel(
                                errorLabel
                            )}`;
                        }
                    }
                    this.toastService.presentErrorToast(errorMessage);
                }
                return throwError(errorMessage);
            })
        );
    }

    getErrorLabel(errorNumber) {
        let labelError;
        switch (errorNumber) {
            case '100019':
                labelError = 'app_error_profesional';
                break;
            case '100022':
                labelError = 'app_error_profesional';
                break;
            case '100023':
                labelError = 'app_error_profesional';
                break;
            case '100029':
                labelError = 'app_disabled_temp';
                break;
            case '100030':
                labelError = 'app_user_skills';
                break;
            case '100031':
                labelError = 'app_disabled';
                break;
            case '100032':
                labelError = 'app_disabled_unregistered';
                break;
        }
        return labelError;
    }
}
