import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FamilyWizardModel } from '../../../models/family-wizard.model';

@Component({
    selector: 'app-family-list',
    templateUrl: './family-list.component.html',
    styleUrls: ['./family-list.component.scss'],
})

/**
 * TODO: Efecto face in para cuando aparecen los resultados
 */
export class FamilyListComponent implements OnInit {
    @Input() families: FamilyWizardModel[];
    @Input() iconFamilyItem: string;
    @Input() isLoadingContent: boolean;

    @Output() familyClickedEvent = new EventEmitter<FamilyWizardModel>();

    constructor() {}

    ngOnInit(): void {}

    onFamilyClicked(family: FamilyWizardModel) {
        this.familyClickedEvent.emit(family);
    }
}
