import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    ServiceWizard,
    ServiceWizardCheckedEvent,
} from '../../../state/service-wizard/service-wizard.model';
import { Observable } from 'rxjs';
import { ServiceWizardQuery } from '../../../state/service-wizard/service-wizard.query';
import {ModalController, Platform} from '@ionic/angular';
import {AppRolesModel, GenericKeysModel} from '../../../models';
import {SettingsQuery} from '../../../state/settings/settings.query';
import {
    ServiceViewComponent
} from '../../../../modules/appointment-wizard/components/service-view/service-view.component';

@Component({
    selector: 'app-service-item',
    templateUrl: './service-item.component.html',
    styleUrls: ['./service-item.component.scss'],
})
export class ServiceItemComponent implements OnInit {
    @Input() service: ServiceWizard;
    @Input() noMoreServices: boolean;
    @Input() appDelegate: boolean;

    @Output() serviceClickedEvent = new EventEmitter<
        ServiceWizardCheckedEvent
    >();
    @Output() serviceToggleEvent = new EventEmitter<any>();

    ios;
    
    serviceIsChecked$: Observable<boolean>;

    get finalPrice() {
        if (this.service.price && this.service.price !== '') {
            if (this.service.promotedPrice && this.service.promotedPrice !== '') {
                return this.service.promotedPrice;
            } else {
                return this.service.price;
            }
        }
    }

    constructor(private settingsQuery: SettingsQuery, private modalController: ModalController, private platform: Platform, private serviceWizardQuery: ServiceWizardQuery) {
        this.ios = this.platform.is('ios');
    }

    ngOnInit(): void {
        this.serviceIsChecked$ = this.serviceWizardQuery.selectEntity(
            this.service.id,
            'checked'
        );
    }

    onServiceClicked(service: ServiceWizard, event) {
        this.serviceClickedEvent.emit({
            service,
            checkedEvent: event.currentTarget.checked,
        });
    }

    onServiceToggle(service: ServiceWizard) {
        this.serviceToggleEvent.emit(service);
    }

    get showModalService() {
        if (
            this.settingsQuery.appRole === AppRolesModel.Personalized ||
            this.settingsQuery.appRole === AppRolesModel.PersonalizedBrowser ||
            ((this.settingsQuery.appRole === AppRolesModel.Generic ||
                this.settingsQuery.appRole === AppRolesModel.GenericBrowser) &&
                this.settingsQuery.genericKey === GenericKeysModel.LPG)
        ) {
            return true;
        }
        return false;
    }

    openServiceInfoModal($event: MouseEvent) {
        if (this.showModalService) {
            $event.preventDefault();
            $event.cancelBubble = true;

            this.modalController.create({
                component: ServiceViewComponent,
                cssClass: this.settingsQuery.browser ? 'modal-flowwwer modal-browser' : 'modal-flowwwer',
                componentProps: {
                    serviceId: this.service.id,
                },
            }).then(modal => modal.present());
        }
    }
}
