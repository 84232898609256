import {
    Component,
    EventEmitter,
    Input, OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {
    fade_in,
    fade_out,
    fade_out_absolute,
} from 'src/app/core/animations/fade';
import { HeaderPictureQuery } from '../../state/header-picture/header-picture.query';
import { InputSearchComponent } from '../input-search/input-search.component';
import { NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Capacitor } from '@capacitor/core';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    animations: [fade_out, fade_in, fade_out_absolute],
})
export class HeaderComponent implements OnInit, OnDestroy {
    @Input() size: 'default' | 'large' = 'default';
    @Input() logoUrl = '';
    @Input() showLogo = false;
    @Input() search = false;
    @Input() favourite = false;
    @Input() favouriteActive = true;
    @Input() navigationBack = false; //TODO: QUien implemente esto, tiene que tener en cuenta que si hay solo 1 clínica no debe de mostrar el botón
    @Input() border = true;
    @Input() steps = 0;
    @Input() currentStep = 0;
    @Input() showSkeleton = false;
    @Input() skeletonBackButton = false;
    @Input() skeletonLogo = false;
    @Input() noresponsive = 'noresponsive';
    @Input() isModal = false;


    @Output() onClickBack = new EventEmitter<void>();
    @Output() onSearch = new EventEmitter<string>();
    @Output() onFavourite = new EventEmitter<void>();
    @Output() onWriteSearch = new EventEmitter<string>();
    @Output() onShowSearch = new EventEmitter<void>();
    @Output() onHideSearch = new EventEmitter<void>();

    @ViewChild(InputSearchComponent) inputSearch: InputSearchComponent;

    private readonly routesLargeLogo: string[] = ['details'];
    searchOpen = false;
    public showLargeLogo: boolean = true;
    public isNative: boolean = Capacitor.isNativePlatform();
    public isFlowwwer: boolean = !!environment.genericKey;
    public isCustom: boolean = !!environment.systemKey;
    public isBrowser: boolean = !!environment.browser;

    private router$?: Subscription;

    constructor(private headerPicturesQuery: HeaderPictureQuery, private router: Router) {}

    ngOnInit(): void {
        if (this.logoUrl === '') {
            this.headerPicturesQuery
                .select('homeCompany')
                .subscribe((homeCompany) => {
                    this.logoUrl = homeCompany;
                });
        }

        if (this.isModal) return;

        this.router$ = this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map( (e: NavigationEnd) => e.url),
                distinctUntilChanged()
            )
            .subscribe( (path: string) => {
                const url = path.split('/').pop();
                this.showLargeLogo = this.routesLargeLogo.includes(url);
            });
           
    }

    clickBackButton(): void {
        this.onClickBack.emit();
    }

    clickShowSearchButton(): void {
        this.searchOpen = true;
        setTimeout(() => {
            this.inputSearch.inputSearchElem.nativeElement.focus();
        }, 1);
        this.onShowSearch.emit();
    }

    clickShowHideButton() {
        this.searchOpen = false;
        this.onHideSearch.emit();
    }

    resetSearch() {
        this.searchOpen = false;
    }

    writeInputSearch(searchText) {
        this.onWriteSearch.emit(searchText);
    }

    searchInputSearch(searchText) {
        this.onSearch.emit(searchText);
    }

    clickFavourite(): void {
        this.onFavourite.emit();
    }

    ngOnDestroy() {
        this.router$?.unsubscribe();
    }

    get stepsArray(): number[] {
        return Array.from(Array(this.steps).keys());
    }
}
