import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { environment } from '../environments/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { IonicStorageModule } from '@ionic/storage';
import {
    HttpClientModule,
    HttpClient,
    HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
    FlowwwerInterceptorService,
    DEFAULT_TIMEOUT,
} from './core/interceptor/flowwwer.interceptor.service';
import { SharedModule } from './shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DateWizardPipe } from './shared/pipes/date-wizard.pipe';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { Market } from '@awesome-cordova-plugins/market/ngx';
import {
    MissingTranslationHandler,
    MissingTranslationHandlerParams,
} from '@ngx-translate/core';
import { EmailComposer } from '@awesome-cordova-plugins/email-composer/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { GlobalErrorHandler } from './core/errorHandler/global-error-handler.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Calendar } from '@awesome-cordova-plugins/calendar/ngx';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export class MyMissingTranslationHandler implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams) {
        return '(ERR_LABEL_' + params.key + '_UNKNOWN)';
    }
}

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        IonicModule.forRoot({
            mode: 'md',
        }),
        AppRoutingModule,
        environment.production ? [] : AkitaNgDevtools.forRoot(),
        HttpClientModule,
        TranslateModule.forRoot({
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: MyMissingTranslationHandler,
            },

            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
            isolate: false,
        }),
        SharedModule,
        IonicStorageModule.forRoot({
            name: '__mydb',
            driverOrder: ['sqlite', 'localstorage'],
        }),
        NgbModule
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: FlowwwerInterceptorService,
            multi: true,
        },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: DEFAULT_TIMEOUT, useValue: 30000 },
        DateWizardPipe,
        Diagnostic,
        AndroidPermissions,
        CallNumber,
        AppVersion,
        Market,
        EmailComposer,
        ScreenOrientation,
        Calendar
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
