import { Component, Input, OnInit } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import { CompanyQuery } from '../../state/company/company.query';
import { CompanyState } from '../../state/company/company.store';
import { PrivacityModalComponent } from '../privacity-modal/privacity-modal.component';
import { SettingsQuery } from '../../state/settings/settings.query';
import { CompanyService } from '../../state/company/company.service';
import { ClientDataSocialLogin } from '../../state/social-login/social-login.store';
import { Preferences } from '@capacitor/preferences';
import { ClientValidators } from '../../validators/ClientValidators';
import { Clipboard } from '@capacitor/clipboard';
import { ToastService } from '../../../core/services/toast/toast.service';
import { LibraryService } from '../../../core/services/library/library.service';

@Component({
    selector: 'app-register-form',
    templateUrl: './register-form.component.html',
    styleUrls: ['./register-form.component.scss'],
})
export class RegisterFormComponent implements OnInit {
    private static readonly charsRegex = /^[a-zA-ZÀ-ÿ ª\-`'´·]*$/;

    registerForm: FormGroup;
    userEnableGeneralConditions = false;
    userEnableRgpd = false;
    nameSplit$: Observable<boolean>;
    company$: Observable<CompanyState>;
    private currentClientDataSocialLogin: ClientDataSocialLogin;

    @Input() clinicDetailRgpdDisable: boolean;
    @Input() set clientDataSocialLogin(
        clientDataSocialLogin: ClientDataSocialLogin
    ) {
        this.currentClientDataSocialLogin = clientDataSocialLogin;
        this.registerForm?.patchValue({
            userName: clientDataSocialLogin.name ?? '',
            userSurname1: clientDataSocialLogin.surname ?? '',
            userEmail: clientDataSocialLogin.email ?? '',
            userPhone: clientDataSocialLogin.phone ?? '',
        });
    }

    get clientDataSocialLogin() {
        return this.currentClientDataSocialLogin;
    }

    constructor(
        public formBuilder: FormBuilder,
        private modalController: ModalController,
        private companyQuery: CompanyQuery,
        private settingsQuery: SettingsQuery,
        private companyService: CompanyService,
        private toastService: ToastService,
        private libraryService: LibraryService
    ) {}

    ngOnInit(): void {
        this.nameSplit$ = this.companyQuery.select('nameSplit');
        this.company$ = this.companyQuery.select();
        this.companyQuery
            .select()
            .pipe(
                filter((params) => params.systemKey !== ''),
                take(1)
            )
            .subscribe(async (_) => {
                await this.initForm();
            });
    }

    async initForm() {
        const referrerCode = (await Preferences.get({ key: 'referrer' })).value;

        this.registerForm = this.formBuilder.group({
            userName: [
                this.clientDataSocialLogin?.name ?? '',
                [
                    Validators.maxLength(30),
                    Validators.pattern(RegisterFormComponent.charsRegex),
                    ClientValidators.validDash(),
                    Validators.required,
                ],
            ],
        });

        if (this.companyQuery.nameSplit) {
            this.registerForm.addControl(
                'userSurname1',
                new FormControl(
                    this.clientDataSocialLogin?.surname ?? '',
                    [
                        Validators.maxLength(60),
                        Validators.pattern(RegisterFormComponent.charsRegex),
                        ClientValidators.validDash(),
                        Validators.required,
                    ]
                )
            );

            if (this.companyService.isUserFormFieldVisible('ClientSurname2')) {
                this.registerForm.addControl(
                    'userSurname2',
                    new FormControl('', [
                        Validators.maxLength(60),
                        Validators.pattern(RegisterFormComponent.charsRegex),
                        ClientValidators.validDash(),
                        this.companyService.isUserFormFieldRequired(
                            'ClientSurname2'
                        ),
                    ])
                );
            }
        }

        if (this.companyQuery.clientAdvisor) {
            this.registerForm.addControl(
                'userReferrerCode',
                new FormControl(referrerCode)
            );
        }

        this.registerForm.addControl(
            'userPhone',
            new FormControl(this.clientDataSocialLogin?.phone ?? '', [
                Validators.maxLength(32),
                Validators.pattern("[A-Za-z0-9ñÑáéíóúÝÉÝÓÚ '-]*"),
                Validators.required,
            ])
        );

        this.registerForm.addControl(
            'userEmail',
            new FormControl(this.clientDataSocialLogin?.email ?? '', [
                Validators.required,
                Validators.pattern('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$'),
            ])
        );

        this.setFormControlEvents();
    }

    isUserFormFieldVisible(field: string): boolean {
        return this.companyService.isUserFormFieldVisible(field);
    }

    get controls() {
        return this.registerForm.controls;
    }

    async presentModalPrivacity(event?: Event) {
        if (event) {
            event.preventDefault();
        }

        const modal = await this.modalController.create({
            component: PrivacityModalComponent,
            cssClass: this.settingsQuery.browser ? 'modal-flowwwer modal-browser' : 'modal-flowwwer',
        });
        return await modal.present();
    }

    /** flowwwer template */
    get isNameSplit(): boolean {
        return this.companyQuery.nameSplit;
    }

    hasControlError(controlName: string): boolean {
        if (this.controls.hasOwnProperty(controlName)) {
            const control = this.controls[controlName];

            return control.invalid && control.touched;
        }

        return false;
    }

    removeSpecialChar(text: string): string {
        // Remplaza la comilla tipografica por la comilla nomral
        let str = text.replace('\u2018', "'");
        // Remplaza la comilla inclinada tipografica por la normal
        str = text.replace('\u2019', "´");
        return str;
    }

    setFormControlEvents(): void {
        this.registerForm.get('userName').valueChanges
        .pipe(
            distinctUntilChanged()
        )
        .subscribe( (inputText: string) => {
            let text = this.removeSpecialChar(inputText);
            this.registerForm.patchValue({'userName': text});
        });

        this.registerForm.get('userSurname1').valueChanges
        .pipe(
            distinctUntilChanged()
        ).subscribe( (inputText: string) => {
            let text = this.removeSpecialChar(inputText);
            this.registerForm.patchValue({'userSurname1': text});
        });

        this.registerForm.get('userSurname2').valueChanges
        .pipe(
            distinctUntilChanged()
        ).subscribe( (inputText: string) => {
            let text = this.removeSpecialChar(inputText);
            this.registerForm.patchValue({'userSurname2': text});
        });
    }

    async pasteCode(input: HTMLIonInputElement) {
        const regex = /^[A-Za-z0-9]{4}-[A-Za-z0-9]{4}$/;
        try {
            let { value } = await Clipboard.read();
            value = value.trim();

            if (value === '' || !regex.test(value)) {
                throw new Error();
            }

            input.value = value;

        } catch (e) {
            this.toastService.presentInfoToast(
                this.libraryService.getLabel('lbl_not_found_clipboard')
            );
        }
    }

}
