import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ClinicDetailQuery} from '../../state/clinic-detail/clinic-detail.query';
import {Observable} from 'rxjs';
import {IonSearchbar} from '@ionic/angular';

@Component({
    selector: 'app-generic-header',
    templateUrl: './generic-header.component.html',
    styleUrls: ['./generic-header.component.scss']
})
export class GenericHeaderComponent implements OnInit {
    @Input() currentStep = 0;
    @Input() steps = 0;
    @Input() searchbar = false;
    @Output() backButtonClick = new EventEmitter<MouseEvent>();
    @Output() search = new EventEmitter<string>();
    @ViewChild(IonSearchbar) ionSearchBar: IonSearchbar;

    clinicName$: Observable<string>;

    constructor(
        private clinicDetailQuery: ClinicDetailQuery
    ) {
    }

    get stepsArray(): number[] {
        return Array.from(Array(this.steps).keys());
    }

    ngOnInit(): void {
        this.clinicName$ = this.clinicDetailQuery.select('commercialName');
    }

    handleBackButton($event: MouseEvent) {
        this.backButtonClick.emit($event);
    }

    clearInput(): void {
        if (this.ionSearchBar) {
            this.ionSearchBar.getInputElement().then(input => input.value = '');
        }
    }

    searchBarOnChange(ev: CustomEvent): void {
        this.search.emit(ev.detail.value);
    }
}
