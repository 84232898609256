import {Injectable} from '@angular/core';
import {ToastController} from '@ionic/angular';
import { templateToastPosition } from './toast-service.functions';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    constructor(public toastController: ToastController) {}

    async presentErrorToast(errorMessage: string) {
        const toast = await this.toastController.create({
            message: errorMessage,
            duration: 3000,
            color: 'danger',
            position: this.getToastPosition()
        });
        toast.present();
    }

    async presentInfoToast(message: string) {
        const toast = await this.toastController.create({
            message,
            duration: 3000,
            color: 'primary',
            position: this.getToastPosition()
        });
        toast.present();
    }

    private getToastPosition(): 'top' | 'bottom' {
        return templateToastPosition({
            defaultTemplate: this.getToastPositionDefault,
            flowwwerTemplate: this.getToastPositionFlowwwer
        });
    }

    private getToastPositionFlowwwer() {
        return 'top';
    }

    private getToastPositionDefault() {
        return 'bottom';
    }
}
