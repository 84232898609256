import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-user-account',
  templateUrl: './skeleton-user-account.component.html',
  styleUrls: ['./skeleton-user-account.component.scss']
})
export class SkeletonUserAccountComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
