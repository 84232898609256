import { Component, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { Observable } from 'rxjs';
import { LanguageService } from 'src/app/core/services/language/language.service';
import { LibraryService } from 'src/app/core/services/library/library.service';
import { GenericKeysModel } from '../../models';
import { SettingsQuery } from '../../state/settings/settings.query';

@Component({
    selector: 'app-floating-link-info',
    templateUrl: './floating-link-info.component.html',
    styleUrls: ['./floating-link-info.component.scss'],
})
export class FloatingLinkInfoComponent implements OnInit {
    floatingInfoEnable = true;

    showFloatingLink;
    title: Observable<string>;
    text: Observable<string>;
    linkText: Observable<string>;
    link: string;

    constructor(
        private settingsQuery: SettingsQuery,
        private languageService: LanguageService,
        private libraryService: LibraryService
    ) {}

    ngOnInit(): void {
        /** TODO: Delay de 1 segundo para hacer que aparezca como a Javi, ver si tenemos que quitar esto o hacerlo de otra forma */
        setTimeout(() => {
            this.settingsQuery.select('genericKey').subscribe((genericKey) => {
                switch (genericKey) {
                    case GenericKeysModel.FLOWwwer:
                        this.floatingInfoEnable = true;
                        this.showFloatingLink = true;
                        this.title = this.libraryService.getLabelStream(
                            'home_register_title_flowwwer'
                        );
                        this.text = this.libraryService.getLabelStream(
                            'home_register_text_flowwwer'
                        );
                        this.linkText = this.libraryService.getLabelStream(
                            'home_register_text_link'
                        );

                        if (this.languageService.getLocale() !== 'pt') {
                            this.link =
                                'https://www.flowww.net/flowww-prueba-software-gestion-marketing-app-citas';
                        } else {
                            this.link =
                                'https://www.flowww.net/flowww-experimente-gratis-software-de-gestao-e-app-de-agendamentos';
                        }
                        break;
                    case GenericKeysModel.Voooy:
                        this.floatingInfoEnable = true;
                        this.showFloatingLink = true;
                        this.title = this.libraryService.getLabelStream(
                            'home_register_title_voooy'
                        );
                        this.text = this.libraryService.getLabelStream(
                            'home_register_text_voooy'
                        );
                        this.linkText = this.libraryService.getLabelStream(
                            'home_register_text_link'
                        );
                        this.link =
                            'https://www.flowww.net/es/voooy/empieza-a-dar-citas';
                        break;
                    default:
                        this.floatingInfoEnable = false;
                        this.showFloatingLink = false;
                        break;
                }
            });
        }, 1000);
    }

    closeFloatingLink() {
        this.showFloatingLink = false;
    }
    openFloatingLink() {
        this.showFloatingLink = true;
    }
    async openUrl() {
        if (Capacitor.isNativePlatform()) {
            await Browser.open({ url: this.link });
        } else {
            const win = window.open(this.link, '_blank');
            win.focus();
        }
    }
}
