import { Injectable } from '@angular/core';
import { HeaderBackgroundStore } from './header-background.store';
import { SettingsQuery } from '../settings/settings.query';
import { AppRolesModel } from '../../models';

@Injectable({ providedIn: 'root' })
export class HeaderBackgroundService {
    constructor(
        private headerBackgroundStore: HeaderBackgroundStore,
        private settingsQuery: SettingsQuery
    ) {}

    /*-----------*/
    /** UPDATES */
    /*----------*/

    updateBackgroundsPersonalized(dataBackground: any) {
        if (this.settingsQuery.appRole === AppRolesModel.PersonalizedBrowser) {
            this.updateBacgroundsPersonalizedBrowser(dataBackground);
        } else {
            this.updateBacgroundsPersonalizedNoBrowser();
        }
    }

    updateBacgroundsPersonalizedBrowser(dataBackground) {
        this.setBrackgoundPictures(
            'home',
            dataBackground?.home,
            dataBackground?.home
        );
        this.setBrackgoundPictures(
            'homeCompany',
            dataBackground?.home,
            dataBackground?.home
        );
        this.setBrackgoundPictures(
            'company',
            dataBackground?.clinic,
            dataBackground?.home
        );
        this.setBrackgoundPictures(
            'account',
            dataBackground?.user,
            dataBackground?.home
        );
        this.setBrackgoundPictures(
            'appointment',
            dataBackground?.sched,
            dataBackground?.home
        );
        this.setBrackgoundPictures(
            'treatment',
            dataBackground?.prepaid,
            dataBackground?.home
        );
        this.setBrackgoundPictures(
            'messages',
            dataBackground?.inbox,
            dataBackground?.home
        );
    }

    updateBacgroundsPersonalizedNoBrowser() {
        this.setBrackgoundPictures(
            'home',
            'assets/backgrounds/home.png',
            'assets/backgrounds/home.png'
        );
        this.setBrackgoundPictures(
            'homeCompany',
            'assets/backgrounds/home.png',
            'assets/backgrounds/home.png'
        );
        this.setBrackgoundPictures(
            'company',
            'assets/backgrounds/company.png',
            'assets/backgrounds/home.png'
        );
        this.setBrackgoundPictures(
            'account',
            'assets/backgrounds/account.png',
            'assets/backgrounds/home.png'
        );
        this.setBrackgoundPictures(
            'appointment',
            'assets/backgrounds/appointment.png',
            'assets/backgrounds/home.png'
        );
        this.setBrackgoundPictures(
            'treatment',
            'assets/backgrounds/treatment.png',
            'assets/backgrounds/home.png'
        );
        this.setBrackgoundPictures(
            'messages',
            'assets/backgrounds/messages.png',
            'assets/backgrounds/home.png'
        );
    }

    setBrackgoundPictures(key, img, homeImg) {
        this.headerBackgroundStore.update({
            [key]: img,
        });

        const objImage = new Image();
        objImage.onload = () => {
            this.headerBackgroundStore.update({
                [key]: img,
            });
        };
        objImage.onerror = () => {
            if (img !== homeImg) {
                this.setBrackgoundPictures(key, homeImg, homeImg);
            } else {
                this.headerBackgroundStore.update({
                    [key]: null,
                });
            }
        };
        objImage.src = img;
    }
}
