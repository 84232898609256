import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface ClinicDetailState {
    id: string /** Id de la clínica dentro del sistema */;
    systemKey: string /** SystemKey al que pertenece la clínica (puede ser diferente a la company cargada) */;
    commercialName: string /** Nombre comercial de la clínica */;
    appDelegate: boolean /** Especifica si la clínica tiene centros que delegan en ella (uno o varios centros tienen como delegado a esta clínica */;
    address1: string /** Dirección 1 de la clínica */;
    address2: string /** Dirección 2 de la clínica */;
    address3: string /** Dirección 3 de la clínica */;
    phone: string /** Teléfono 1 de la clínca */;
    phone2: string /** Teléfono 2 de la clínca */;
    email: string /** Email de la clínica */;
    twitter: string /** Dirección de Twitter de la clínca */;
    facebook: string /** Dirección de Facebook de la clínca */;
    website: string /** Sitio web de la clínica */;
    instagram: string /** Instagram web de la clínica */;
    title: string /** Título de la descripción de la clíncia */;
    text: string /** Subtítulo de la descripción de la clínica */;
    latitude: string /** Latitud de la clínica */;
    longitude: string /** Longitud de la clínica */;
    weekDayStart: string /** TODO: Preguntar, Javi no lo usa */;
    legalCommercialPolicy: string /** Politica de privacidad de la clinica */;
    legalMobileDevicePolicy: string /** Politica de privacidad de la clinica desde dispositivos moviles */;
    RGPDDisable: boolean /** Deshabilita la RGPD de la clínica */;
    image: Array<string> /** Imágenes de la clínca */;
    openAppointmentWizard: boolean /** Indica si se tiene que abrir el modal de pedir cita al entrar en mis citas */;
    flowwwerReadOnly: boolean /** Dato importado de 'clinic'. Solo se rellena en personalizadas o en generic si se pasa por el buscador */;
    city: string /** Dato importado de 'clinic'. Solo se rellena en personalizadas o en generic si se pasa por el buscador */;
    distance: string /** Distancia del usuario a la clínica */
}

export function createInitialState(): ClinicDetailState {
    return {
        id: '',
        systemKey: '',
        commercialName: '',
        appDelegate: false,
        address1: '',
        address2: '',
        address3: '',
        phone: '',
        phone2: '',
        email: '',
        twitter: '',
        facebook: '',
        website: '',
        instagram: '',
        title: '',
        text: '',
        latitude: '',
        longitude: '',
        weekDayStart: '',
        legalCommercialPolicy: '',
        legalMobileDevicePolicy: '',
        RGPDDisable: false,
        image: [],
        openAppointmentWizard: false,
        flowwwerReadOnly: false,
        city: '',
        distance: ''
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'clinic-detail', resettable: true })
export class ClinicDetailStore extends Store<ClinicDetailState> {
    constructor() {
        super(createInitialState());
    }
}
